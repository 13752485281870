import { useEffect, useMemo } from 'react';
import { useGetQuery, useApiMutation } from '@/store/fetchClientInstance';
import { apiRoutes } from '@/services';
import { formatDay } from '@/utils/formatDate';

interface PDUData {
  customer_name: string;
  contract_expiry: string;
  ilt_remaining: number;
  registered_courses: number;
  completed_courses: number;
  certificates: number;
}

export const usePDUData = (selectedUser?: any) => {
  const isQuery = Boolean(selectedUser);

  const { 
    data: queryData, 
    isLoading: isLoadingQuery 
  } = useGetQuery({
    url: apiRoutes.getPDUData,
    skip: isQuery
  });

  const [fetchData, { 
    data: mutationData, 
    isLoading: isLoadingMutation 
  }] = useApiMutation();

  useEffect(() => {
    if (selectedUser) {
      fetchData({
        url: `${apiRoutes.getPDUData}/query`,
        config: {
          method: 'POST',
          body: selectedUser.length > 0 ? selectedUser : [selectedUser]
        }
      });
    }
  }, [selectedUser, fetchData]);

  const processedData = useMemo(() => {
    const data = isQuery ? mutationData : queryData;
    
    if (!data) return [];

    return data.map((item: PDUData) => ({
      ...item,
      contract_expiry: formatDay(item.contract_expiry)
    }));
  }, [isQuery, mutationData, queryData]);

  return {
    pduData: processedData,
    isLoading: isQuery ? isLoadingMutation : isLoadingQuery,
    refetch: isQuery ? 
      () => fetchData({
        url: apiRoutes.getPDUData,
        config: {
          method: 'POST',
          body: {
            query: selectedUser.length > 0 ? selectedUser : [selectedUser]
          }
        }
      }) : 
      undefined
  };
};

export const useUpdatePDUData = () => {
  const [updateData] = useApiMutation();

  const updatePDUData = async (data: any) => {
    try {
      await updateData({
        url: apiRoutes.getPDUData,
        config: {
          method: 'POST',
          body: {
            data,
            action: 'update'
          }
        }
      });
    } catch (err) {
      console.error('Error updating PDU data:', err);
      throw err;
    }
  };

  return {
    updatePDUData
  };
};
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiRequest, apiRoutes } from '../../services';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ServiceModal from './ServiceModal/ServiceModal';
import SupportModal from './SupportModal/SupportModal';
import FeatureModal from './FeatureModal/FeatureModal';
import { SERVICES } from './services';
import './PremiumRequestModal.css';

const STEPS = {
  MAIN: 'main',
  SUPPORT: 'support',
  SERVICE: 'service',
  TRAINING: 'training',
  FEATURE: 'feature',
  OTHER: 'other',
  CONFIRMATION: 'confirmation',
};

const PremiumRequestModal = ({
  setShowRequestModal,
  subdomain,
  email,
  startingStep,
  startingSelectedService,
}) => {
  const [selectedService, setSelectedService] = useState(
    startingSelectedService || null
  );
  const [currentStep, setCurrentStep] = useState(startingStep || STEPS.MAIN);
  const [supportFormData, setSupportFormData] = useState({});
  const [serviceFormData, setServiceFormData] = useState({});
  const [featureFormData, setFeatureFormData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const validateFormData = (formData) => {
    if (!formData || typeof formData !== 'object') return false;

    return Object.values(formData).every((value) => {
      if (typeof value === 'string') {
        return value.trim() !== '';
      }
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value != null;
    });
  };

  useEffect(() => {
    if (currentStep === STEPS.MAIN) {
      setIsFormValid(!!selectedService);
    } else if (selectedService) {
      if (selectedService.type === 'feature') {
        const formData = featureFormData;
        setIsFormValid(validateFormData(formData));
      } else if (selectedService.type === 'service') {
        const formData = serviceFormData;
        setIsFormValid(validateFormData(formData));
      } else {
        const formData = supportFormData;
        setIsFormValid(validateFormData(formData));
      }
    } else {
      setIsFormValid(false);
    }
  }, [
    currentStep,
    selectedService,
    supportFormData,
    serviceFormData,
    featureFormData,
  ]);

  const selectRequest = (service) => {
    setSelectedService(service);
  };

  const handleNext = () => {
    if (selectedService && currentStep !== STEPS.MAIN) {
      setCurrentStep(STEPS.CONFIRMATION);
      handleSubmit();
    } else if (selectedService) {
      setCurrentStep(selectedService.type);
    }
  };

  const handleBack = () => {
    setCurrentStep(STEPS.MAIN);
  };

  const handleSubmit = async () => {
    // const formData = selectedService.type === 'service' ? serviceFormData : supportFormData;
    const formData =
      selectedService.type === 'service'
        ? serviceFormData
        : selectedService.type === 'feature'
        ? featureFormData
        : supportFormData;
    const [error, data] = await apiRequest({
      method: 'post',
      url: apiRoutes.premiumRequest,
      data: {
        request_type:
          selectedService.title === 'Premium Support'
            ? `New ${selectedService.title} Request`
            : selectedService.title === 'Training Request'
            ? `New PDU Request`
            : `New ${selectedService.title}`,
        request_urgency:
          formData.urgency || 'Normal Urgency 7-10 Business Days',
        request_details: formData.details,
        subdomain: subdomain,
        email: email,
        ...formData,
      },
    });
    if (data.status === 200) {
      // console.log('Request submitted successfully');
    } else {
      console.warn('Unexpected response status:', data.status);
    }
    setCurrentStep(STEPS.CONFIRMATION);
  };

  const handleSupportFormDataChange = (newFormData) => {
    setSupportFormData(newFormData);
  };

  const handleServiceFormDataChange = (newFormData) => {
    setServiceFormData(newFormData);
  };

  const handlFeatureFormDataChange = (newFormData) => {
    setFeatureFormData(newFormData);
  };

  const renderServiceOptions = () => (
    <article className='premium-request__modal-main'>
      {currentStep === STEPS.MAIN && (
        <p className='premium-request__modal-card-description'>
          Please select one of the request types below
        </p>
      )}
      {SERVICES.map((service, index) => {
        if (currentStep !== STEPS.MAIN && service !== selectedService) {
          return null;
        }
        return (
          <section
            key={index}
            onClick={() => currentStep === STEPS.MAIN && selectRequest(service)}
            className={`premium-request__modal-main-card ${
              selectedService === service ? 'selected' : ''
            }`}
          >
            <section className='premium-request__modal-card-item__container'>
              <article className='premium-request__modal-card-title__container'>
                <FontAwesomeIcon
                  icon={service.icon}
                  className='premium-request__modal-card-icon'
                />
              </article>
              <article>
                <h3 className='premium-request__modal-card-title'>
                  {service.title}
                </h3>
                <p className='premium-request__modal-card-description'>
                  {service.description}
                </p>
              </article>
            </section>
            {selectedService === service && (
              <article className='premium-request__modal-card-icon_selected'>
                <FontAwesomeIcon icon={faCheck} />
              </article>
            )}
          </section>
        );
      })}
    </article>
  );

  const renderStepContent = () => {
    switch (currentStep) {
      case STEPS.SUPPORT:
      case STEPS.TRAINING:
      case STEPS.OTHER:
        return (
          <SupportModal
            subdomain={subdomain}
            email={email}
            service={selectedService}
            onFormDataChange={handleSupportFormDataChange}
            initialFormData={supportFormData}
          />
        );
      case STEPS.FEATURE:
        return (
          <FeatureModal
            subdomain={subdomain}
            email={email}
            service={selectedService}
            onFormDataChange={handlFeatureFormDataChange}
            initialFormData={featureFormData}
          />
        );
      case STEPS.SERVICE:
        return (
          <ServiceModal
            onFormDataChange={handleServiceFormDataChange}
            initialFormData={serviceFormData}
          />
        );
      case STEPS.CONFIRMATION:
        return (
          <div className='premium-request__modal-confirmation'>
            <span className='premium-request__modal-confirmation__header-icon'>
              <FontAwesomeIcon icon={faCheck} />
            </span>
            <h3 className='premium-request__modal-confirmation__header'>
              Your request has been submitted Successfully!
            </h3>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <section
      className='premium-request__modal'
      role='dialog'
      aria-modal='true'
    >
      <article className='premium-request__modal-content'>
        <div className='premium-request__modal-header'>
          <h2 className='premium-request__modal-title'>Request Form</h2>
          <button
            className='premium-request__modal-close'
            onClick={() => setShowRequestModal(false)}
            aria-label='Close modal'
          >
            ×
          </button>
        </div>

        <div className='premium-request__modal-body'>
          {renderServiceOptions()}
          {renderStepContent()}
        </div>

        <section className='premium-request__modal-footer'>
          {currentStep !== STEPS.MAIN && currentStep !== STEPS.CONFIRMATION && (
            <button
              className='premium-request__modal-footer_cancel-button'
              onClick={handleBack}
            >
              Go Back
            </button>
          )}
          {currentStep !== STEPS.CONFIRMATION && (
            <button
              className='primary premium-request__modal-footer_confirm-button'
              onClick={handleNext}
              disabled={!isFormValid}
            >
              Next
            </button>
          )}
          {currentStep === STEPS.CONFIRMATION && (
            <button
              className='premium-request__modal-footer_confirm-button'
              onClick={() => setShowRequestModal(false)}
            >
              Close
            </button>
          )}
        </section>
      </article>
    </section>
  );
};

export default PremiumRequestModal;

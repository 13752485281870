import { NodeType } from "../components/TreeNode/TreeNodeType";

export const deepCopy = (obj: any) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  const copy: any = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    copy[key] = deepCopy(obj[key]);
  }
  return copy;
};

export const deepCompare = (obj1: any, obj2: any) => {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepCompare(obj1[key], obj2[key])) {
      return false;
    }
  }
  return true;
};

export const formatDateYYYYMMDD = (dateInput: Date) => {
  const year = dateInput.getFullYear();
  const month = String(dateInput.getMonth() + 1).padStart(2, "0");
  const day = String(dateInput.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const SeverityToColorLiteral = {
  Critical: "#CD0000",
  Major: "#E87000",
  High: "#F7C604",
  Medium: "#690375",
  Low: "#329CAD",
  Minor: "#CECECE",
  Info: "#117170",
};

export const toggleChevronIcon = (status: boolean) => {
  return status ? "ChevronUpIcon" : "ChevronDownIcon";
};

export const capitalizeName = (name: string): string => {
  return name
    ? name
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase())
        .join("")
    : "";
};

export const IconStatusLiteral = {
  "In Progress": "RocketLaunchIcon",
  "Not Started": "BellAlertIcon",
  Done: "CheckCircleIcon",
  Blocked: "NoSymbolIcon",
  Disabled: "NoSymbolIcon",
};

export const convertMinutesToHours = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours} hr ${remainingMinutes} minutes`;
};

export const getAllUsers = (data: NodeType[]) => {
  const result: { color: any; name: any; team: any; teamColor: any }[] = [];

  function traverse(members: NodeType[]) {
    for (const member of members) {
      result.push({
        name: member.name,
        color: member.color,
        team: member.team,
        teamColor: member.teamColor,
      });
      if (member.teamMembers && member.teamMembers.length > 0) {
        traverse(member.teamMembers);
      }
    }
  }

  traverse(data);
  return result;
};

export const getIconsOptions = () => {
  return [
    { name: "Academic Cap", value: "AcademicCapIcon" },
    { name: "Adjustments Horizontal", value: "AdjustmentsHorizontalIcon" },
    { name: "Adjustments Vertical", value: "AdjustmentsVerticalIcon" },
    { name: "Archive Box Arrow Down", value: "ArchiveBoxArrowDownIcon" },
    { name: "Archive Box X Mark", value: "ArchiveBoxXMarkIcon" },
    { name: "Archive Box", value: "ArchiveBoxIcon" },
    { name: "Arrow Down Circle", value: "ArrowDownCircleIcon" },
    { name: "Arrow Down Left", value: "ArrowDownLeftIcon" },
    { name: "Arrow Down On Square Stack", value: "ArrowDownOnSquareStackIcon" },
    { name: "Arrow Down On Square", value: "ArrowDownOnSquareIcon" },
    { name: "Arrow Down Right", value: "ArrowDownRightIcon" },
    { name: "Arrow Down Tray", value: "ArrowDownTrayIcon" },
    { name: "Arrow Down", value: "ArrowDownIcon" },
    { name: "Arrow Left Circle", value: "ArrowLeftCircleIcon" },
    {
      name: "Arrow Left End On Rectangle",
      value: "ArrowLeftEndOnRectangleIcon",
    },
    {
      name: "Arrow Left Start On Rectangle",
      value: "ArrowLeftStartOnRectangleIcon",
    },
    { name: "Arrow Left", value: "ArrowLeftIcon" },
    { name: "Arrow Long Down", value: "ArrowLongDownIcon" },
    { name: "Arrow Long Left", value: "ArrowLongLeftIcon" },
    { name: "Arrow Long Right", value: "ArrowLongRightIcon" },
    { name: "Arrow Long Up", value: "ArrowLongUpIcon" },
    { name: "Arrow Path Rounded Square", value: "ArrowPathRoundedSquareIcon" },
    { name: "Arrow Path", value: "ArrowPathIcon" },
    { name: "Arrow Right Circle", value: "ArrowRightCircleIcon" },
    {
      name: "Arrow Right End On Rectangle",
      value: "ArrowRightEndOnRectangleIcon",
    },
    {
      name: "Arrow Right Start On Rectangle",
      value: "ArrowRightStartOnRectangleIcon",
    },
    { name: "Arrow Right", value: "ArrowRightIcon" },
    { name: "Arrow Top Right On Square", value: "ArrowTopRightOnSquareIcon" },
    { name: "Arrow Trending Down", value: "ArrowTrendingDownIcon" },
    { name: "Arrow Trending Up", value: "ArrowTrendingUpIcon" },
    { name: "Arrow Turn Down Left", value: "ArrowTurnDownLeftIcon" },
    { name: "Arrow Turn Down Right", value: "ArrowTurnDownRightIcon" },
    { name: "Arrow Turn Left Down", value: "ArrowTurnLeftDownIcon" },
    { name: "Arrow Turn Left Up", value: "ArrowTurnLeftUpIcon" },
    { name: "Arrow Turn Right Down", value: "ArrowTurnRightDownIcon" },
    { name: "Arrow Turn Right Up", value: "ArrowTurnRightUpIcon" },
    { name: "Arrow Turn Up Left", value: "ArrowTurnUpLeftIcon" },
    { name: "Arrow Turn Up Right", value: "ArrowTurnUpRightIcon" },
    { name: "Arrow Up Circle", value: "ArrowUpCircleIcon" },
    { name: "Arrow Up Left", value: "ArrowUpLeftIcon" },
    { name: "Arrow Up On Square Stack", value: "ArrowUpOnSquareStackIcon" },
    { name: "Arrow Up On Square", value: "ArrowUpOnSquareIcon" },
    { name: "Arrow Up Right", value: "ArrowUpRightIcon" },
    { name: "Arrow Up Tray", value: "ArrowUpTrayIcon" },
    { name: "Arrow Up", value: "ArrowUpIcon" },
    { name: "Arrow Uturn Down", value: "ArrowUturnDownIcon" },
    { name: "Arrow Uturn Left", value: "ArrowUturnLeftIcon" },
    { name: "Arrow Uturn Right", value: "ArrowUturnRightIcon" },
    { name: "Arrow Uturn Up", value: "ArrowUturnUpIcon" },
    { name: "Arrows Pointing In", value: "ArrowsPointingInIcon" },
    { name: "Arrows Pointing Out", value: "ArrowsPointingOutIcon" },
    { name: "Arrows Right Left", value: "ArrowsRightLeftIcon" },
    { name: "Arrows Up Down", value: "ArrowsUpDownIcon" },
    { name: "At Symbol", value: "AtSymbolIcon" },
    { name: "Backspace", value: "BackspaceIcon" },
    { name: "Backward", value: "BackwardIcon" },
    { name: "Banknotes", value: "BanknotesIcon" },
    { name: "Bars 2", value: "Bars2Icon" },
    { name: "Bars 3 Bottom Left", value: "Bars3BottomLeftIcon" },
    { name: "Bars 3 Bottom Right", value: "Bars3BottomRightIcon" },
    { name: "Bars 3 Center Left", value: "Bars3CenterLeftIcon" },
    { name: "Bars 3", value: "Bars3Icon" },
    { name: "Bars 4", value: "Bars4Icon" },
    { name: "Bars Arrow Down", value: "BarsArrowDownIcon" },
    { name: "Bars Arrow Up", value: "BarsArrowUpIcon" },
    { name: "Battery 0", value: "Battery0Icon" },
    { name: "Battery 100", value: "Battery100Icon" },
    { name: "Battery 50", value: "Battery50Icon" },
    { name: "Beaker", value: "BeakerIcon" },
    { name: "Bell Alert", value: "BellAlertIcon" },
    { name: "Bell Slash", value: "BellSlashIcon" },
    { name: "Bell Snooze", value: "BellSnoozeIcon" },
    { name: "Bell", value: "BellIcon" },
    { name: "Bold", value: "BoldIcon" },
    { name: "Bolt Slash", value: "BoltSlashIcon" },
    { name: "Bolt", value: "BoltIcon" },
    { name: "Book Open", value: "BookOpenIcon" },
    { name: "Bookmark Slash", value: "BookmarkSlashIcon" },
    { name: "Bookmark Square", value: "BookmarkSquareIcon" },
    { name: "Bookmark", value: "BookmarkIcon" },
    { name: "Briefcase", value: "BriefcaseIcon" },
    { name: "Bug Ant", value: "BugAntIcon" },
    { name: "Building Library", value: "BuildingLibraryIcon" },
    { name: "Building Office 2", value: "BuildingOffice2Icon" },
    { name: "Building Office", value: "BuildingOfficeIcon" },
    { name: "Building Storefront", value: "BuildingStorefrontIcon" },
    { name: "Cake", value: "CakeIcon" },
    { name: "Calculator", value: "CalculatorIcon" },
    { name: "Calendar Date Range", value: "CalendarDateRangeIcon" },
    { name: "Calendar Days", value: "CalendarDaysIcon" },
    { name: "Calendar", value: "CalendarIcon" },
    { name: "Camera", value: "CameraIcon" },
    { name: "Chart Bar Square", value: "ChartBarSquareIcon" },
    { name: "Chart Bar", value: "ChartBarIcon" },
    { name: "Chart Pie", value: "ChartPieIcon" },
    {
      name: "Chat Bubble Bottom Center Text",
      value: "ChatBubbleBottomCenterTextIcon",
    },
    { name: "Chat Bubble Bottom Center", value: "ChatBubbleBottomCenterIcon" },
    { name: "Chat Bubble Left Ellipsis", value: "ChatBubbleLeftEllipsisIcon" },
    { name: "Chat Bubble Left Right", value: "ChatBubbleLeftRightIcon" },
    { name: "Chat Bubble Left", value: "ChatBubbleLeftIcon" },
    {
      name: "Chat Bubble Oval Left Ellipsis",
      value: "ChatBubbleOvalLeftEllipsisIcon",
    },
    { name: "Chat Bubble Oval Left", value: "ChatBubbleOvalLeftIcon" },
    { name: "Check Badge", value: "CheckBadgeIcon" },
    { name: "Check Circle", value: "CheckCircleIcon" },
    { name: "Check", value: "CheckIcon" },
    { name: "Chevron Double Down", value: "ChevronDoubleDownIcon" },
    { name: "Chevron Double Left", value: "ChevronDoubleLeftIcon" },
    { name: "Chevron Double Right", value: "ChevronDoubleRightIcon" },
    { name: "Chevron Double Up", value: "ChevronDoubleUpIcon" },
    { name: "Chevron Down", value: "ChevronDownIcon" },
    { name: "Chevron Left", value: "ChevronLeftIcon" },
    { name: "Chevron Right", value: "ChevronRightIcon" },
    { name: "Chevron Up Down", value: "ChevronUpDownIcon" },
    { name: "Chevron Up", value: "ChevronUpIcon" },
    { name: "Circle Stack", value: "CircleStackIcon" },
    { name: "Clipboard Document Check", value: "ClipboardDocumentCheckIcon" },
    { name: "Clipboard Document List", value: "ClipboardDocumentListIcon" },
    { name: "Clipboard Document", value: "ClipboardDocumentIcon" },
    { name: "Clipboard", value: "ClipboardIcon" },
    { name: "Clock", value: "ClockIcon" },
    { name: "Cloud Arrow Down", value: "CloudArrowDownIcon" },
    { name: "Cloud Arrow Up", value: "CloudArrowUpIcon" },
    { name: "Cloud", value: "CloudIcon" },
    { name: "Code Bracket Square", value: "CodeBracketSquareIcon" },
    { name: "Code Bracket", value: "CodeBracketIcon" },
    { name: "Cog 6 Toother", value: "Cog6TootherIcon" },
    { name: "Cog 8 Toother", value: "Cog8TootherIcon" },
    { name: "Cog", value: "CogIcon" },
    { name: "Command Line", value: "CommandLineIcon" },
    { name: "Computer Desktop", value: "ComputerDesktopIcon" },
    { name: "Cpu Chip", value: "CpuChipIcon" },
    { name: "Credit Card", value: "CreditCardIcon" },
    { name: "Cube Transparent", value: "CubeTransparentIcon" },
    { name: "Cube", value: "CubeIcon" },
    { name: "Currency Bangladeshi", value: "CurrencyBangladeshiIcon" },
    { name: "Currency Dollar", value: "CurrencyDollarIcon" },
    { name: "Currency Euro", value: "CurrencyEuroIcon" },
    { name: "Currency Pound", value: "CurrencyPoundIcon" },
    { name: "Currency Rupee", value: "CurrencyRupeeIcon" },
    { name: "Currency Yen", value: "CurrencyYenIcon" },
    { name: "Cursor Arrow Rays", value: "CursorArrowRaysIcon" },
    { name: "Cursor Arrow Ripple", value: "CursorArrowRippleIcon" },
    { name: "Device Phone Mobile", value: "DevicePhoneMobileIcon" },
    { name: "Device Tablet", value: "DeviceTabletIcon" },
    { name: "Document Arrow Down", value: "DocumentArrowDownIcon" },
    { name: "Document Arrow Up", value: "DocumentArrowUpIcon" },
    { name: "Document Chart Bar", value: "DocumentChartBarIcon" },
    { name: "Document Check", value: "DocumentCheckIcon" },
    { name: "Document Duplicate", value: "DocumentDuplicateIcon" },
    { name: "Document Magnifying Glass", value: "DocumentMagnifyingGlassIcon" },
    { name: "Document Minus", value: "DocumentMinusIcon" },
    { name: "Document Plus", value: "DocumentPlusIcon" },
    { name: "Document Text", value: "DocumentTextIcon" },
    { name: "Document", value: "DocumentIcon" },
    {
      name: "Ellipsis Horizontal Circle",
      value: "EllipsisHorizontalCircleIcon",
    },
    { name: "Ellipsis Horizontal", value: "EllipsisHorizontalIcon" },
    { name: "Ellipsis Vertical", value: "EllipsisVerticalIcon" },
    { name: "Envelope Open", value: "EnvelopeOpenIcon" },
    { name: "Envelope", value: "EnvelopeIcon" },
    { name: "Exclamation Circle", value: "ExclamationCircleIcon" },
    { name: "Exclamation Triangle", value: "ExclamationTriangleIcon" },
    { name: "Eye Dropper", value: "EyeDropperIcon" },
    { name: "Eye Slash", value: "EyeSlashIcon" },
    { name: "Eye", value: "EyeIcon" },
    { name: "Face Frown", value: "FaceFrownIcon" },
    { name: "Face Smile", value: "FaceSmileIcon" },
    { name: "Film", value: "FilmIcon" },
    { name: "Finger Print", value: "FingerPrintIcon" },
    { name: "Fire", value: "FireIcon" },
    { name: "Flag", value: "FlagIcon" },
    { name: "Folder Arrow Down", value: "FolderArrowDownIcon" },
    { name: "Folder Minus", value: "FolderMinusIcon" },
    { name: "Folder Open", value: "FolderOpenIcon" },
    { name: "Folder Plus", value: "FolderPlusIcon" },
    { name: "Folder", value: "FolderIcon" },
    { name: "Forward", value: "ForwardIcon" },
    { name: "Funnel", value: "FunnelIcon" },
    { name: "Gif", value: "GifIcon" },
    { name: "Gift Top", value: "GiftTopIcon" },
    { name: "Gift", value: "GiftIcon" },
    { name: "Globe Alt", value: "GlobeAltIcon" },
    { name: "Globe Americas", value: "GlobeAmericasIcon" },
    { name: "Globe Asia Australia", value: "GlobeAsiaAustraliaIcon" },
    { name: "Globe Europe Africa", value: "GlobeEuropeAfricaIcon" },
    { name: "Hand Raised", value: "HandRaisedIcon" },
    { name: "Hand Thumb Down", value: "HandThumbDownIcon" },
    { name: "Hand Thumb Up", value: "HandThumbUpIcon" },
    { name: "Hashtag", value: "HashtagIcon" },
    { name: "Heart", value: "HeartIcon" },
    { name: "Home Modern", value: "HomeModernIcon" },
    { name: "Home", value: "HomeIcon" },
    { name: "Identification", value: "IdentificationIcon" },
    { name: "Inbox Arrow Down", value: "InboxArrowDownIcon" },
    { name: "Inbox Stack", value: "InboxStackIcon" },
    { name: "Inbox", value: "InboxIcon" },
    { name: "Information Circle", value: "InformationCircleIcon" },
    { name: "Key", value: "KeyIcon" },
    { name: "Language", value: "LanguageIcon" },
    { name: "Lifebuoy", value: "LifebuoyIcon" },
    { name: "Light Bulb", value: "LightBulbIcon" },
    { name: "Link", value: "LinkIcon" },
    { name: "List Bullet", value: "ListBulletIcon" },
    { name: "Lock Closed", value: "LockClosedIcon" },
    { name: "Lock Open", value: "LockOpenIcon" },
    { name: "Magnifying Glass Circle", value: "MagnifyingGlassCircleIcon" },
    { name: "Magnifying Glass Minus", value: "MagnifyingGlassMinusIcon" },
    { name: "Magnifying Glass Plus", value: "MagnifyingGlassPlusIcon" },
    { name: "Magnifying Glass", value: "MagnifyingGlassIcon" },
    { name: "Map Pin", value: "MapPinIcon" },
    { name: "Map", value: "MapIcon" },
    { name: "Megaphone", value: "MegaphoneIcon" },
    { name: "Microphone", value: "MicrophoneIcon" },
    { name: "Minus Circle", value: "MinusCircleIcon" },
    { name: "Minus Small", value: "MinusSmallIcon" },
    { name: "Minus", value: "MinusIcon" },
    { name: "Moon", value: "MoonIcon" },
    { name: "Musical Note", value: "MusicalNoteIcon" },
    { name: "Newspaper", value: "NewspaperIcon" },
    { name: "No Symbol", value: "NoSymbolIcon" },
    { name: "Paint Brush", value: "PaintBrushIcon" },
    { name: "Paper Airplane", value: "PaperAirplaneIcon" },
    { name: "Paper Clip", value: "PaperClipIcon" },
    { name: "Pause Circle", value: "PauseCircleIcon" },
    { name: "Pause", value: "PauseIcon" },
    { name: "Pencil Square", value: "PencilSquareIcon" },
    { name: "Pencil", value: "PencilIcon" },
    { name: "Phone Arrow Down Left", value: "PhoneArrowDownLeftIcon" },
    { name: "Phone Arrow Up Right", value: "PhoneArrowUpRightIcon" },
    { name: "Phone X Mark", value: "PhoneXMarkIcon" },
    { name: "Phone", value: "PhoneIcon" },
    { name: "Photo", value: "PhotoIcon" },
    { name: "Play Circle", value: "PlayCircleIcon" },
    { name: "Play Pause", value: "PlayPauseIcon" },
    { name: "Play", value: "PlayIcon" },
    { name: "Plus Circle", value: "PlusCircleIcon" },
    { name: "Plus Small", value: "PlusSmallIcon" },
    { name: "Plus", value: "PlusIcon" },
    { name: "Power", value: "PowerIcon" },
    { name: "Presentation Chart Bar", value: "PresentationChartBarIcon" },
    { name: "Presentation Chart Line", value: "PresentationChartLineIcon" },
    { name: "Printer", value: "PrinterIcon" },
    { name: "Puzzle Piece", value: "PuzzlePieceIcon" },
    { name: "Qr Code", value: "QrCodeIcon" },
    { name: "Question Mark Circle", value: "QuestionMarkCircleIcon" },
    { name: "Queue List", value: "QueueListIcon" },
    { name: "Radio", value: "RadioIcon" },
    { name: "Receipt Percent", value: "ReceiptPercentIcon" },
    { name: "Receipt Refund", value: "ReceiptRefundIcon" },
    { name: "Rectangle Group", value: "RectangleGroupIcon" },
    { name: "Rectangle Stack", value: "RectangleStackIcon" },
    { name: "Rocket Launch", value: "RocketLaunchIcon" },
    { name: "Rss", value: "RssIcon" },
    { name: "Scale", value: "ScaleIcon" },
    { name: "Scissors", value: "ScissorsIcon" },
    { name: "Server Stack", value: "ServerStackIcon" },
    { name: "Server", value: "ServerIcon" },
    { name: "Share", value: "ShareIcon" },
    { name: "Shield Check", value: "ShieldCheckIcon" },
    { name: "Shield Exclamation", value: "ShieldExclamationIcon" },
    { name: "Shopping Bag", value: "ShoppingBagIcon" },
    { name: "Shopping Cart", value: "ShoppingCartIcon" },
    { name: "Signal Slash", value: "SignalSlashIcon" },
    { name: "Signal", value: "SignalIcon" },
    { name: "Sparkles", value: "SparklesIcon" },
    { name: "Speaker Wave", value: "SpeakerWaveIcon" },
    { name: "Speaker X Mark", value: "SpeakerXMarkIcon" },
    { name: "Square 2 Stack", value: "Square2StackIcon" },
    { name: "Square 3 Stack 3d", value: "Square3Stack3dIcon" },
    { name: "Squares Plus", value: "SquaresPlusIcon" },
    { name: "Star", value: "StarIcon" },
    { name: "Stop Circle", value: "StopCircleIcon" },
    { name: "Stop", value: "StopIcon" },
    { name: "Sun", value: "SunIcon" },
    { name: "Swatch", value: "SwatchIcon" },
    { name: "Table Cells", value: "TableCellsIcon" },
    { name: "Tag", value: "TagIcon" },
    { name: "Ticket", value: "TicketIcon" },
    { name: "Trash", value: "TrashIcon" },
    { name: "Trophy", value: "TrophyIcon" },
    { name: "Truck", value: "TruckIcon" },
    { name: "Tv", value: "TvIcon" },
    { name: "User Circle", value: "UserCircleIcon" },
    { name: "User Group", value: "UserGroupIcon" },
    { name: "User Minus", value: "UserMinusIcon" },
    { name: "User Plus", value: "UserPlusIcon" },
    { name: "User", value: "UserIcon" },
    { name: "Users", value: "UsersIcon" },
    { name: "Variable", value: "VariableIcon" },
    { name: "Video Camera Slash", value: "VideoCameraSlashIcon" },
    { name: "Video Camera", value: "VideoCameraIcon" },
    { name: "View Columns", value: "ViewColumnsIcon" },
    { name: "Viewfinder Circle", value: "ViewfinderCircleIcon" },
    { name: "Wallet", value: "WalletIcon" },
    { name: "Wifi", value: "WifiIcon" },
    { name: "Window", value: "WindowIcon" },
    { name: "Wrench", value: "WrenchIcon" },
    { name: "X Circle", value: "XCircleIcon" },
    { name: "X Mark", value: "XMarkIcon" },
  ];
};

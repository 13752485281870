// components/ProductSubscriptions/index.tsx
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import {
  Header,
  TitleContainer,
  Title,
  TableContainer,
  TableWrapper,
  Table,
  TableHead,
  TableBody,
  Th,
  Td
} from './ClientDeliveryDashboard.styled';

export const SectionContainer = styled.div`
  margin-top: 12px;
  min-width: 100%;
  background-color: white;
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;


export const IconButton = styled.div`
  width: 2rem;
  cursor: pointer;
`;

const CustomerHeader = styled.th`
  padding: 0.5rem 0.875rem;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 600;
  color: #117170;
  background-color: rgb(18, 113, 112, 0.2);
  border-top: 1px solid #e5e7eb;
`;

const ProductRow = styled.tr<{ isFirst?: boolean }>`
  border-top: 1px solid ${props => props.isFirst ? '#cbd5e1' : '#e5e7eb'};
`;

const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  border-bottom: solid #F7F7F7;
  width: 100%;
`;

const ProductName = styled.div`
  cursor: pointer;
  font-weight: 500;
  color: #1f2937;
`;

const LicenseType = styled.div`
  margin-top: 4px;
  font-size: 14px;
  color: #6b7280;
`;

const ExpandIcon = styled.svg`
  width: 20px;
  cursor: pointer;
`;

interface ProductSubscriptionsProps {
  showProducts: boolean;
  setShowProducts: (show: boolean) => void;
  productSummary: any[];
  isProductExpanded: { [key: string]: boolean };
  setProductExpanded: (customerName: string, expanded: boolean) => void;
  goToPath: (path: string) => void;
}

export const ProductSubscriptions: React.FC<ProductSubscriptionsProps> = ({
  showProducts,
  setShowProducts,
  productSummary = [],
  isProductExpanded,
  setProductExpanded,
  goToPath
}) => (
  <SectionContainer>
    <Header>
      <TitleContainer>
        <Title>Product Subscriptions & Add Ons</Title>
      </TitleContainer>
      <IconButton onClick={() => setShowProducts(!showProducts)}>
        {showProducts ? (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#117170">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#117170">
            <path
              fillRule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </IconButton>
    </Header>

    {productSummary.length > 0 && showProducts && (
      <TableContainer>
        <TableWrapper>
          <Table>
            <TableHead>
              <tr>
                <Th>Customer Product Name</Th>
                <Th>Available Licenses</Th>
                <Th>Deployed Licenses</Th>
                <Th>Purchased Licenses</Th>
                <Th align="right">
                  <span className="sr-only">Edit</span>
                </Th>
              </tr>
            </TableHead>
            <TableBody>
              {productSummary.map((item, i) => (
                <>
                  <tr key={item.customer_name}>
                    <CustomerHeader colSpan={5}>
                      {item.customer_name}
                      <ExpandIcon
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        onClick={() => setProductExpanded(item.customer_name, !isProductExpanded[item.customer_name])}
                      >
                        <path
                          fillRule="evenodd"
                          d={isProductExpanded[item.customer_name] 
                            ? 'M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z'
                            : 'M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                          }
                          clipRule="evenodd"
                        />
                      </ExpandIcon>
                    </CustomerHeader>
                  </tr>
                  {isProductExpanded[item.customer_name] &&
                    item.products.map((product, index) => (
                      <ProductRow key={index} isFirst={index === 0}>
                        <Td>
                          <ProductInfo>
                            <div>
                              <ProductName onClick={() => goToPath('PsStatus')}>
                                {product.customer_product_name}
                              </ProductName>
                              <LicenseType>{product.licence_type}</LicenseType>
                            </div>
                          </ProductInfo>
                        </Td>
                        <Td>{product.customer_available_licenses}</Td>
                        <Td>{product.customer_deployed_licenses}</Td>
                        <Td>{product.customer_purchased_licenses}</Td>
                      </ProductRow>
                    ))}
                </>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </TableContainer>
    )}
  </SectionContainer>
);
import { useRef, useState } from "react";
import {
  ChevronRowIconsContainer,
  ChevronRowTitle,
  ChevronRowTitleContainer,
  ChevronRowTitleContainerTop,
  PageContainer,
  SpanButton,
} from "@/common.styled.tsx";
import {
  Icon,
  ProfileCircle,
  QuartersPill,
  SelectStatus,
  
} from "@/components/index.ts";
import { currentQuarterType } from "@/components/QuartersPill/QuartersPillType.ts";
import {
  Container,
  ContainerCards,
  ContainerCardsUpper,
  MemberCardContainer,
  NoDoneStatusTexy,
  NothingHere,
  PeopleOnMyTeamCardsContainer,
  PeopleOnMyTeamContainer,
  TopContainer,
} from "./Goals.styled.tsx";
import { toggleChevronIcon } from "@/utils/shared-functions.js";
import MemberCardComponent from "./MemberCard/index.ts";
import FormModal from "./FormModal/FormModal.tsx";
import TeamFormModal from "./TeamFormModal/TeamFormModal.tsx";
import { IconsType } from "@/components/Icon/IconType.ts";
import { goalsTypeArray, statusType, teamsInfoType } from "@/models";
import { useTeamsInfoSelector, useUserManagementInfoSelector } from "@/store/states/index.ts";

function Goals() {
  const formModal = useRef<any>();
  const teamFormModal = useRef<any>();
  const userInfo = useUserManagementInfoSelector();
  const teamsInfo = useTeamsInfoSelector();
  const [currentQuarter, setCurrentQuarter] =
    useState<currentQuarterType>("Q1");
  const [peopleOnMyTeamOpenArray, setPeopleOnMyTeamOpenArray] = useState<
    Array<number>
  >([]);
  const [peopleOnMyTeamOpenArrayDone, setPeopleOnMyTeamOpenArrayDone] =
    useState<Array<number>>([]);
  const [peopleOnMyTeamOpen, setPeopleOnMyTeamOpen] = useState<boolean>(false);
  const [myGoalsOpen, setMyGoalsOpen] = useState<boolean>(true);
  const [myGoalsOpenDone, setMyGoalsOpenDone] = useState<boolean>(false);
  const [currentStatusFilters, setCurrentStatusFilters] = useState<
    Array<statusType>
  >(["In Progress", "Not Started", "Blocked"]);

  const handlePeopleOnMyTeamOpen = (index: number) => {
    if (peopleOnMyTeamOpenArray.includes(index)) {
      setPeopleOnMyTeamOpenArray(
        peopleOnMyTeamOpenArray.filter((item) => item !== index),
      );
    } else {
      setPeopleOnMyTeamOpenArray([...peopleOnMyTeamOpenArray, index]);
    }
  };

  const updateCurrentStatusFilters = (status: statusType) => {
    if (currentStatusFilters.includes(status)) {
      setCurrentStatusFilters(
        currentStatusFilters.filter((item) => item !== status),
      );
    } else {
      setCurrentStatusFilters([...currentStatusFilters, status]);
    }
  };

  const handlePeopleOnMyTeamOpenDone = (index: number) => {
    if (peopleOnMyTeamOpenArrayDone.includes(index)) {
      setPeopleOnMyTeamOpenArrayDone(
        peopleOnMyTeamOpenArrayDone.filter((item) => item !== index),
      );
    } else {
      setPeopleOnMyTeamOpenArrayDone([...peopleOnMyTeamOpenArrayDone, index]);
    }
  };

  const onIconClicked = (icon: IconsType) => {
    if (icon === "ChevronDownIcon" || icon === "ChevronUpIcon") {
      setMyGoalsOpen(!myGoalsOpen);
    }
    if (icon === "PlusCircleIcon") {
      formModal?.current?.addNewGoal();
    }
  };

  return (
    <>
      <PageContainer>
        <TopContainer>
          <QuartersPill
            onClick={(quarter: currentQuarterType) => {
              setCurrentQuarter(quarter);
            }}
            currentQuarter={currentQuarter}
          />
          <SelectStatus
            currentStatusFilters={currentStatusFilters}
            updateCurrentStatusFilters={updateCurrentStatusFilters}
          />
        </TopContainer>
        <Container>
          <ContainerCardsUpper>
            <ProfileCircle
              title={userInfo.name}
              users={[{ name: userInfo.name, color: userInfo.color }]}
              arrayOfIcons={[
                { icon: "PlusCircleIcon", type: "outline" },
                { icon: toggleChevronIcon(myGoalsOpen), type: "outline" },
              ]}
              onIconClick={(icon) => {
                onIconClicked(icon);
              }}
            />
            {myGoalsOpen && (
              <>
                {userInfo.goals[currentQuarter].filter((goal: goalsTypeArray) =>
                  currentStatusFilters.includes(goal.status),
                ).length > 0 ? (
                  <MemberCardContainer>
                    {userInfo.goals[currentQuarter]
                      .filter((goal: goalsTypeArray) =>
                        currentStatusFilters.includes(goal.status),
                      )
                      .map((goals: goalsTypeArray, index: number) => (
                        <MemberCardComponent
                          key={goals.name}
                          {...goals}
                          isSuperior={false}
                          onClickEdit={() =>
                            formModal?.current?.editGoal(index, goals)
                          }
                        />
                      ))}
                  </MemberCardContainer>
                ) : (
                  <NothingHere>No Goals</NothingHere>
                )}
                <ChevronRowTitleContainerTop $isOpen={myGoalsOpenDone}>
                  <ChevronRowTitle>Done</ChevronRowTitle>
                  <ChevronRowIconsContainer>
                    <SpanButton
                      onClick={() => {
                        setMyGoalsOpenDone(!myGoalsOpenDone);
                      }}
                    >
                      <Icon
                        icon={toggleChevronIcon(myGoalsOpenDone)}
                        type={"outline"}
                      />
                    </SpanButton>
                  </ChevronRowIconsContainer>
                </ChevronRowTitleContainerTop>
                {myGoalsOpenDone && (
                  <>
                    {userInfo.goals[currentQuarter].filter(
                      (goal: goalsTypeArray) => goal.status === "Done",
                    ).length > 0 ? (
                      <MemberCardContainer>
                        {userInfo.goals[currentQuarter]
                          .filter(
                            (goal: goalsTypeArray) => goal.status === "Done",
                          )
                          .map((goal: goalsTypeArray) => (
                            <MemberCardComponent
                              {...goal}
                              isSuperior={false}
                              key={goal.name}
                            />
                          ))}
                      </MemberCardContainer>
                    ) : (
                      <NothingHere>
                        No Goals with status{" "}
                        <NoDoneStatusTexy>Done</NoDoneStatusTexy>
                      </NothingHere>
                    )}
                  </>
                )}
              </>
            )}
          </ContainerCardsUpper>
          <ContainerCards>
            <ProfileCircle
              title={"People on my teams"}
              users={teamsInfo}
              arrayOfIcons={[
                {
                  icon: toggleChevronIcon(peopleOnMyTeamOpen),
                  type: "outline",
                },
              ]}
              onIconClick={(icon) =>
                (icon === "ChevronDownIcon" || icon === "ChevronUpIcon") &&
                setPeopleOnMyTeamOpen(!peopleOnMyTeamOpen)
              }
            />
            {peopleOnMyTeamOpen && (
              <ContainerCardsUpper>
                {teamsInfo.map((member: teamsInfoType, memberIndex: number) => (
                  <PeopleOnMyTeamContainer key={member.name}>
                    <ChevronRowTitleContainer>
                      <ChevronRowTitle>{member.name}</ChevronRowTitle>
                      <ChevronRowIconsContainer>
                        <SpanButton
                          onClick={() =>
                            teamFormModal?.current?.setUser(memberIndex)
                          }
                        >
                          <Icon icon={"PlusCircleIcon"} type={"outline"} />
                        </SpanButton>
                        <SpanButton
                          onClick={() => {
                            handlePeopleOnMyTeamOpen(memberIndex);
                          }}
                        >
                          <Icon
                            icon={toggleChevronIcon(
                              peopleOnMyTeamOpenArray.includes(memberIndex),
                            )}
                            type={"outline"}
                          />
                        </SpanButton>
                      </ChevronRowIconsContainer>
                    </ChevronRowTitleContainer>
                    {peopleOnMyTeamOpenArray.includes(memberIndex) && (
                      <>
                        {member.goals[currentQuarter as keyof typeof member.goals].filter(
                          (goal: goalsTypeArray) =>
                            currentStatusFilters.includes(goal.status),
                        ).length > 0 ? (
                          <PeopleOnMyTeamCardsContainer>
                            {member.goals[currentQuarter as keyof typeof member.goals]
                              .filter((goal: goalsTypeArray) =>
                                currentStatusFilters.includes(goal.status),
                              )
                              .map((goals: goalsTypeArray, index: number) => (
                                <MemberCardComponent
                                  key={goals.name}
                                  {...goals}
                                  isSuperior={false}
                                  onClickEdit={() =>
                                    teamFormModal?.current?.setTeamUser(
                                      memberIndex,
                                      index,
                                      goals,
                                    )
                                  }
                                />
                              ))}
                          </PeopleOnMyTeamCardsContainer>
                        ) : (
                          <NothingHere>No Goals</NothingHere>
                        )}
                        <ChevronRowTitleContainer>
                          <ChevronRowTitle>Done</ChevronRowTitle>
                          <ChevronRowIconsContainer>
                            <SpanButton
                              onClick={() => {
                                handlePeopleOnMyTeamOpenDone(memberIndex);
                              }}
                            >
                              <Icon
                                icon={toggleChevronIcon(
                                  peopleOnMyTeamOpenArrayDone.includes(
                                    memberIndex,
                                  ),
                                )}
                                type={"outline"}
                              />
                            </SpanButton>
                          </ChevronRowIconsContainer>
                        </ChevronRowTitleContainer>
                        {peopleOnMyTeamOpenArrayDone.includes(memberIndex) && (
                          <>
                            {member.goals[currentQuarter as keyof typeof member.goals].filter(
                              (goal: goalsTypeArray) => goal.status === "Done",
                            ).length > 0 ? (
                              <PeopleOnMyTeamCardsContainer>
                                {member.goals[currentQuarter as keyof typeof member.goals]
                                  .filter(
                                    (goal: goalsTypeArray) =>
                                      goal.status === "Done",
                                  )
                                  .map((goal: goalsTypeArray) => (
                                    <MemberCardComponent
                                      key={goal.name}
                                      {...goal}
                                      isSuperior={false}
                                    />
                                  ))}
                              </PeopleOnMyTeamCardsContainer>
                            ) : (
                              <NothingHere>
                                No Goals with status{" "}
                                <NoDoneStatusTexy>Done</NoDoneStatusTexy>
                              </NothingHere>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </PeopleOnMyTeamContainer>
                ))}
              </ContainerCardsUpper>
            )}
          </ContainerCards>
        </Container>
      </PageContainer>
      <FormModal ref={formModal} currentQuarter={currentQuarter} />
      <TeamFormModal ref={teamFormModal} currentQuarter={currentQuarter} />
    </>
  );
}

export default Goals;

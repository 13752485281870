import ProfileCircle from "./nodeTypes/ProfileCircle";

export const nodeTypes = {
  ProfileCircle: ({ data }: any) => ProfileCircle({ data }),
};

interface NodeDimensions {
  [key: string]: { height: number; width: number };
}

const nodeDimensions: NodeDimensions = {
  ProfileCircle: { width: 100, height: 100 },
};

export const getNodeDimensions = (type: string | undefined) => {
  return nodeDimensions[type || "ProfileCircle"];
};

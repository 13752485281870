import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

const WarningContainer = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 0.25rem;
`;

const WarningContent = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-right: 1rem;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: #856404;
  font-size: 1.5rem;
`;

const TextContent = styled.div`
  flex: 1;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 1.1rem;
  color: #856404;
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

const Message = styled.p`
  margin: 0;
  color: #856404;
`;

const ResetButton = styled.button`
  padding: 0.375rem 0.75rem;
  background-color: #856404;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #6a5003;
  }
`;

// Interfaces
interface CSGMemberType {
  description: string;
}

interface WarningBoxProps {
  clientRole: string;
  manager?: {
    csg_member_type: CSGMemberType;
    full_name: string;
  } | null;
  selectedUser?: {
    csg_member_type: CSGMemberType;
    full_name: string;
  } | null;
  onReset: () => void;
}

export const WarningBox: React.FC<WarningBoxProps> = ({ 
  clientRole, 
  manager, 
  selectedUser, 
  onReset 
}) => {
  if (clientRole !== 'SUPER_USER') return null;

  const displayName = manager?.full_name || selectedUser?.full_name || '';
  const displayType = manager?.csg_member_type?.description || selectedUser?.csg_member_type?.description || '';

  return (
    <WarningContainer>
      <WarningContent>
        <IconWrapper>
          <StyledIcon icon={faWarning} aria-hidden="true" />
        </IconWrapper>
        <TextContent>
          <Title>Attention</Title>
          <MessageWrapper>
            <Message>
              The information comes from <strong>{displayType}</strong> - <strong>{displayName}</strong>
            </Message>
            <ResetButton onClick={onReset}>
              See other user
            </ResetButton>
          </MessageWrapper>
        </TextContent>
      </WarningContent>
    </WarningContainer>
  );
};

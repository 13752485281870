import { useState } from "react";
import {
  ChevronRowIconsContainer,
  ChevronRowTitle,
  ChevronRowTitleContainer,
  PageContainerSides,
  PageContainerLeft,
  PageContainerRight,
  SpanButton,
} from "@/common.styled.tsx";
import { CircleChart, Dropdown, Icon } from "@/components";
import { deepCompare, toggleChevronIcon } from "@/utils/shared-functions.js";
import CourseCard from "./CourseCard/CourseCard.tsx";
import { useDispatch } from "react-redux";
import {
  CardsContainer,
  ChartChart,
  ChartDiv,
  ChartNumber,
  ChartText,
  ChartTextDiv,
  TeamStatusDiv,
} from "./Courses.styled.tsx";
import {
  coursesAndEventsStateType,
  coursesAndEventsType,
  peopleOnMyTeamEnrolledType,
} from "@/models";
import { setCoursesAndEvents, useCoursesAndEventsSelector, useTeamsInfoSelector } from "@/store/states";

function Courses() {
  const dispatch = useDispatch();
  const coursesAndEvents = useCoursesAndEventsSelector();
  const teamsInfo = useTeamsInfoSelector();
  const [coursesOpen, setCoursesOpen] = useState(true);
  const [eventsOpen, setEventsOpen] = useState(true);
  const [activeCourse, setActiveCourse] = useState<coursesAndEventsType | null>(
    null
  );
  const [peopleOnMyTeamOpen, setPeopleOnMyTeamOpen] = useState(true);

  const replacePersonalStatus = (value: coursesAndEventsStateType) => {
    if (activeCourse) {
      const newActiveCourse: coursesAndEventsType = {
        ...activeCourse,
        personalStatus: value,
      };
      const newCoursesAndEvents = coursesAndEvents.map(
        (element: coursesAndEventsType) => {
          if (element.id === newActiveCourse.id) {
            return newActiveCourse;
          }
          return element;
        }
      );
      dispatch(setCoursesAndEvents(newCoursesAndEvents));
      setActiveCourse({ ...newActiveCourse });
    }
  };

  const replaceTeamStatus = (value: peopleOnMyTeamEnrolledType) => {
    if (activeCourse) {
      const newActiveCourse: coursesAndEventsType = {
        ...activeCourse,
        peopleOnMyTeamEnrolled: teamsInfo.map((element: any) => {
          if (element.name === value.name) {
            return { name: value.name, status: value.status };
          }
          return {
            name: element.name,
            status:
              activeCourse.peopleOnMyTeamEnrolled.find(
                (element2: any) => element2.name === element.name
              )?.status || "Not Enrolled",
          };
        }),
      };
      const newCoursesAndEvents = coursesAndEvents.map(
        (element: coursesAndEventsType) => {
          if (element.id === newActiveCourse.id) {
            return newActiveCourse;
          }
          return element;
        }
      );
      dispatch(setCoursesAndEvents(newCoursesAndEvents));
      setActiveCourse({ ...newActiveCourse });
    }
  };

  return (
    <PageContainerSides>
      <PageContainerLeft $isOpen={!!activeCourse}>
        <ChevronRowTitleContainer>
          <ChevronRowTitle>Courses</ChevronRowTitle>
          <ChevronRowIconsContainer>
            <SpanButton
              onClick={() => {
                setCoursesOpen(!coursesOpen);
              }}
            >
              <Icon icon={toggleChevronIcon(coursesOpen)} type={"outline"} />
            </SpanButton>
          </ChevronRowIconsContainer>
        </ChevronRowTitleContainer>
        {coursesOpen && (
          <CardsContainer>
            {coursesAndEvents
              ?.filter((course: any) => course.type === "Course")
              .map((course: any) => (
                <CourseCard
                  key={course.id}
                  {...course}
                  onClick={(card: coursesAndEventsType) =>
                    setActiveCourse(
                      deepCompare(card, activeCourse) ? null : card
                    )
                  }
                />
              ))}
          </CardsContainer>
        )}
        <ChevronRowTitleContainer>
          <ChevronRowTitle>Events</ChevronRowTitle>
          <ChevronRowIconsContainer>
            <SpanButton
              onClick={() => {
                setEventsOpen(!eventsOpen);
              }}
            >
              <Icon icon={toggleChevronIcon(eventsOpen)} type={"outline"} />
            </SpanButton>
          </ChevronRowIconsContainer>
        </ChevronRowTitleContainer>
        {eventsOpen && (
          <CardsContainer>
            {coursesAndEvents
              ?.filter((course: any) => course.type === "Event")
              .map((course: any) => (
                <CourseCard
                  key={course.id}
                  {...course}
                  onClick={(card: coursesAndEventsType) =>
                    setActiveCourse(
                      deepCompare(card, activeCourse) ? null : card
                    )
                  }
                />
              ))}
          </CardsContainer>
        )}
      </PageContainerLeft>
      <PageContainerRight $isOpen={!!activeCourse}>
        {activeCourse && (
          <>
            <CourseCard {...activeCourse} />
            <div>Current Status:</div>
            <Dropdown
              displayValue={"value"}
              options={[
                { value: "Not Started", label: "Not Started" },
                { value: "In Progress", label: "In Progress" },
                { value: "Done", label: "Done" },
                { value: "Not Enrolled", label: "Not Enrolled" },
              ]}
              value={activeCourse.personalStatus}
              onChange={(value) => replacePersonalStatus(value)}
            />
            <ChevronRowTitleContainer>
              <ChevronRowTitle>My Team Status:</ChevronRowTitle>
              <ChevronRowIconsContainer>
                <SpanButton
                  onClick={() => {
                    setPeopleOnMyTeamOpen(!peopleOnMyTeamOpen);
                  }}
                >
                  <Icon
                    icon={toggleChevronIcon(peopleOnMyTeamOpen)}
                    type={"outline"}
                  />
                </SpanButton>
              </ChevronRowIconsContainer>
            </ChevronRowTitleContainer>
            {peopleOnMyTeamOpen && (
              <ChartDiv>
                <ChartTextDiv>
                  <ChartText>Team Progress</ChartText>
                  <ChartNumber>
                    {Math.round(
                      (activeCourse.peopleOnMyTeamEnrolled.filter(
                        (element: any) => element.status === "Done"
                      ).length /
                        activeCourse.peopleOnMyTeamEnrolled.length) *
                        100
                    ) || 0}
                    %
                  </ChartNumber>
                </ChartTextDiv>
                <ChartChart>
                  <CircleChart
                    percentage={
                      Math.round(
                        (activeCourse.peopleOnMyTeamEnrolled.filter(
                          (element: any) => element.status === "Done"
                        ).length /
                          activeCourse.peopleOnMyTeamEnrolled.length) *
                          100
                      ) || 0
                    }
                    color="#005A24"
                  />
                </ChartChart>
              </ChartDiv>
            )}
            {peopleOnMyTeamOpen && (
              <>
                {teamsInfo.map((member: any) => {
                  return (
                    <TeamStatusDiv key={member.name}>
                      <div>{member.name}</div>
                      <Dropdown
                        displayValue={"value"}
                        options={[
                          { value: "Not Started", label: "Not Started" },
                          { value: "In Progress", label: "In Progress" },
                          { value: "Done", label: "Done" },
                          { value: "Not Enrolled", label: "Not Enrolled" },
                        ]}
                        value={
                          activeCourse.peopleOnMyTeamEnrolled.find(
                            (element: any) => element.name === member.name
                          )?.status || "Not Enrolled"
                        }
                        onChange={(value) =>
                          replaceTeamStatus({
                            name: member.name,
                            status: value,
                          })
                        }
                      />
                    </TeamStatusDiv>
                  );
                })}
              </>
            )}
          </>
        )}
      </PageContainerRight>
    </PageContainerSides>
  );
}

export default Courses;

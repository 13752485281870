import { IconStatusType } from "@/components/Icon/IconType";
import styled from "styled-components";

interface MembersCardInterface {
  $isSuperior: boolean;
}

export const MembersCard = styled.div<MembersCardInterface>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: ${(props) => (props.$isSuperior ? "#E5F2E9" : "#FFFFFF")};
`;

export const MemberCardTopDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

interface MembersCardIconInterface {
  $statusColor: IconStatusType;
}

export const MembersCardIcon = styled.div<MembersCardIconInterface>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  color: #ffffff;
  background-color: ${(props) => {
    switch (props.$statusColor) {
      case "In Progress":
        return "#e0bd00";
      case "Not Started":
        return "#117170";
      case "Done":
        return "#005A24";
      case "Blocked":
        return "#ce0000";
      case "Disabled":
        return "#8f8f8f";
      default:
        return "#FFFFFF";
    }
  }};
  & svg {
    width: 20px;
    height: 20px;
  }
`;

export const MembersCardInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const MemberCardTitle = styled.div<MembersCardIconInterface>`
  font-size: 16px;
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  background-color: ${(props) => {
    switch (props.$statusColor) {
      case "In Progress":
        return "#e0bd00";
      case "Not Started":
        return "#117170";
      case "Done":
        return "#005A24";
      case "Blocked":
        return "#ce0000";
      case "Disabled":
        return "#8f8f8f";
      default:
        return "#FFFFFF";
    }
  }};
`;

export const MemberCardDescription = styled.div`
  font-size: 14px;
  margin: 16px;
  color: #313131;
  padding-top: 16px;
`;

export const MemberCardStatus = styled.div`
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-self: flex-end;
  gap: 10px;
  & svg {
    width: 20px;
    height: 20px;
  }
`;

export const MemberCardActionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: #117170;
  & svg {
    width: 20px;
    height: 20px;
  }
`;

export const AssociationCardsLabel = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #333;
  padding: 16px;
`;

export const AssociationCardsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  padding: 10px;
  flex-wrap: wrap;
`;

export const MemberTitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

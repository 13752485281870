import { userManagementInfoType } from "@/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const EmptyState: userManagementInfoType = {
  name: "",
  color: "",
  goals: {
    Q1: [],
    Q2: [],
    Q3: [],
    Q4: [],
  },
  v2Moms: {
    Q1: [],
    Q2: [],
    Q3: [],
    Q4: [],
  },
  timeoff: [],
};

export const userManagementInfoSlice = createSlice({
  name: "userManagementInfo",
  initialState: EmptyState,
  reducers: {
    setUserManagementInfo: (state, action: PayloadAction<userManagementInfoType>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearUserManagementInfo: () => EmptyState,
  },
});

export const { setUserManagementInfo, clearUserManagementInfo } = userManagementInfoSlice.actions;
import { statusListType } from "@/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const EmptyState: statusListType[] = [];

export const statusListSlice = createSlice({
  name: "statusList",
  initialState: EmptyState,
  reducers: {
    setStatusList: (state, action: PayloadAction<statusListType[]>) => {
      return [...action.payload];
    },
    clearStatusList: () => EmptyState,
  },
});

export const { setStatusList, clearStatusList } = statusListSlice.actions;

import styled from "styled-components";

export const AssociationCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: fit-content;
  padding: 10px;
  border-radius: 8px;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
`;

export const TypeIcon = styled.div`
  & svg {
    height: 20px;
    width: 20px;
  }
`;

export const CloseIcon = styled.div`
  & svg {
    height: 20px;
    width: 20px;
  }
  &:hover {
    cursor: pointer;
    color: #ff0000;
  }
`;

export const AssociationCardTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #333;
`;

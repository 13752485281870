import { forwardRef, useImperativeHandle } from "react";
import { TimeScheduleType } from "./TimeSchedule.model.ts";
import {
  Container,
  WeekNumber,
  WeekDay,
  StageName,
  StageDay,
  Row,
} from "./TimeSchedule.styled.tsx";

const TimeSchedule = forwardRef(
  ({ quarter, year, stages }: TimeScheduleType, ref) => {
    useImperativeHandle(ref, () => ({}));
    const getQuarterDates = (quarter: string, year: number) => {
      const startMonth = ["Q1", "Q2", "Q3", "Q4"].indexOf(quarter) * 3;
      const startDate = new Date(year, startMonth, 1);
      const endDate = new Date(year, startMonth + 3, 0);
      return { startDate, endDate };
    };

    const getWeeksInQuarter = (startDate: Date, endDate: Date) => {
      const weeks = [];
      const current = new Date(startDate);

      while (current <= endDate) {
        const startOfWeek = new Date(current);
        const endOfWeek = new Date(current);
        endOfWeek.setDate(endOfWeek.getDate() + 6);

        weeks.push({ startOfWeek, endOfWeek });
        current.setDate(current.getDate() + 7);
      }
      return weeks;
    };

    const { startDate, endDate } = getQuarterDates(quarter, year);
    const weeks = getWeeksInQuarter(startDate, endDate);

    // Calculate the number of days in the quarter
    const numberOfDays =
      Math.ceil(
        (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24),
      ) + 1;

    const daysArray = Array.from({ length: numberOfDays }).map(
      (_, dayIndex) => {
        const dayDate = new Date(startDate.getTime() + dayIndex * 86400000);
        return {
          date: dayDate,
          day: dayDate.getDate(),
          dayOfWeek: dayDate.toLocaleDateString("en-US", { weekday: "short" }),
        };
      },
    );

    return (
      <Container days={numberOfDays}>
        {/* Week Numbers Row */}
        <Row>
          <div></div>
          {weeks.map((week, index) => (
            <WeekNumber key={index}>Week {index + 1}</WeekNumber>
          ))}
        </Row>
        {/* Days Row */}
        <Row>
          <div></div>
          {daysArray.map((day, index) => (
            <WeekDay key={index}>
              {day.dayOfWeek} {day.day}
            </WeekDay>
          ))}
        </Row>
        {/* Stages Rows */}
        {stages.map((stage, stageIndex) => (
          <Row key={stageIndex}>
            <StageName>{stage.name}</StageName>
            {daysArray.map((day, dayIndex) => {
              const isActive =
                day.date >= stage.startDate && day.date <= stage.endDate;
              return <StageDay key={dayIndex} isActive={isActive}></StageDay>;
            })}
          </Row>
        ))}
      </Container>
    );
  },
);

TimeSchedule.displayName = "TimeSchedule";

export default TimeSchedule;

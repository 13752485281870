import styled from "styled-components";

interface ContainerInterface {
  days: number;
}

export const Container = styled.div<ContainerInterface>`
  display: grid;
  grid-template-columns: auto repeat(${(props) => props.days}, 1fr);
  gap: 1px;
  overflow-x: auto;
  white-space: nowrap;
`;

export const Row = styled.div`
  display: contents;
`;

export const WeekNumber = styled.div`
  text-align: center;
  font-weight: bold;
  padding: 8px;
  grid-column: span 7;
`;

export const WeekDay = styled.div`
  text-align: center;
  padding: 4px;
  width: 100px;
  color: var(--primary-color);
  background-color: #e0e0e0;
`;

export const StageName = styled.div`
  padding: 8px;
  width: 200px;
  background-color: #e0e0e0;
`;

export const StageDay = styled.div<{ isActive: boolean }>`
  padding: 4px;
  background-color: ${(props) => (props.isActive ? "#a0e0a0" : "#ffffff")};
  width: 100px;
`;

import { createSelector } from '@reduxjs/toolkit';
import { RootState, useAppSelector } from '@/store';

const superiorInfoSelector = (state: RootState) => state.superiorInfo;

const memoizedSelector = createSelector(superiorInfoSelector, (state) => {
  return state;
});

export const useSuperiorInfoSelector = () => useAppSelector(memoizedSelector);

import { useDispatch } from "react-redux";
import {
  LayoutContainer,
  PageContainer,
  RightContainer,
} from "@/common.styled.tsx";
import { Button, Dropdown, PDInput, TreeNode } from "@/components";
import {
  ButtonsContainer,
  FormContainer,
  InitialsContainer,
  NoSelectedNodeContainer,
  TreeContainer,
} from "./Teams.styled.tsx";
import { useCallback, useEffect, useState } from "react";
import { capitalizeName } from "@/utils/shared-functions.js";
import { NodeType, TeamArrayType } from "@/components/TreeNode/TreeNodeType.ts";
import { setTeamsTree, useTeamsTreeSelector } from "@/store/states";

function Teams() {
  const dispatch = useDispatch();
  const teamsTree = useTeamsTreeSelector();
  const [selectedNode, setSelectedNode] = useState<any>(null);
  const [currentName, setCurrentName] = useState<string>("");
  const [currentTeam, setCurrentTeam] = useState<{
    team: string;
    teamColor: string;
  }>({ team: "", teamColor: "" });

  const extractTeams = useCallback(
    (nodes: NodeType[], uniqueTeams: Set<string>): TeamArrayType[] => {
      let teams: TeamArrayType[] = [];

      nodes.forEach((node) => {
        if (!uniqueTeams.has(node.team)) {
          uniqueTeams.add(node.team);
          teams.push({ team: node.team, teamColor: node.teamColor });
        }
        if (node.teamMembers) {
          teams = teams.concat(extractTeams(node.teamMembers, uniqueTeams));
        }
      });

      return teams;
    },
    [],
  );

  const [teamsArray, setTeamsArray] = useState<Array<TeamArrayType>>(() =>
    extractTeams(teamsTree, new Set<string>()),
  );

  useEffect(() => {
    setTeamsArray(extractTeams(teamsTree, new Set<string>()));
  }, [teamsTree, extractTeams]);

  useEffect(() => {
    setCurrentName(selectedNode?.name);
    setCurrentTeam({
      team: selectedNode?.team,
      teamColor: selectedNode?.teamColor,
    });
  }, [selectedNode]);

  const onClickNode = (node: any) => {
    setSelectedNode(node);
  };

  const updateNodeInTree = (
    nodes: NodeType[],
    selectedNode: NodeType,
    currentName: string,
    currentTeam: TeamArrayType,
  ): NodeType[] => {
    return nodes.map((node) => {
      if (node.name === selectedNode.name) {
        return {
          ...node,
          name: currentName,
          teamColor: currentTeam.teamColor,
          team: currentTeam.team,
        };
      }
      if (node.teamMembers) {
        return {
          ...node,
          teamMembers: updateNodeInTree(
            node.teamMembers,
            selectedNode,
            currentName,
            currentTeam,
          ),
        };
      }
      return node;
    });
  };

  const onAccept = () => {
    const newNodes = updateNodeInTree(
      teamsTree,
      selectedNode,
      currentName,
      currentTeam,
    );
    setSelectedNode(null);
    dispatch(setTeamsTree(newNodes));
  };

  const onCancel = () => {
    setSelectedNode(null);
  };

  return (
    <PageContainer>
      <LayoutContainer>
        <TreeContainer>
          <TreeNode
            nodes={teamsTree}
            onClick={(node: any) => onClickNode(node)}
          />
        </TreeContainer>
        <RightContainer>
          {selectedNode ? (
            <FormContainer>
              <InitialsContainer title={currentName} color={selectedNode.color}>
                {capitalizeName(currentName)}
              </InitialsContainer>
              <PDInput value={currentName} onChange={setCurrentName} />
              <Dropdown
                value={currentTeam.team}
                displayValue={"team"}
                options={teamsArray}
                onChange={(_, value) =>
                  setCurrentTeam({
                    team: value.team,
                    teamColor: value.teamColor,
                  })
                }
              />
              <ButtonsContainer>
                <Button text="Accept" onClick={() => onAccept()} />
                <Button
                  text="Cancel"
                  type="secondary"
                  onClick={() => onCancel()}
                />
              </ButtonsContainer>
            </FormContainer>
          ) : (
            <NoSelectedNodeContainer>
              There is no selected node, plase select one to see the details.
            </NoSelectedNodeContainer>
          )}
        </RightContainer>
      </LayoutContainer>
    </PageContainer>
  );
}

export default Teams;

import { useState, useEffect } from 'react';
import MultiSelect from "@/components/MultiSelect";
import styled from 'styled-components';
import {useTeamManager} from '@/query';

interface Role {
  type_key: string;
  description: string;
}

interface User {
  email: string;
  full_name: string;
  value: string;
}

interface SuperAdminViewProps {
  roles: Role[];
  onRoleSelect: (role: Role) => void;
  onUserSelect: (user: User) => void;
  users: User[];
  isLoading?: boolean;
  selectedRole?: Role;
}

const Container = styled.div`
  background-color: white;
  padding: 6rem 1.5rem;
  height: calc(90vh - 60px);
`;

const Wrapper = styled.div`
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
`;

const Title = styled.h2`
  font-size: 2.25rem;
  font-weight: bold;
  letter-spacing: -0.025em;
  color: #111827;
`;

const Description = styled.p`
  margin-top: 1.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #4B5563;
`;

const Label = styled.label`
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: #111827;
`;

const Select = styled.select`
  margin-top: 0.5rem;
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  border: none;
  padding: 0.375rem 0.75rem;
  color: #111827;
  height: 44px;
  box-shadow: 0 0 0 1px #D1D5DB inset;
  font-size: 0.875rem;
  line-height: 1.5rem;

  &:disabled {
    background-color: #F3F4F6;
    cursor: not-allowed;
  }
`;

const FormGroup = styled.div`
  margin-top: 1rem;
`;
const MultiSelectWrapper = styled.div`
  position: relative;
`;
const LoadingOverlay = styled.div`
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0.375rem;
  min-height: 44px;
`;

export const SuperAdminView: React.FC<SuperAdminViewProps> = ({
  roles,
  users,
  isLoading,
  onRoleSelect,
  onUserSelect,
  selectedRole
}) => {
  const [selectKey, setSelectKey] = useState(0);
  const [isRoleChange, setIsRoleChange] = useState(false);
  const [visibleOptions, setVisibleOptions] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const { manager, isLoading: isLoadingManager } = useTeamManager(selectedUser, selectedRole);

  useEffect(() => {
    if (!isLoading) {
      setVisibleOptions(users || []);
    }
  }, [users, isLoading]);

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTypeKey = e.target.value;
    const role = roles.find(role => role.type_key === selectedTypeKey);
    if (role) {
      setVisibleOptions([]);
      setIsRoleChange(true);
      setSelectedUser(null);
      onRoleSelect(role);
      setSelectKey(prev => prev + 1);
    }
  };

  const handleUserSelect = (selectedValues: User[]) => {
    if (!isRoleChange && selectedValues?.length > 0) {
      const user = selectedValues[0];
      setSelectedUser(user);
      
      if (selectedRole?.type_key === "DELIVERY_MANAGER_TEAM_MANAGER") {
        if (manager) {
          const combinedData = [...manager, user];
          onUserSelect(combinedData);
        } else {
          onUserSelect(user);
        }
      } else {
        onUserSelect(user);
      }
    }
  };

  useEffect(() => {
    if (selectedRole?.type_key === "DELIVERY_MANAGER_TEAM_MANAGER" && manager && selectedUser) {
      const combinedData = [...manager, selectedUser];
      onUserSelect(combinedData);
    }
  }, [manager, selectedRole?.type_key, selectedUser]);

  useEffect(() => {
    if (isRoleChange) {
      setIsRoleChange(false);
    }
  }, [isRoleChange]);

  return (
    <Container>
      <Wrapper>
        <Title>Welcome to client delivery dashboard</Title>
        <Description>
          Please select from the dropdown below which role and then search for a user 
          you would like to view the dashboard for.
        </Description>
        
        <FormGroup>
          <Label htmlFor="role">Role</Label>
          <Select
            id="role"
            name="role"
            onChange={handleRoleChange}
            value={selectedRole?.type_key || ""}
          >
            <option value="">Select a role</option>
            {roles.map((role) => (
              <option key={role.type_key} value={role.type_key}>
                {role.description}
              </option>
            ))}
          </Select>

          {selectedRole && (
            <FormGroup>
              <Label htmlFor="user">User</Label>
              <div style={{ position: 'relative' }}>
                {isLoading && (
                  <div 
                    style={{ 
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(255, 255, 255, 0.7)',
                      borderRadius: '0.375rem',
                      zIndex: 1
                    }}
                  >
                    <div className="animate-pulse h-full bg-gray-200 rounded" />
                  </div>
                )}
                <MultiSelect
                  key={selectKey}
                  options={visibleOptions}
                  onChange={handleUserSelect}
                  valueToDisplay="value"
                  allowOnlyOne={true}
                  defaultValues={null}
                  placeholder="User name search..."
                  isDisabled={isLoading}
                />
              </div>
            </FormGroup>
          )}
        </FormGroup>
      </Wrapper>
    </Container>
  );
};
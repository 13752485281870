import React from 'react';
import { forwardRef, useImperativeHandle } from 'react';
import { CircleChartType } from './CircleChart.model.ts';
import { ChartContainer } from './CircleChart.styled.tsx';
import Chart from 'react-apexcharts';

const CircleChart = forwardRef<ApexCharts.ApexOptions, CircleChartType>(
  (
    {
      color = '#117170',
      trackColor = '#E9E9E9',
      label,
      percentage,
      height = 162,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({}));

    const options: ApexCharts.ApexOptions = {
      plotOptions: {
        radialBar: {
          track: {
            background: trackColor,
          },
          dataLabels: {
            name: {
              show: !!label,
              fontSize: '10px',
              offsetY: 3,
            },
            value: {
              show: !label,
              offsetY: 5,
              color,
            },
            total: {
              show: false,
            },
          },
        },
      },
      stroke: {
        lineCap: 'round',
      },
      labels: [label || ''],
      colors: [color],
    };

    return (
      <ChartContainer>
        <Chart
          options={options}
          series={[percentage]}
          type="radialBar"
          height={height}
        />
      </ChartContainer>
    );
  }
);

CircleChart.displayName = 'CircleChart';

export default CircleChart;

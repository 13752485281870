import { faBoxOpen, faStar, faGraduationCap, faCode, faSliders } from '@fortawesome/free-solid-svg-icons';

export const support = {
  title: 'Premium Support',
  description: 'Top-tier assistance from our Support team, for emergencies or unknown issues.',
  icon: faStar,
  type: 'support',
};

export const service = {
  title: 'Service Request',
  description: 'Any request within your contracted hourly capacity.',
  icon: faBoxOpen,
  type: 'service',
};

export const training = {
  title: 'Training Request',
  description: 'Any need related PagerDuty university, courses, training.',
  icon: faGraduationCap,
  type: 'training',
};

export const feature = {
  title: 'Feature',
  description: 'Any new application, function, or fixes.',
  icon: faCode,
  type: 'feature',
};

export const other = {
  title: 'Other',
  description: 'Top-tier assistance from our Support team 24×7.',
  icon: faSliders,
  type: 'other',
};


export const SERVICES = [
  support,
  service,
  training,
  feature,
  other,
];
import { superiorInfoType } from "@/models";

export const superiorInfoMock = <superiorInfoType>{
  name: "Brandon Colina",
  color: "#e09275",
  v2Moms: {
    Q1: [
      {
        name: "Finish portal migration",
        status: "In Progress",
        description: "Migrate the portal to the new infrastructure",
      },
      {
        name: "Onboard new team member",
        status: "Not Started",
        description: "Onboard the new team member",
      },
      {
        name: "Create new team structure",
        status: "Done",
        description: "Create the new team structure",
      },
    ],
    Q2: [],
    Q3: [],
    Q4: [],
  },
};

import React from 'react';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { DragDropListType } from './DragDropList.model';
import { Container } from 'rsuite';
import { ListItemGrabbing, ListItem } from './DragDropList.styled';

const DragDropList = forwardRef(
  ({ items, onChange, renderItem }: DragDropListType, ref) => {
    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
    const [overIndex, setOverIndex] = useState<number | null>(null);

    useImperativeHandle(ref, () => ({}));

    const handleDragStart = (
      e: React.DragEvent<HTMLDivElement>,
      index: number
    ) => {
      setDraggedIndex(index);
    };

    const handleDragOver = (
      e: React.DragEvent<HTMLDivElement>,
      index: number
    ) => {
      e.preventDefault();
      setOverIndex(index);
    };

    const handleDragLeave = () => {
      setOverIndex(null);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>, index: number) => {
      e.preventDefault();
      if (draggedIndex !== null && draggedIndex !== index) {
        const updatedItems = [...items];
        const [movedItem] = updatedItems.splice(draggedIndex, 1);
        updatedItems.splice(index, 0, movedItem);

        onChange(updatedItems);
        setDraggedIndex(null);
        setOverIndex(null);
      }
    };

    return (
      <Container>
        {items.map((item, index) => (
          <ListItemGrabbing
            key={item.id}
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={(e) => handleDragOver(e, index)}
            onDrop={(e) => handleDrop(e, index)}
            onDragLeave={handleDragLeave}
            className={overIndex === index ? 'over' : ''}
          >
            {renderItem ? renderItem(item) : <ListItem>{item.name}</ListItem>}
          </ListItemGrabbing>
        ))}
      </Container>
    );
  }
);

DragDropList.displayName = 'DragDropList';

export default DragDropList;

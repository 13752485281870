import { SelectStatusType } from "./SelectStatusType.ts";
import { Container, FilterButton } from "./SelectStatus.styled.tsx";
import Icon from "../Icon/index.ts";

const SelectStatus = ({
  currentStatusFilters,
  updateCurrentStatusFilters,
}: SelectStatusType) => {
  return (
    <Container>
      <FilterButton
        title={
          currentStatusFilters.includes("Not Started")
            ? "Hide Not Started"
            : "Show Not Started"
        }
        $statusColor={
          currentStatusFilters.includes("Not Started")
            ? "Not Started"
            : "Disabled"
        }
        onClick={() => {
          updateCurrentStatusFilters("Not Started");
        }}
      >
        <Icon icon={"BellAlertIcon"} type={"outline"} />
      </FilterButton>
      <FilterButton
        title={
          currentStatusFilters.includes("In Progress")
            ? "Hide In Progress"
            : "Show In Progress"
        }
        $statusColor={
          currentStatusFilters.includes("In Progress")
            ? "In Progress"
            : "Disabled"
        }
        onClick={() => {
          updateCurrentStatusFilters("In Progress");
        }}
      >
        <Icon icon={"RocketLaunchIcon"} type={"outline"} />
      </FilterButton>
      <FilterButton
        title={
          currentStatusFilters.includes("Blocked")
            ? "Hide Blocked"
            : "Show Blocked"
        }
        $statusColor={
          currentStatusFilters.includes("Blocked") ? "Blocked" : "Disabled"
        }
        onClick={() => {
          updateCurrentStatusFilters("Blocked");
        }}
      >
        <Icon icon={"NoSymbolIcon"} type={"outline"} />
      </FilterButton>
    </Container>
  );
};

SelectStatus.displayName = "SelectStatus";

export default SelectStatus;

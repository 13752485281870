import styled from "styled-components";

export const Container = styled.div`
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  background: #f9f9f9;
`;

export const ListItem = styled.div`
  padding: 8px;
  margin-bottom: 4px;
  background: #fff;
  border-radius: 4px;
`;

export const ListItemGrabbing = styled(ListItem)`
  cursor: grab;

  &.over {
    border: 2px dashed #007bff;
  }
`;

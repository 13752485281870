import styled from "styled-components";

interface StyledButtonInterface {
  $color?: string;
  $expand?: string;
  $type?: "primary" | "secondary" | "tertiary";
}

export const StyledButton = styled.button<StyledButtonInterface>`
  background: ${(props) =>
    props.$type === "primary"
      ? props.$color
        ? props.$color
        : "var(--primary-color)"
      : "transparent"};
  border-radius: 8px;
  opacity: 1;
  font-size: 14px;
  border: ${(props) =>
    props.$type === "primary" || props.$type === "tertiary"
      ? "none"
      : props.disabled
        ? "1px solid #E0E1E2"
        : props.$color
          ? "1px solid " + props.$color
          : "1px solid #e0e0e0"};
  padding: 8px 16px;
  color: ${(props) =>
    props.$type === "primary" ? "#fff" : props.$color || "#535353"};
  text-align: center;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  & svg {
    color: ${(props) =>
      props.$type === "primary" ? "#fff" : props.$color || "#535353"};
    width: 24px;
    height: 24px;
  }

  &:disabled {
    background: ${(props) =>
      props.$type === "primary" ? "#E0E1E2" : "transparent"};
    box-shadow: none;
    color: #b0b0b0;
    opacity: 0.38;
    cursor: default;
  }

  @media only screen and (max-width: 768px) {
    width: ${(props) => (props.$expand === "true" ? "100%" : "fit-content")};
    margin-left: ${(props) => (props.$expand === "true" ? "0px" : "10px")};
  }
`;

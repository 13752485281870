import apiRoutes from '@/services/api-routes';
import { useApiMutation } from "@/store/fetchClientInstance";
import { validateMFAMutation } from './validateQueryTypes.model';

export const useValidateMFAOauth = () => {
  const [validateMFAOauth, { data, isLoading, error, reset, status }] = useApiMutation();
  const handleValidateMFAOauth = (code: validateMFAMutation) => {
    validateMFAOauth({
      url: apiRoutes.loginOauth,
      config: {
        method: 'POST',
        body: code
      }
    });
  };

  return { validateMFAOauth: handleValidateMFAOauth, data, isLoading, error, reset, status };
};

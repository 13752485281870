import styled from "styled-components";

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 20px;
`;

export const TeamStatusDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const ChartDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  width: 100%;
`;

export const ChartTextDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: calc(100% - 200px);
`;

export const ChartText = styled.div`
  font-size: 20px;
`;

export const ChartNumber = styled.div`
  font-size: 30px;
  color: #005a24;
`;

export const ChartChart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 200px;
  margin-top: 18px;
`;

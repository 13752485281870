import { useCallback } from "react";
import { apiRoutes, apiRequest } from "../../services";
import { API_BASE_URL } from "../../services/api-instance";


const DownloadBase64 = () => {
  const downloadCSV = useCallback(
    async (api, reportName, reportType, payload) => {
      let data = null;
      let error = null;
      let isBase64Encoded = false;
      let jsonData = null;
      let textData = null;
      let response = null;
      try {
        response = await fetch(`${API_BASE_URL}${api}`, {
          method: "get",
          headers: { "Authorization": `CsgPDBearer ${ localStorage.getItem("token") }` },
          credentials: 'include'
        });
        textData = await response.text()
      } catch (e) {
        console.log("error", error);
        return [e, data]
      }

      try {
        jsonData = JSON.parse(textData)
        data = jsonData?.pdf_base64
        isBase64Encoded = data !== null
      } catch(e) {
        data = textData
      }

      if (!data) {
        return [
          Error(`malformed data received when trying to download CSV from GET: ${api}`),
          data,
        ]
      }

      let blob = null;
      const csvContentType = "text/csv;charset=utf-8;"
      if (!isBase64Encoded) {
        blob = new Blob([data], { type: csvContentType});
      } else {
        const bytes = Uint8Array.from(atob(data), (c) => c.charCodeAt(0));
        blob = new Blob([bytes], { type: csvContentType });
      }
      const url = window.URL.createObjectURL(blob);
      let download = document.createElement("a");
      download.textContent = "download";
      download.download =
        reportName + '-' + new Date().toISOString() + "." + reportType;
      download.href = url
      download.click();
      download.remove()

      return [error, data];
    },
    []
  );

  return {
    downloadCSV,
  };
};

export default DownloadBase64;

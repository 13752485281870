import { forwardRef, useImperativeHandle, useState } from "react";
import { Actions, ActionsPopUpType } from "./ActionsPopUp.model.ts";
import {
  ActionButton,
  ActionsPopUpContainer,
  ButtonContainer,
  Container,
  IconContainer,
  Label,
} from "./ActionsPopUp.styled.tsx";
import { v4 as uuidv4 } from "uuid";
import Icon from "../Icon/index.ts";

const ActionsPopUp = forwardRef(
  ({ actions, onClick, label, icon }: ActionsPopUpType, ref) => {
    const [actionsPopUpOpen, setActionsPopUpOpen] = useState(false);
    useImperativeHandle(ref, () => ({}));

    const onClickFunction = (action: string) => {
      setActionsPopUpOpen(false);
      onClick(action);
    };

    return (
      <Container>
        <ButtonContainer onClick={() => setActionsPopUpOpen(!actionsPopUpOpen)}>
          {label && <Label>{label}</Label>}
          {icon && <Icon icon={icon.icon} type={icon.type} />}
        </ButtonContainer>
        {actionsPopUpOpen && (
          <ActionsPopUpContainer>
            {actions.map((action: Actions) => (
              <ActionButton
                key={uuidv4()}
                onClick={() =>
                  !action.disabled && onClickFunction(action.label)
                }
                $disabled={action.disabled}
                disabled={action.disabled}
              >
                <IconContainer>
                  <Icon icon={action.icon.icon} type={action.icon.type} />{" "}
                </IconContainer>
                {action.label}
              </ActionButton>
            ))}
          </ActionsPopUpContainer>
        )}
      </Container>
    );
  },
);

ActionsPopUp.displayName = "ActionsPopUp";

export default ActionsPopUp;

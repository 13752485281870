import { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { FormInput } from "@/common.styled";
import { PDInputType } from "./PDInput.model";

const PDInput = forwardRef(
  (
    {
      value,
      floatDecimals,
      disabled,
      readOnly,
      classNames,
      type = "text",
      min,
      max,
      step,
      placeholder,
      name,
      handleFormChange,
      onChange,
      validationLabel,
    }: PDInputType,
    ref,
  ) => {
    useImperativeHandle(ref, () => ({}));
    const [valueInner, setValue] = useState<string>(value || "");

    const changeValue = (e: any) => {
      const value = e.currentTarget.value;
      setValue(value);
      handleFormChange && handleFormChange({ name }, value);
      onChange && onChange(value);
    };

    const blur = (e: any) => {
      let value: any = e.currentTarget.value;
      if (step) {
        value = floatDecimals
          ? parseFloat(value).toFixed(floatDecimals)
          : parseInt(value);
        value = isNaN(value)
          ? value
          : min && min > value
            ? min
            : max && max < value
              ? max
              : value;
        value =
          (floatDecimals
            ? parseFloat(value).toFixed(floatDecimals)
            : parseInt(value)) + "";
      }
      setValue(value);
      handleFormChange && handleFormChange({ name }, value);
      onChange && onChange(value);
    };

    useEffect(() => {
      if (value !== valueInner) {
        setValue(value || "");
      }
    }, [value, valueInner]);

    return (
      <>
        <FormInput
          className={classNames}
          disabled={disabled}
          readOnly={readOnly}
          value={valueInner}
          data-testid={name}
          key={name}
          type={type}
          min={min}
          max={max}
          step={step}
          onChange={changeValue}
          onBlur={blur}
          placeholder={placeholder}
        />
        {validationLabel && (
          <span style={{ color: "#ff253a", fontSize: "12px" }}>
            {validationLabel}
          </span>
        )}
      </>
    );
  },
);

PDInput.displayName = "PDInput";

export default PDInput;

import CloudCheckMark from "../../../assets/CloudCheckMark.svg";
import ModalHome from "../../../components/ModalHome/ModalHome.js";

export default function ModalFreeTrial({openFreeTrialModal, setOpenFreeTrialModal}) {
  return(
    <ModalHome
    show={openFreeTrialModal}
    handleClose={() =>
      setOpenFreeTrialModal(!openFreeTrialModal)
    }
    title={"New Request"}
    modalFitContent
  >
    <div>
      <div className="popupModalHomeSmall">
        <div
          className="round__icon__modal"
          style={{ backgroundColor: "#E2F0E7" }}
        >
          <img src={CloudCheckMark} alt="" role="display" />
        </div>
        <div className="popupModalHomeSmallText">
          <span className="popupModalHomeSecondSmallTextTitle">
          Thank you for your interest, your Customer Success Manager will follow up in the next{" "}
            <span className="popupModalHomeSecondBoldSmallTextTitle">
            72 business hours!
            </span>{" "}
          </span>
        </div>
      </div>
      <button
        onClick={() => {
          setOpenFreeTrialModal(false);
        }}
        className="popupModalHomeSmallButton"
      >
        Close
      </button>
    </div>
  </ModalHome>
  )
}
import styled from "styled-components";

export const TopContainer = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

export const BodyContainer = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 100%;
`;

export const SecondaryContainerTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

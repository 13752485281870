import styled from "styled-components";

export const ContainerFilters = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 10px 0px;
`;

type InitialsContainerInterface = {
  color: string;
};

export const InitialsContainer = styled.div<InitialsContainerInterface>`
  width: 43px;
  height: 43px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  border: 2px solid #e5e7eb;
  box-shadow: 0 0 0 2px #8f8f8f;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
`;

export const FilterTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  width: 100%;
`;

export const DragDropContainerDiv = styled.div`
  margin-top: 20px;
`;

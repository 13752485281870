import { Icon } from "@/components";
import { CircleNumber } from "@/common.styled";
import {
  CardContainer,
  CardContainerDescription,
  CardContainerTitle,
  CardText,
  CardTextDiv,
  DueDateText,
  RequariedIcon,
  TopDIV,
  TypeIcon,
} from "./CourseCard.styled";
import { coursesAndEventsComponentType } from "@/models";

function CourseCard({
  id,
  name,
  totalEnroll,
  type,
  description,
  requaried,
  personalStatus,
  peopleOnMyTeamEnrolled,
  dueDate,
  onClick,
}: coursesAndEventsComponentType) {
  const getDueDate = (dueDate: Date) => {
    return new Date(dueDate).toLocaleDateString();
  };
  return (
    <CardContainer
      $statusColor={personalStatus}
      onClick={() => {
        onClick &&
          onClick({
            id,
            name,
            totalEnroll,
            type,
            description,
            requaried,
            personalStatus,
            peopleOnMyTeamEnrolled,
            dueDate,
          });
      }}
    >
      <TopDIV>
        <TypeIcon $statusColor={personalStatus}>
          <Icon
            icon={type === "Course" ? "AcademicCapIcon" : "UsersIcon"}
            type={"outline"}
          />
        </TypeIcon>
        <CardContainerTitle>{name}</CardContainerTitle>
        {requaried && (
          <RequariedIcon>
            <Icon icon={"ShieldExclamationIcon"} type={"outline"} />
          </RequariedIcon>
        )}
      </TopDIV>
      <CardContainerDescription>{description}</CardContainerDescription>
      <CardTextDiv>
        <CardText>
          Enrolled: <CircleNumber>{totalEnroll}</CircleNumber>
        </CardText>
        <CardText>
          Team:{" "}
          <CircleNumber>
            {
              peopleOnMyTeamEnrolled.filter(
                (member) => member.status !== "Not Enrolled",
              ).length
            }
          </CircleNumber>
        </CardText>
        {dueDate && requaried && (
          <CardText>
            Due Date: <DueDateText>{getDueDate(dueDate)}</DueDateText>
          </CardText>
        )}
      </CardTextDiv>
    </CardContainer>
  );
}

export default CourseCard;

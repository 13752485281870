import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Icon } from "@/components";
import {
  AssociationCardContainer,
  AssociationCardTitle,
  CloseIcon,
  TypeIcon,
} from "./AssociationCard.styled";
import { AssociationCardType, coursesAndEventsType } from "@/models";

const AssociationCard = ({ id, onClose }: AssociationCardType) => {
  const coursesAndEvents = useSelector((state: any) => state.coursesAndEvents);
  const [currentCourse, setCurrentCourse] =
    useState<coursesAndEventsType | null>(null);

  useEffect(() => {
    if (coursesAndEvents) {
      const course = coursesAndEvents.find(
        (course: coursesAndEventsType) => course.id === id,
      );
      setCurrentCourse(course);
    }
  }, [coursesAndEvents, id]);

  return (
    <AssociationCardContainer>
      <TypeIcon>
        <Icon
          icon={
            currentCourse?.type === "Course" ? "AcademicCapIcon" : "UsersIcon"
          }
          type={"outline"}
        />
      </TypeIcon>
      <AssociationCardTitle>{currentCourse?.name}</AssociationCardTitle>
      {onClose && (
        <CloseIcon onClick={() => onClose && onClose()}>
          <Icon icon="XMarkIcon" type={"outline"} />
        </CloseIcon>
      )}
    </AssociationCardContainer>
  );
};

export default AssociationCard;

import apiRoutes from '@/services/api-routes';
import { useApiMutation } from "@/store/fetchClientInstance";
import { validateMFAMutation } from './validateQueryTypes.model';

export const useValidateMFA = () => {
  const [validateMFA, { data, isLoading, error, reset, status }] = useApiMutation();
  const handleValidateMFA = (code: validateMFAMutation) => {
    validateMFA({
      url: apiRoutes.login,
      config: {
        method: 'POST',
        body: code
      }
    });
  };

  return { validateMFA: handleValidateMFA, data, isLoading, error, reset, status };
};

import { IconStatusType } from "@/components/Icon/IconType";
import styled from "styled-components";

export const ModalButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
`;

export const ModalSpan = styled.span`
  font-size: 16px;
  color: #313131;
  & span {
    color: #048a24;
  }
`;

export const ModalButtonsContainerRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

export const ModalDivConatiner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 500px;
`;

export const AssociationCardsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
`;

interface MembersCardIconInterface {
  $statusColor: IconStatusType;
}

export const MembersCardIcon = styled.div<MembersCardIconInterface>`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: ${(props) => {
    switch (props.$statusColor) {
      case "In Progress":
        return "#e0bd00";
      case "Not Started":
        return "#117170";
      case "Done":
        return "#005A24";
      case "Blocked":
        return "#ce0000";
      case "Disabled":
        return "#8f8f8f";
      default:
        return "#FFFFFF";
    }
  }};
  color: white;
  border-radius: 8px;
  & svg {
    width: 60px;
    height: 60px;
  }
`;

export const MembersCardIconSelectContainer = styled.div<MembersCardIconInterface>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  flex-direction: column;
  color: ${(props) => {
    switch (props.$statusColor) {
      case "In Progress":
        return "#e0bd00";
      case "Not Started":
        return "#117170";
      case "Done":
        return "#005A24";
      case "Blocked":
        return "#ce0000";
      case "Disabled":
        return "#8f8f8f";
      default:
        return "#FFFFFF";
    }
  }};
`;

export const SelectContainerStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
`;

import React, { useState, useEffect } from "react";
import {
  LoginContainer,
  LoginDescription,
  LoginForm,
  LoginPanel,
  Logo,
} from "../Login/Login.styled.tsx";
import LogoSrc from "@/assets/images/innovation-logo.png";
import { useNavigate } from "react-router-dom";
import { LoadingPagey } from "../../animations/index.ts";
import jwtDecode from "jwt-decode";
import { LoadingPageyType } from "@/animations/LoadingPagey/LoadingPageyType.ts";
import { useAppDispatch, setSessionInfo } from "@/store";
import Paths from "@/paths.js";
import { useValidateMFA } from "@/query/Validate/index.ts";

function Validate() {
  const history = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] =
    useState<LoadingPageyType["state"]>("Loading");
  const { validateMFA, data, error } = useValidateMFA();

  useEffect(() => {
    const createSession = async (code: string) => {
      validateMFA({ code });
    };
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const code = params.get("code");
    createSession(code as string);
  }, [dispatch, history]);

  useEffect(() => {
    if (!error && data && data.csg_pd_access_token) {
      const { csg_pd_access_token } = data;
      localStorage.setItem("token", data.csg_pd_access_token); // This is a security risk remove when not needed
      setIsLoading("Success");
      setTimeout(() => {
        const tokenValue = jwtDecode(csg_pd_access_token, {
          header: false,
        }) as any;
        dispatch(
          setSessionInfo({
            exp: tokenValue.exp,
            iat: tokenValue.iat,
            sub: tokenValue.sub,
            name: tokenValue.name,
            authentication_type: tokenValue.authentication_type,
            role: tokenValue.claims.roles[0].role,
            apps: tokenValue.claims.roles[0].apps,
            allowedRead: tokenValue.claims.roles[0].apps
              .filter((app: any) => app.permissions.includes("READ"))
              .map((app: any) => app.app),
            allowedWrite: tokenValue.claims.roles[0].apps
              .filter((app: any) => app.permissions.includes("WRITE"))
              .map((app: any) => app.app),
            token: data.csg_pd_access_token,
          })
        );
        history(Paths.Dashboard);
      }, 2500);
    } else if (error) {
      setIsLoading("Error");
      setTimeout(() => {
        history(Paths.Unauthorized);
      }, 2500);
    }
  }, [data, error, dispatch, history]);

  return (
    <LoginContainer>
      <LoginForm>
        <Logo src={LogoSrc} />
        <LoadingPagey state={isLoading} />
        <LoginDescription>
          Copyright © {new Date().getFullYear()} Csg Innovation Services -
          Pagerduty, Inc. All rights reserved.
        </LoginDescription>
      </LoginForm>
      <LoginPanel />
    </LoginContainer>
  );
}

export default Validate;

import styled, { css } from 'styled-components';

export const TooltipContainer = styled.div`
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  padding: 4px 8px;
  position: relative;
  transition: all 0.2s;
  width: auto;
`;

export const InfoIcon = styled.span`
  background-color: #ccc;
  border-radius: 50%;
  color: white;
  display: inline-block;
  font-size: 14px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  width: 18px;
`;

interface TooltipContentProps {
  backgroundColor?: string;
  isVisible: boolean;
  position: 'top' | 'bottom' | 'left' | 'right';
  textColor?: string;
}

export const TooltipContent = styled.div<TooltipContentProps>`
  background-color: ${({ backgroundColor }) => backgroundColor || '#343434'};
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  color: ${({ textColor }) => textColor || '#ffffff'};
  font-weight: 400;
  max-width: 300px;
  min-width: 200px;
  opacity: 0;
  padding: 8px 16px;
  pointer-events: none;
  position: absolute;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  width: max-content;
  z-index: 1000;

  ${({ position }) => getPositionStyles(position)}

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      pointer-events: auto;
      transform: scale(1) translate(0, 0);
      visibility: visible;
    `}
`;

const getPositionStyles = (position: 'top' | 'bottom' | 'left' | 'right') => {
  switch (position) {
    case 'top':
      return css`
        bottom: calc(100% + 10px);
        left: 50%;
      `;
    case 'bottom':
      return css`
        left: 50%;
        top: calc(100% + 10px);
      `;
    case 'left':
      return css`
        right: calc(100% + 10px);
        top: 50%;
      `;
    case 'right':
      return css`
        left: calc(100% + 10px);
        top: 50%;
      `;
  }
};

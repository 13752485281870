import { managementReleasesType } from "@/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const EmptyState: managementReleasesType[] = [];

export const managementReleasesSlice = createSlice({
  name: "managementReleases",
  initialState: EmptyState,
  reducers: {
    setManagementReleases: (state, action: PayloadAction<managementReleasesType[]>) => {
      return [...action.payload];
    },
    clearManagementReleases: () => EmptyState,
  },
});

export const { setManagementReleases, clearManagementReleases } = managementReleasesSlice.actions;
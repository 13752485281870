import { statusElementsType } from "@/models";

export const statusElementsMock = <statusElementsType[]>[
    {
      name: "Not Started",
      color: "#117170",
      icon: "BellAlertIcon",
      reasonFor: false,
    },
    {
      name: "In Progress",
      color: "#e0bd00",
      icon: "RocketLaunchIcon",
      reasonFor: false,
    },
    {
      name: "Done",
      color: "#005A24",
      icon: "CheckCircleIcon",
      reasonFor: false,
    },
    {
      name: "Blocked",
      color: "#ce0000",
      icon: "NoSymbolIcon",
      reasonFor: true,
    },
  ];
import { useState, useEffect, useRef } from 'react';
import type { InstanceHeaders, SolutionProduct } from '../../services/api-types';
import { useGetQuery,useApiMutation } from '@/store/fetchClientInstance';
import { apiRoutes } from '@/services';
import { useMemo } from 'react';

export const useDeliveryData = (selectedUser?: any) => {
  const isQuery = Boolean(selectedUser);
  
  const { 
    data: queryData, 
    isLoading: isLoadingQuery 
  } = useGetQuery({
    url: apiRoutes.getCSMdashboardDeliveryData,
    skip: isQuery
  });

  const [fetchData, { 
    data: mutationData, 
    isLoading: isLoadingMutation 
  }] = useApiMutation();

  const [updateDeliveryData, {
    isLoading: isUpdating
  }] = useApiMutation();

  useEffect(() => {
    if (selectedUser) {
      fetchData({
        url: `${apiRoutes.getCSMdashboardDeliveryData}/query`,
        config: {
          method: 'POST',
          body: selectedUser.length > 0 ? selectedUser : [selectedUser]
        }
      });
    }
  }, [selectedUser, fetchData]);

  const data = isQuery ? mutationData : queryData;
  const isLoading = isQuery ? isLoadingMutation : isLoadingQuery;

  const processedData = useMemo(() => {
    if (!data || !data.length) return [];

    const uniqueSubdomains = {};
    const newData = [];
    const customers = data.map((item) => ({
      customer_name: item.customer_name,
      subdomain: item.subdomain,
    }));

    data.forEach(item => {
      if (!uniqueSubdomains[item.subdomain]) {
        uniqueSubdomains[item.subdomain] = true;

        const matchingCustomer = customers.find(
          customer => customer.subdomain === item.subdomain
        );

        newData.push({
          ...item,
          customer_name: matchingCustomer ? matchingCustomer.customer_name : item.customer_name
        });
      }
    });

    return newData.sort((a, b) => {
      let nameA = a.customer_name.toUpperCase();
      let nameB = b.customer_name.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  }, [data]);

  useEffect(() => {
  
  }, [data, processedData]);

  const handleUpdateDelivery = async (updatedData: any) => {
    try {
      await updateDeliveryData({
        url: apiRoutes.getCSMdashboardDeliveryData,
        config: {
          method: 'PUT',
          body: updatedData
        }
      });

      if (isQuery) {
        await fetchData({
          url: `${apiRoutes.getCSMdashboardDeliveryData}/query`,
          config: {
            method: 'POST',
            body: selectedUser.length > 0 ? selectedUser : [selectedUser]
          }
        });
      }

      return true;
    } catch (error) {
      console.error('Error updating delivery data:', error);
      return false;
    }
  };


  return {
    deliveryData: processedData,
    isLoading: isLoading || isUpdating,
    updateDeliveryData: handleUpdateDelivery,
    refetch: isQuery ? () => fetchData({
      url: `${apiRoutes.getCSMdashboardDeliveryData}/query`,
      config: {
        method: 'POST',
        body: selectedUser.length > 0 ? selectedUser : [selectedUser]
      }
    }) : undefined,
  };
};
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  ChevronRowIconsContainer,
  ChevronRowTitle,
  ChevronRowTitleContainer,
  PageContainer,
  FormRow,
  FormLabel,
  FormColumn,
  FormTextArea,
  ModalButtonsContainer,
  ModalButtonsContainerRight,
  ModalContainer,
  StatusTag,
  SpanButton,
} from "@/common.styled.tsx";
import {
  ActionsPopUp,
  Button,
  DatePicker,
  Dropdown,
  Icon,
  Modal,
  PDInput,
  QuartersPill,
  Tabs,
} from "@/components";
import { currentQuarterType } from "@/components/QuartersPill/QuartersPillType.ts";
import {
  BodyContainer,
  ButtonsContainer,
  SecondaryContainerTop,
  TopContainer,
} from "./Releases.styled.tsx";
import { useDispatch } from "react-redux";
import ReleaseCard from "./ReleaseCard/ReleaseCard.tsx";
import { v4 as uuidv4 } from "uuid";
import { deepCopy } from "@/utils/shared-functions.js";
import TasksModals from "./TasksModals/TasksModals.tsx";
import StatusSettings from "./StatusSettings/StatusSettings.tsx";
import { featuresType, managementReleasesType, stagesType, statusElementsType, statusListsType, tasksType } from "@/models";
import { setManagementReleases, useManagementReleasesSelector, useStatusListSelector, useTeamsInfoSelector, useUserManagementInfoSelector } from "@/store/states";

function Releases() {
  const dispatch = useDispatch();
  const tasksModals = useRef<any>();
  const releases = useManagementReleasesSelector();
  const statusList = useStatusListSelector();
  const teamsInfo = useTeamsInfoSelector();
  const userInfo = useUserManagementInfoSelector();
  
  const [currentQuarter, setCurrentQuarter] =
    useState<currentQuarterType>("Q1");
  const [openNewReleaseModal, setOpenNewReleaseModal] =
    useState<boolean>(false);
  const [openNewStageModal, setOpenNewStageModal] = useState<boolean>(false);
  const [openNewFeatureModal, setOpenNewFeatureModal] =
    useState<boolean>(false);
  const [openNewTaskModal, setOpenNewTaskModal] = useState<boolean>(false);
  const [selectedRelease, setSelectedRelease] = useState<managementReleasesType | null>(
    null,
  );
  const [currentStatusList, setCurrentStatusList] = useState<statusListsType>(
    deepCopy(statusList[0]),
  );
  const [isEditModeNewRelease, setIsEditModeNewRelease] =
    useState<boolean>(false);
  const [isEditModeNewStage, setIsEditModeNewStage] = useState<boolean>(false);
  const [isEditModeNewFeature, setIsEditModeNewFeature] =
    useState<boolean>(false);
  const [selectedStage, setSelectedStage] = useState<stagesType | null>(null);
  const [selectedFeature, setSelectedFeature] = useState<featuresType | null>(
    null,
  );
  const [selectedTask, setSelectedTask] = useState<tasksType | null>(null);
  const [releaseModalName, setReleaseModalName] = useState<string>("");
  const [releaseModalDescription, setReleaseModalDescription] =
    useState<string>("");
  const [releaseModalStartDate, setReleaseModalStartDate] =
    useState<Date | null>(null);
  const [releaseModalDueDate, setReleaseModalDueDate] = useState<Date | null>(
    null,
  );
  const [releaseModalRealStartDate, setReleaseModalRealStartDate] =
    useState<Date | null>(null);
  const [releaseModalRealEndDate, setReleaseModalRealEndDate] =
    useState<Date | null>(null);
  const [releasesStatuses, setReleasesStatuses] = useState<string[]>(
    [
      ...new Set(releases.map((release: managementReleasesType) => release.status.name)),
    ].sort() as string[],
  );
  const [activeTab, setActiveTab] = useState<string>(releasesStatuses[0]);
  const [releaseModalStatus, setReleaseModalStatus] =
    useState<statusElementsType>(currentStatusList.statusList[0]);
  const [releaseModalReasonFor, setReleaseModalReasonFor] =
    useState<string>("");

  const [stageModalName, setStageModalName] = useState<string>("");
  const [stageModalStartDate, setStageModalStartDate] = useState<Date | null>(
    null,
  );
  const [stageModalEndDate, setStageModalEndDate] = useState<Date | null>(null);
  const [stageModalRealStartDate, setStageModalRealStartDate] =
    useState<Date | null>(null);
  const [stageModalRealEndDate, setStageModalRealEndDate] =
    useState<Date | null>(null);
  const [stageModalStatus, setStageModalStatus] = useState<statusElementsType>(
    currentStatusList.statusList[0],
  );
  const [stageModalReasonFor, setStageModalReasonFor] = useState<string>("");
  const [stageModalDescription, setStageModalDescription] =
    useState<string>("");

  const [featureModalName, setFeatureModalName] = useState<string>("");
  const [featureModalDescription, setFeatureModalDescription] =
    useState<string>("");
  const [featureModalStatus, setFeatureModalStatus] =
    useState<statusElementsType>(currentStatusList.statusList[0]);
  const [featureAssignedTo, setFeatureAssignedTo] = useState<string>("");
  const [featureModalReasonFor, setFeatureModalReasonFor] =
    useState<string>("");

  //Actions
  const [openStatusSettings, setOpenStatusSettings] = useState<boolean>(false);

  useEffect(() => {
    setReleaseModalReasonFor("");
  }, [releaseModalStatus]);

  useEffect(() => {
    setStageModalReasonFor("");
  }, [stageModalStatus]);

  useEffect(() => {
    setFeatureModalReasonFor("");
  }, [featureModalStatus]);

  const initialStatusList = useMemo(
    () => statusList[0].statusList,
    [statusList],
  );

  useEffect(() => {
    if (statusList) {
      setCurrentStatusList(deepCopy(statusList[0]));
      !releaseModalStatus && setReleaseModalStatus(statusList[0].statusList[0]);
      !stageModalStatus && setStageModalStatus(statusList[0].statusList[0]);
      !featureModalStatus && setFeatureModalStatus(statusList[0].statusList[0]);
    }
  }, [
    initialStatusList,
    statusList,
    releaseModalStatus,
    stageModalStatus,
    featureModalStatus,
  ]);

  useEffect(() => {
    if (selectedRelease) {
      setCurrentStatusList({
        name: "",
        statusList: selectedRelease.statusList,
      });
    }
  }, [selectedRelease]);

  const getUsersList = useCallback(() => {
    if (teamsInfo && userInfo) {
      const usersList: any = [];
      usersList.push({ name: userInfo.name });
      teamsInfo.map((team: any) => {
        usersList.push({ name: team.name });
      });
      return usersList;
    }
    return [];
  }, [teamsInfo, userInfo]);

  const [usersList, setUsersList] = useState<any>(getUsersList());

  useEffect(() => {
    setUsersList(getUsersList());
  }, [userInfo, teamsInfo, getUsersList]);

  const makeNewId = () => {
    const newID = uuidv4();
    const idAlreadyExists = (id: string): boolean => {
      return releases.some((release: managementReleasesType) => release.id === id);
    };
    if (idAlreadyExists(newID)) {
      return makeNewId();
    }
    return newID;
  };

  const addNewRelease = () => {
    dispatch(
      setManagementReleases([
        ...releases,
        {
          name: releaseModalName,
          description: releaseModalDescription,
          startDate: releaseModalStartDate,
          dueDate: releaseModalDueDate,
          realStartDate: null,
          realEndDate: null,
          stages: [],
          id: Math.random().toString(36).substr(2, 9),
          status: releaseModalStatus,
          statusList: currentStatusList.statusList,
          reasonFor: releaseModalStatus?.reasonFor
            ? releaseModalReasonFor
            : null,
          v2Mom: "",
        },
      ]),
    );
    cancelNewRelease();
  };

  const editRelease = () => {
    if (!selectedRelease) return;
    const releaseCopy = [...releases];
    const releaseIndex = releaseCopy.findIndex(
      (release: managementReleasesType) => release.id === selectedRelease.id,
    );
    releaseCopy[releaseIndex] = {
      name: releaseModalName,
      description: releaseModalDescription,
      startDate: releaseModalStartDate,
      dueDate: releaseModalDueDate,
      realStartDate: releaseModalRealStartDate,
      realEndDate: releaseModalRealEndDate,
      stages: selectedRelease.stages,
      id: selectedRelease.id,
      status: releaseModalStatus,
      statusList: selectedRelease.statusList,
      reasonFor: releaseModalStatus?.reasonFor ? releaseModalReasonFor : null,
      v2Mom: "",
    };
    dispatch(setManagementReleases(releaseCopy));
    setSelectedRelease(releaseCopy[releaseIndex]);
    cancelNewRelease();
  };

  const cancelNewRelease = () => {
    setReleaseModalName("");
    setReleaseModalDescription("");
    setReleaseModalStartDate(null);
    setReleaseModalDueDate(null);
    setReleaseModalStatus(currentStatusList.statusList[0]);
    setReleaseModalReasonFor("");
    setOpenNewReleaseModal(false);
    setIsEditModeNewRelease(false);
  };

  const cancelNewStage = () => {
    setStageModalName("");
    setStageModalDescription("");
    setStageModalStartDate(null);
    setStageModalEndDate(null);
    setStageModalRealStartDate(null);
    setStageModalRealEndDate(null);
    setStageModalStatus(currentStatusList.statusList[0]);
    setStageModalReasonFor("");
    setOpenNewStageModal(false);
    setIsEditModeNewStage(false);
  };

  const cancelNewFeature = () => {
    setFeatureModalName("");
    setFeatureModalDescription("");
    setFeatureModalStatus(currentStatusList.statusList[0]);
    setFeatureAssignedTo("");
    setFeatureModalReasonFor("");
    setOpenNewFeatureModal(false);
    setIsEditModeNewFeature(false);
  };

  const addNewStage = () => {
    if (!selectedRelease) return;
    const releaseCopy = deepCopy(releases);
    const releaseIndex = releaseCopy.findIndex(
      (release: managementReleasesType) => release.id === selectedRelease.id,
    );
    releaseCopy[releaseIndex].stages.push({
      name: stageModalName,
      startDate: stageModalStartDate,
      endDate: stageModalEndDate,
      realStartDate: null,
      realEndDate: null,
      status: stageModalStatus,
      reasonFor: stageModalStatus.reasonFor ? stageModalReasonFor : null,
      features: [],
      id: makeNewId(),
      description: stageModalDescription,
    });
    dispatch(setManagementReleases(releaseCopy));
    setSelectedRelease(releaseCopy[releaseIndex]);
    cancelNewStage();
  };

  const editStage = () => {
    if (!selectedRelease || !selectedStage?.id) return;
    const releaseCopy = deepCopy(releases);
    const releaseIndex = releaseCopy.findIndex(
      (release: managementReleasesType) => release.id === selectedRelease.id,
    );
    const stageIndex = releaseCopy[releaseIndex].stages.findIndex(
      (stage: stagesType) => stage.id === selectedStage.id,
    );
    releaseCopy[releaseIndex].stages[stageIndex] = {
      name: stageModalName,
      startDate: stageModalStartDate,
      endDate: stageModalEndDate,
      realStartDate: stageModalRealStartDate,
      realEndDate: stageModalRealEndDate,
      status: stageModalStatus,
      reasonFor: stageModalStatus.reasonFor ? stageModalReasonFor : null,
      features: selectedStage.features,
      id: selectedStage.id,
      description: stageModalDescription,
    };
    dispatch(setManagementReleases(releaseCopy));
    setSelectedRelease(releaseCopy[releaseIndex]);
    setSelectedStage(releaseCopy[releaseIndex].stages[stageIndex]);
    setOpenNewStageModal(false);
  };

  const editReleaseOpenModal = () => {
    setIsEditModeNewRelease(true);
    setOpenNewReleaseModal(true);
    if (selectedRelease) {
      setReleaseModalName(selectedRelease.name);
      setReleaseModalDescription(selectedRelease.description);
      setReleaseModalStartDate(selectedRelease.startDate);
      setReleaseModalDueDate(selectedRelease.dueDate);
      setReleaseModalStatus(selectedRelease.status);
      setReleaseModalReasonFor(selectedRelease.reasonFor || "");
    }
  };

  const editStageOpenModal = () => {
    if (selectedRelease && selectedStage) {
      const stage = selectedRelease.stages.find(
        (stage: stagesType) => stage.id === selectedStage.id,
      );
      if (stage) {
        setStageModalName(stage.name);
        setStageModalDescription(stage.description);
        setStageModalStartDate(stage.startDate);
        setStageModalRealEndDate(stage.realEndDate);
        setStageModalRealStartDate(stage.realStartDate);
        setStageModalEndDate(stage.endDate);
        setStageModalStatus(stage.status);
        setStageModalReasonFor(stage.reasonFor || "");
      }
    }
    setIsEditModeNewStage(true);
    setOpenNewStageModal(true);
  };

  const editFeatureOpenModal = () => {
    if (selectedRelease && selectedStage && selectedFeature) {
      const feature = selectedRelease.stages
        .find((stage: stagesType) => stage.id === selectedStage.id)
        ?.features.find(
          (feature: featuresType) => feature.id === selectedFeature.id,
        );
      if (feature) {
        setFeatureModalName(feature.name);
        setFeatureModalDescription(feature.description);
        setFeatureModalStatus(feature.status);
        setFeatureAssignedTo(feature.assignedTo);
        setFeatureModalReasonFor(feature.reasonFor || "");
      }
    }
    setIsEditModeNewFeature(true);
    setOpenNewFeatureModal(true);
  };

  const addNewFeature = () => {
    if (!selectedStage) return;
    const releaseCopy = deepCopy(releases);
    const releaseIndex = releaseCopy.findIndex(
      (release: managementReleasesType) => release.id === selectedRelease?.id,
    );
    const stageIndex = releaseCopy[releaseIndex].stages.findIndex(
      (stage: stagesType) => stage.id === selectedStage?.id,
    );
    releaseCopy[releaseIndex].stages[stageIndex].features.push({
      name: featureModalName,
      status: featureModalStatus,
      description: featureModalDescription,
      assignedTo: featureAssignedTo,
      tasks: [],
      id: makeNewId(),
      reasonFor: featureModalStatus.reasonFor ? featureModalReasonFor : null,
    });
    dispatch(setManagementReleases(releaseCopy));
    setSelectedRelease(releaseCopy[releaseIndex]);
    setSelectedStage(releaseCopy[releaseIndex].stages[stageIndex]);
    cancelNewFeature();
  };

  const editFeature = () => {
    if (!selectedRelease || !selectedStage || !selectedFeature?.id) return;
    const releaseCopy = deepCopy(releases);
    const releaseIndex = releaseCopy.findIndex(
      (release: managementReleasesType) => release.id === selectedRelease.id,
    );
    const stageIndex = releaseCopy[releaseIndex].stages.findIndex(
      (stage: stagesType) => stage.id === selectedStage.id,
    );
    const featureIndex = releaseCopy[releaseIndex].stages[
      stageIndex
    ].features.findIndex(
      (feature: featuresType) => feature.id === selectedFeature.id,
    );
    releaseCopy[releaseIndex].stages[stageIndex].features[featureIndex] = {
      name: featureModalName,
      status: featureModalStatus,
      description: featureModalDescription,
      assignedTo: featureAssignedTo,
      tasks: selectedFeature.tasks,
      id: selectedFeature.id,
      reasonFor: featureModalStatus.reasonFor ? featureModalReasonFor : null,
    };
    dispatch(setManagementReleases(releaseCopy));
    setSelectedRelease(releaseCopy[releaseIndex]);
    setSelectedStage(releaseCopy[releaseIndex].stages[stageIndex]);
    setSelectedFeature(
      releaseCopy[releaseIndex].stages[stageIndex].features[featureIndex],
    );
    cancelNewFeature();
  };

  const [removeReleaseModal, setRemoveReleaseModal] = useState<boolean>(false);
  const [removeStageModal, setRemoveStageModal] = useState<boolean>(false);
  const [removeFeatureModal, setRemoveFeatureModal] = useState<boolean>(false);
  const [removeTaskModal, setRemoveTaskModal] = useState<boolean>(false);

  const removeRelease = () => {
    if (!selectedRelease) return;
    const releaseCopy = deepCopy(releases);
    const releaseIndex = releaseCopy.findIndex(
      (release: managementReleasesType) => release.id === selectedRelease.id,
    );
    releaseCopy.splice(releaseIndex, 1);
    dispatch(setManagementReleases(releaseCopy));
    setSelectedRelease(null);
    setOpenNewReleaseModal(false);
  };

  const removeStage = () => {
    if (!selectedRelease || !selectedStage) return;
    const releaseCopy = deepCopy(releases);
    const releaseIndex = releaseCopy.findIndex(
      (release: managementReleasesType) => release.id === selectedRelease.id,
    );
    const stageIndex = releaseCopy[releaseIndex].stages.findIndex(
      (stage: stagesType) => stage.id === selectedStage.id,
    );
    releaseCopy[releaseIndex].stages.splice(stageIndex, 1);
    dispatch(setManagementReleases(releaseCopy));
    setSelectedRelease(releaseCopy[releaseIndex]);
    setSelectedStage(null);
    cancelNewStage();
  };

  const removeFeature = () => {
    if (!selectedRelease || !selectedStage || !selectedFeature) return;
    const releaseCopy = deepCopy(releases);
    const releaseIndex = releaseCopy.findIndex(
      (release: managementReleasesType) => release.id === selectedRelease.id,
    );
    const stageIndex = releaseCopy[releaseIndex].stages.findIndex(
      (stage: stagesType) => stage.id === selectedStage.id,
    );
    const featureIndex = releaseCopy[releaseIndex].stages[
      stageIndex
    ].features.findIndex(
      (feature: featuresType) => feature.id === selectedFeature.id,
    );
    releaseCopy[releaseIndex].stages[stageIndex].features.splice(
      featureIndex,
      1,
    );
    dispatch(setManagementReleases(releaseCopy));
    setSelectedRelease(releaseCopy[releaseIndex]);
    setSelectedStage(releaseCopy[releaseIndex].stages[stageIndex]);
    setSelectedFeature(null);
    cancelNewFeature();
  };

  const actionsLiteral = {
    "Status Settings": () => {
      setOpenStatusSettings(true);
    },
  };

  useEffect(() => {
    if (releases) {
      setReleasesStatuses(
        [
          ...new Set(
            releases.map((release: managementReleasesType) => release.status.name),
          ),
        ].sort() as string[],
      );
    }
  }, [releases]);

  return (
    <>
      <PageContainer>
        {!openStatusSettings && (
          <TopContainer>
            <QuartersPill
              onClick={(quarter: currentQuarterType) => {
                setCurrentQuarter(quarter);
              }}
              currentQuarter={currentQuarter}
            />
          </TopContainer>
        )}
        {openStatusSettings && (
          <StatusSettings
            onReturn={() => {
              setOpenStatusSettings(false);
            }}
          />
        )}
        {!selectedRelease && !openStatusSettings && (
          <SecondaryContainerTop>
            <Tabs
              tabs={releasesStatuses}
              activeTab={activeTab}
              onTabChange={setActiveTab}
              type="color"
            />
            <ActionsPopUp
              actions={[
                {
                  disabled: false,
                  icon: {
                    icon: "HashtagIcon",
                    type: "outline",
                  },
                  label: "Status Settings",
                },
              ]}
              onClick={(action: string) => {
                (actionsLiteral as any)[action]();
              }}
              label={"Settings"}
              icon={{
                icon: "Cog8ToothIcon",
                type: "outline",
              }}
            />
          </SecondaryContainerTop>
        )}
        <BodyContainer>
          {!selectedRelease && !openStatusSettings && (
            <>
              {releases
                .filter(
                  (release: managementReleasesType) => release.status.name === activeTab,
                )
                .map((release: managementReleasesType) => (
                  <ChevronRowTitleContainer
                    color={release.status.color}
                    key={release.id}
                  >
                    <ChevronRowTitle>{release.name}</ChevronRowTitle>
                    <ChevronRowIconsContainer>
                      <SpanButton
                        onClick={() => {
                          setSelectedRelease(release);
                        }}
                      >
                        <Icon icon={"ChevronRightIcon"} type={"outline"} />
                      </SpanButton>
                    </ChevronRowIconsContainer>
                  </ChevronRowTitleContainer>
                ))}
            </>
          )}
          {selectedRelease && (
            <ReleaseCard
              release={selectedRelease}
              quarter={currentQuarter}
              year={2024}
              onResetRelease={() => {
                setSelectedRelease(null);
              }}
              onAddNewStage={() => {
                setOpenNewStageModal(true);
              }}
              onAddNewFeature={() => {
                setOpenNewFeatureModal(true);
              }}
              onAddNewTask={() => {
                setOpenNewTaskModal(true);
              }}
              onEditReleaseModal={() => {
                editReleaseOpenModal();
              }}
              onEditStageModal={() => {
                editStageOpenModal();
              }}
              onEditFeatureModal={() => {
                editFeatureOpenModal();
              }}
              onEditTaskModal={() => {
                tasksModals?.current?.editTaskOpenModal();
              }}
              setSelectedStage={(stage: stagesType | null) => {
                setSelectedStage(stage);
              }}
              selectedStage={selectedStage}
              selectedFeature={selectedFeature}
              setSelectedFeature={(feature: featuresType | null) => {
                setSelectedFeature(feature);
              }}
              selectedTask={selectedTask}
              setSelectedTask={(task: tasksType | null) => {
                setSelectedTask(task);
              }}
            />
          )}

          {!selectedRelease && !openStatusSettings && (
            <ButtonsContainer>
              <Button
                text="New Release"
                onClick={() => {
                  setOpenNewReleaseModal(true);
                }}
              />
            </ButtonsContainer>
          )}
        </BodyContainer>
      </PageContainer>
      <Modal
        handleClose={() => {
          cancelNewRelease();
        }}
        show={openNewReleaseModal}
        title={
          isEditModeNewRelease ? `Release ${releaseModalName}` : "New Releases"
        }
      >
        <ModalContainer>
          <FormLabel $mandatory="true">Release Name</FormLabel>
          <PDInput
            placeholder="Release Name"
            value={releaseModalName}
            onChange={(value: string) => setReleaseModalName(value)}
          />
          <FormLabel $mandatory="true">Release Description</FormLabel>
          <FormTextArea
            placeholder="Release Description"
            value={releaseModalDescription}
            onChange={(value) => setReleaseModalDescription(value.target.value)}
          />
          <FormRow>
            <FormColumn>
              <FormLabel $mandatory="true">Start Date</FormLabel>
              <DatePicker
                value={releaseModalStartDate}
                onChange={(value: Date) => setReleaseModalStartDate(value)}
                size="Form"
              />
            </FormColumn>
            <FormColumn>
              <FormLabel $mandatory="true">Due Date</FormLabel>
              <DatePicker
                value={releaseModalDueDate}
                onChange={(value: Date) => setReleaseModalDueDate(value)}
                size="Form"
              />
            </FormColumn>
          </FormRow>
          {isEditModeNewRelease && (
            <FormRow>
              <FormColumn>
                <FormLabel>Real Start Date</FormLabel>
                <DatePicker
                  value={releaseModalRealStartDate}
                  onChange={(value: Date) =>
                    setReleaseModalRealStartDate(value)
                  }
                  size="Form"
                />
              </FormColumn>
              <FormColumn>
                <FormLabel>Real End Date</FormLabel>
                <DatePicker
                  value={releaseModalRealEndDate}
                  onChange={(value: Date) => setReleaseModalRealEndDate(value)}
                  size="Form"
                />
              </FormColumn>
            </FormRow>
          )}
          {!isEditModeNewRelease && (
            <>
              <FormLabel $mandatory="true">
                Status List (Not editable)
              </FormLabel>
              <Dropdown
                value={currentStatusList.name}
                onChange={(value: string) => {
                  setCurrentStatusList(
                    statusList.find(
                      (statusList: statusListsType) =>
                        statusList.name === value,
                    ) || [],
                  );
                  setReleaseModalStatus(
                    statusList.find(
                      (statusList: statusListsType) =>
                        statusList.name === value,
                    )?.statusList[0] || {
                      name: "",
                      color: "",
                      reasonFor: false,
                      icon: "",
                    },
                  );
                  setStageModalStatus(
                    statusList.find(
                      (statusList: statusListsType) =>
                        statusList.name === value,
                    )?.statusList[0] || {
                      name: "",
                      color: "",
                      reasonFor: false,
                      icon: "",
                    },
                  );
                  setFeatureModalStatus(
                    statusList.find(
                      (statusList: statusListsType) =>
                        statusList.name === value,
                    )?.statusList[0] || {
                      name: "",
                      color: "",
                      reasonFor: false,
                      icon: "",
                    },
                  );
                }}
                options={statusList.map((status: statusElementsType) => {
                  return { value: status.name };
                })}
                displayValue={"value"}
              />
            </>
          )}
          <FormLabel $mandatory="true">Status</FormLabel>
          <FormRow $position="center">
            {currentStatusList.statusList.map(
              (statusElement: statusElementsType) => (
                <StatusTag
                  key={statusElement.name}
                  $statusColor={
                    statusElement.name === releaseModalStatus.name
                      ? statusElement.color
                      : "#d0d0d0"
                  }
                  onClick={() => setReleaseModalStatus(statusElement)}
                >
                  <Icon icon={statusElement.icon} type={"outline"} />
                  {statusElement.name}
                </StatusTag>
              ),
            )}
          </FormRow>
          {releaseModalStatus?.reasonFor && (
            <>
              <FormLabel $mandatory="true">Reason for</FormLabel>
              <FormTextArea
                value={releaseModalReasonFor}
                onChange={(value) =>
                  setReleaseModalReasonFor(value.target.value)
                }
                placeholder="Reason for moving this release"
              />
            </>
          )}
          <ModalButtonsContainer>
            <div>
              {selectedRelease && (
                <Button
                  text="Delete"
                  type="secondary"
                  color="#f44336"
                  onClick={() => setRemoveReleaseModal(true)}
                />
              )}
            </div>
            <ModalButtonsContainerRight>
              <Button
                text={isEditModeNewRelease ? "Edit" : "Create"}
                type="secondary"
                color="#048a24"
                onClick={() => {
                  isEditModeNewRelease ? editRelease() : addNewRelease();
                }}
                disabled={
                  !releaseModalName ||
                  !releaseModalDescription ||
                  !releaseModalStartDate ||
                  !releaseModalDueDate ||
                  !releaseModalStatus ||
                  (releaseModalStatus?.reasonFor && !releaseModalReasonFor)
                }
              />
              <Button
                text="Cancel"
                type="secondary"
                color="#f44336"
                onClick={() => {
                  cancelNewRelease();
                }}
              />
            </ModalButtonsContainerRight>
          </ModalButtonsContainer>
        </ModalContainer>
      </Modal>
      <Modal
        handleClose={() => {
          cancelNewStage();
        }}
        show={openNewStageModal}
        title={isEditModeNewStage ? `Stage ${stageModalName}` : "New Stage"}
      >
        <ModalContainer>
          <FormLabel $mandatory="true">Stage Name</FormLabel>
          <PDInput
            placeholder="Stage Name"
            value={stageModalName}
            onChange={(value: string) => setStageModalName(value)}
          />
          <FormLabel $mandatory="true">Stage Description</FormLabel>
          <FormTextArea
            placeholder="Stage Description"
            value={stageModalDescription}
            onChange={(value) => setStageModalDescription(value.target.value)}
          />
          <FormRow>
            <FormColumn>
              <FormLabel $mandatory="true">Start Date</FormLabel>
              <DatePicker
                value={stageModalStartDate}
                onChange={(value: Date) => setStageModalStartDate(value)}
                size="Form"
              />
            </FormColumn>
            <FormColumn>
              <FormLabel $mandatory="true">End Date</FormLabel>
              <DatePicker
                value={stageModalEndDate}
                onChange={(value: Date) => setStageModalEndDate(value)}
                size="Form"
              />
            </FormColumn>
          </FormRow>
          {isEditModeNewStage && (
            <FormRow>
              <FormColumn>
                <FormLabel>Real Start Date</FormLabel>
                <DatePicker
                  value={selectedStage?.realStartDate}
                  onChange={(value: Date) => setStageModalRealStartDate(value)}
                  size="Form"
                />
              </FormColumn>
              <FormColumn>
                <FormLabel>Real End Date</FormLabel>
                <DatePicker
                  value={selectedStage?.realEndDate}
                  onChange={(value: Date) => setStageModalRealEndDate(value)}
                  size="Form"
                />
              </FormColumn>
            </FormRow>
          )}
          <FormLabel $mandatory="true">Status</FormLabel>
          <FormRow $position="center">
            {currentStatusList.statusList.map(
              (statusElement: statusElementsType) => (
                <StatusTag
                  key={statusElement.name}
                  $statusColor={
                    statusElement.name === stageModalStatus.name
                      ? statusElement.color
                      : "#d0d0d0"
                  }
                  onClick={() => setStageModalStatus(statusElement)}
                >
                  <Icon icon={statusElement.icon} type={"outline"} />
                  {statusElement.name}
                </StatusTag>
              ),
            )}
          </FormRow>
          {stageModalStatus.reasonFor && (
            <>
              <FormLabel $mandatory="true">Reason for</FormLabel>
              <FormTextArea
                value={stageModalReasonFor}
                onChange={(value) => setStageModalReasonFor(value.target.value)}
                placeholder="Reason for moving this stage"
              />
            </>
          )}
          <ModalButtonsContainer>
            <div>
              {selectedStage && (
                <Button
                  text="Delete"
                  type="secondary"
                  color="#f44336"
                  onClick={() => setRemoveStageModal(true)}
                />
              )}
            </div>
            <ModalButtonsContainerRight>
              <Button
                text={isEditModeNewStage ? "Edit" : "Create"}
                type="secondary"
                color="#048a24"
                onClick={() => {
                  isEditModeNewStage ? editStage() : addNewStage();
                }}
                disabled={
                  !stageModalName ||
                  !stageModalDescription ||
                  !stageModalStartDate ||
                  !stageModalEndDate ||
                  !stageModalStatus ||
                  (stageModalStatus.reasonFor && !stageModalReasonFor)
                }
              />
              <Button
                text="Cancel"
                type="secondary"
                color="#f44336"
                onClick={() => {
                  cancelNewStage();
                }}
              />
            </ModalButtonsContainerRight>
          </ModalButtonsContainer>
        </ModalContainer>
      </Modal>
      <Modal
        handleClose={() => {
          cancelNewFeature();
        }}
        show={openNewFeatureModal}
        title={
          isEditModeNewFeature ? `Feature ${featureModalName}` : "New Feature"
        }
      >
        <ModalContainer>
          <FormLabel $mandatory="true">Feature Name</FormLabel>
          <PDInput
            placeholder="Feature Name"
            value={featureModalName}
            onChange={(value: string) => setFeatureModalName(value)}
          />
          <FormLabel $mandatory="true">Feature Description</FormLabel>
          <FormTextArea
            placeholder="Feature Description"
            value={featureModalDescription}
            onChange={(value) => setFeatureModalDescription(value.target.value)}
          />
          <FormLabel $mandatory="true">Feature Assigned To</FormLabel>
          <Dropdown
            options={usersList}
            value={featureAssignedTo}
            onChange={(value: string) => setFeatureAssignedTo(value)}
            displayValue={"name"}
          />
          <FormLabel $mandatory="true">Status</FormLabel>
          <FormRow $position="center">
            {currentStatusList.statusList.map(
              (statusElement: statusElementsType) => (
                <StatusTag
                  key={statusElement.name}
                  $statusColor={
                    statusElement.name === featureModalStatus.name
                      ? statusElement.color
                      : "#d0d0d0"
                  }
                  onClick={() => setFeatureModalStatus(statusElement)}
                >
                  <Icon icon={statusElement.icon} type={"outline"} />
                  {statusElement.name}
                </StatusTag>
              ),
            )}
          </FormRow>
          {featureModalStatus.reasonFor && (
            <>
              <FormLabel $mandatory="true">Reason for</FormLabel>
              <FormTextArea
                value={featureModalReasonFor}
                onChange={(value) =>
                  setFeatureModalReasonFor(value.target.value)
                }
                placeholder="Reason for moving this feature"
              />
            </>
          )}
          <ModalButtonsContainer>
            <div>
              {selectedFeature && (
                <Button
                  text="Delete"
                  type="secondary"
                  color="#f44336"
                  onClick={() => setRemoveFeatureModal(true)}
                />
              )}
            </div>
            <ModalButtonsContainerRight>
              <Button
                text={isEditModeNewFeature ? "Edit" : "Create"}
                type="secondary"
                color="#048a24"
                onClick={() => {
                  isEditModeNewFeature ? editFeature() : addNewFeature();
                }}
                disabled={
                  !featureModalName ||
                  !featureModalDescription ||
                  !featureAssignedTo ||
                  !featureModalStatus ||
                  (featureModalStatus.reasonFor && !featureModalReasonFor)
                }
              />
              <Button
                text="Cancel"
                type="secondary"
                color="#f44336"
                onClick={() => {
                  cancelNewFeature();
                }}
              />
            </ModalButtonsContainerRight>
          </ModalButtonsContainer>
        </ModalContainer>
      </Modal>
      <Modal
        handleClose={() => setRemoveFeatureModal(false)}
        show={removeFeatureModal}
        title={"Delete feature confirmation"}
        modalFitContent={true}
      >
        <ModalContainer>
          <FormLabel>Are you sure you want to delete this feature?</FormLabel>
          <ModalButtonsContainer>
            <Button
              text="Delete"
              type="secondary"
              color="#f44336"
              onClick={() => {
                removeFeature();
                setRemoveFeatureModal(false);
              }}
            />
            <Button
              text="Cancel"
              type="secondary"
              color="#048a24"
              onClick={() => {
                setRemoveFeatureModal(false);
              }}
            />
          </ModalButtonsContainer>
        </ModalContainer>
      </Modal>
      <Modal
        handleClose={() => setRemoveStageModal(false)}
        show={removeStageModal}
        title={"Delete stage confirmation"}
        modalFitContent={true}
      >
        <ModalContainer>
          <FormLabel>Are you sure you want to delete this stage?</FormLabel>
          <ModalButtonsContainer>
            <Button
              text="Delete"
              type="secondary"
              color="#f44336"
              onClick={() => {
                removeStage();
                setRemoveStageModal(false);
              }}
            />
            <Button
              text="Cancel"
              type="secondary"
              color="#048a24"
              onClick={() => {
                setRemoveStageModal(false);
              }}
            />
          </ModalButtonsContainer>
        </ModalContainer>
      </Modal>
      <Modal
        handleClose={() => setRemoveReleaseModal(false)}
        show={removeReleaseModal}
        title={"Delete release confirmation"}
        modalFitContent={true}
      >
        <ModalContainer>
          <FormLabel>Are you sure you want to delete this release?</FormLabel>
          <ModalButtonsContainer>
            <Button
              text="Delete"
              type="secondary"
              color="#f44336"
              onClick={() => {
                removeRelease();
                setRemoveReleaseModal(false);
              }}
            />
            <Button
              text="Cancel"
              type="secondary"
              color="#048a24"
              onClick={() => {
                setRemoveReleaseModal(false);
              }}
            />
          </ModalButtonsContainer>
        </ModalContainer>
      </Modal>
      <TasksModals
        ref={tasksModals}
        releases={releases}
        releaseId={selectedRelease?.id}
        stageId={selectedStage?.id}
        featureId={selectedFeature?.id}
        selectedTask={selectedTask}
        selectedRelease={selectedRelease}
        openTaskModalDelete={removeTaskModal}
        onDeleteTaskModal={(status: boolean) => {
          setRemoveTaskModal(status);
        }}
        onTaskAdded={(
          release: managementReleasesType[],
          releaseIndex: number,
          stageIndex: number,
          featureIndex: number,
        ) => {
          setSelectedRelease(release[releaseIndex]);
          setSelectedStage(release[releaseIndex].stages[stageIndex]);
          setSelectedFeature(
            release[releaseIndex].stages[stageIndex].features[featureIndex],
          );
          setOpenNewTaskModal(false);
        }}
        onTaskEdited={(
          release: managementReleasesType[],
          releaseIndex: number,
          stageIndex: number,
          featureIndex: number,
          taskIndex: number,
        ) => {
          setSelectedRelease(release[releaseIndex]);
          setSelectedStage(release[releaseIndex].stages[stageIndex]);
          setSelectedFeature(
            release[releaseIndex].stages[stageIndex].features[featureIndex],
          );
          setOpenNewTaskModal(false);
          setSelectedTask(
            release[releaseIndex].stages[stageIndex].features[featureIndex]
              .tasks[taskIndex],
          );
          setOpenNewTaskModal(false);
        }}
        onTaskDelete={(
          release: managementReleasesType[],
          releaseIndex: number,
          stageIndex: number,
          featureIndex: number,
        ) => {
          setSelectedRelease(release[releaseIndex]);
          setSelectedStage(release[releaseIndex].stages[stageIndex]);
          setSelectedFeature(
            release[releaseIndex].stages[stageIndex].features[featureIndex],
          );
          setSelectedTask(null);
          setOpenNewTaskModal(false);
        }}
        openTaskModalAdd={openNewTaskModal}
        onAddTaskModal={(status: boolean) => {
          setOpenNewTaskModal(status);
        }}
      />
    </>
  );
}

export default Releases;

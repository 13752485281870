import { coursesAndEventsStateType } from "@/models";
import styled from "styled-components";

interface CardContainerInterface {
  $statusColor: coursesAndEventsStateType;
}

export const CardContainer = styled.div<CardContainerInterface>`
  margin: 10px 0;
  padding: 10px;
  border-radius: 16px;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  border-top: 2px solid
    ${(props) => {
      switch (props.$statusColor) {
        case "In Progress":
          return "#e0bd00";
        case "Not Started":
          return "#117170";
        case "Done":
          return "#005A24";
        case "Not Enrolled":
          return "#8f8f8f";
        default:
          return "#FFFFFF";
      }
    }};
`;

export const TopDIV = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TypeIcon = styled.div<CardContainerInterface>`
  background-color: ${({ $statusColor }) => {
    switch ($statusColor) {
      case "In Progress":
        return "#e0bd00";
      case "Not Started":
        return "#117170";
      case "Done":
        return "#005A24";
      case "Not Enrolled":
        return "#8f8f8f";
      default:
        return "#FFFFFF";
    }
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  & svg {
    height: 20px;
    width: 20px;
  }
`;

export const CardContainerTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const RequariedIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff0000;
  width: 40px;
  height: 40px;
  & svg {
    height: 20px;
    width: 20px;
  }
`;

export const CardContainerDescription = styled.div`
  font-size: 14px;
  color: #333;
  margin: 10px 0;
`;
export const CardTextDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const CardText = styled.div`
  font-size: 14px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const DueDateText = styled.div`
  font-size: 14px;
  color: #ff0000;
`;

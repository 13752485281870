import { PDInput, Icon, Modal, Dropdown, Button } from "@/components";
import AssociationCard from "../AssociationCard";
import {
  ModalDivConatiner,
  ModalSpan,
  AssociationCardsDiv,
  SelectContainerStatus,
  MembersCardIconSelectContainer,
  MembersCardIcon,
  ModalButtonsContainer,
  ModalButtonsContainerRight,
  TitleInputs,
} from "./FormModal.styled";
import { deepCopy } from "@/utils/shared-functions";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";
import { formModalType, statusType, coursesAndEventsType, goalsType } from "@/models";
import { setUserManagementInfo, useCoursesAndEventsSelector, useUserManagementInfoSelector } from "@/store/states";

const FormModal = forwardRef(({ currentQuarter }: formModalType, ref) => {
  const dispatch = useDispatch();
  const userInfo = useUserManagementInfoSelector();
  const coursesAndEvents = useCoursesAndEventsSelector();
  const [modalStatus, setModalStatus] = useState<statusType>("Not Started");
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [newGoalsName, setNewGoalsName] = useState<string>("");
  const [newGoalsDescription, setNewGoalsDescription] = useState<string>("");
  const [newGoalsAssociations, setNewGoalsAssociations] = useState<
    coursesAndEventsType[]
  >([]);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    addNewGoal,
    editGoal,
  }));

  const addNewGoal = () => {
    setOpenModal(true);
    setNewGoalsAssociations([]);
  };

  const editGoal = (index: number, goals: goalsType) => {
    setSelectedIndex(index);
    setNewGoalsName(goals.name);
    setNewGoalsDescription(goals.description);
    setModalStatus(goals.status);
    setNewGoalsAssociations(
      coursesAndEvents.filter((association: coursesAndEventsType) =>
        goals.associations.includes(association.id),
      ),
    );
    setOpenModal(true);
  };

  const resetNewGoals = () => {
    setOpenModal(false);
    setNewGoalsName("");
    setNewGoalsDescription("");
    setNewGoalsAssociations([]);
    setModalStatus("Not Started");
    setSelectedIndex(null);
  };

  const addOrEditGoal = () => {
    const userInfoCopy = deepCopy(userInfo);
    if (selectedIndex === null) {
      userInfoCopy.goals[currentQuarter].push({
        name: newGoalsName,
        status: modalStatus,
        description: newGoalsDescription,
        associations: newGoalsAssociations.map((association) => association.id),
      });
    } else if (selectedIndex !== null) {
      userInfoCopy.goals[currentQuarter][selectedIndex] = {
        name: newGoalsName,
        status: modalStatus,
        description: newGoalsDescription,
        associations: newGoalsAssociations.map((association) => association.id),
      };
    }
    dispatch(setUserManagementInfo(userInfoCopy));
    resetNewGoals();
  };

  const deleteGoal = () => {
    const userInfoCopy = deepCopy(userInfo);
    if (userInfoCopy !== null) {
      userInfoCopy.goals[currentQuarter].splice(selectedIndex, 1);
    }
    dispatch(setUserManagementInfo(userInfoCopy));
    resetNewGoals();
  };

  const removeCard = (id: number) => {
    setNewGoalsAssociations(
      newGoalsAssociations.filter(
        (association: coursesAndEventsType) => association.id !== id,
      ),
    );
  };

  return (
    <Modal
      handleClose={() => setOpenModal(!openModal)}
      show={openModal}
      title={newGoalsName || "New goals"}
    >
      <ModalDivConatiner>
        <ModalSpan>
          The following goal will be{" "}
          {newGoalsName ? "edited from " : "added to "}
          {<span>{userInfo.name}</span>}
        </ModalSpan>
        <TitleInputs>Goal Name</TitleInputs>
        <PDInput
          placeholder="Goal Name"
          value={newGoalsName}
          onChange={(value: string) => setNewGoalsName(value)}
        />
        <TitleInputs>Description</TitleInputs>
        <PDInput
          placeholder="Description"
          value={newGoalsDescription}
          onChange={(value: string) => setNewGoalsDescription(value)}
        />
        <TitleInputs>Associations</TitleInputs>
        <Dropdown
          displayValue={"name"}
          title={"Select Associations"}
          options={coursesAndEvents.filter(
            (course: coursesAndEventsType) =>
              !newGoalsAssociations
                .map((association) => association.id)
                .includes(course.id),
          )}
          onChange={(_, value) =>
            setNewGoalsAssociations([
              ...newGoalsAssociations,
              coursesAndEvents.find(
                (course: coursesAndEventsType) => course.id === value.id
              ) as coursesAndEventsType,
            ])
          }
        />
        <AssociationCardsDiv>
          {newGoalsAssociations.map((association) => (
            <AssociationCard
              key={association.id}
              id={association.id}
              onClose={() => removeCard(association.id)}
            />
          ))}
        </AssociationCardsDiv>
        <div>Status</div>
        <SelectContainerStatus>
          <MembersCardIconSelectContainer
            onClick={() => setModalStatus("Not Started")}
            $statusColor={
              modalStatus === "Not Started" ? "Not Started" : "Disabled"
            }
          >
            <MembersCardIcon
              title={"Not Started"}
              $statusColor={
                modalStatus === "Not Started" ? "Not Started" : "Disabled"
              }
            >
              <Icon icon={"BellAlertIcon"} type={"outline"} />
            </MembersCardIcon>
            Not Started
          </MembersCardIconSelectContainer>
          <MembersCardIconSelectContainer
            onClick={() => setModalStatus("In Progress")}
            $statusColor={
              modalStatus === "In Progress" ? "In Progress" : "Disabled"
            }
          >
            <MembersCardIcon
              title={"In Progress"}
              $statusColor={
                modalStatus === "In Progress" ? "In Progress" : "Disabled"
              }
            >
              <Icon icon={"RocketLaunchIcon"} type={"outline"} />
            </MembersCardIcon>
            In Progress
          </MembersCardIconSelectContainer>
          <MembersCardIconSelectContainer
            onClick={() => setModalStatus("Done")}
            $statusColor={modalStatus === "Done" ? "Done" : "Disabled"}
          >
            <MembersCardIcon
              title={"Done"}
              $statusColor={modalStatus === "Done" ? "Done" : "Disabled"}
            >
              <Icon icon={"CheckCircleIcon"} type={"outline"} />
            </MembersCardIcon>
            Done
          </MembersCardIconSelectContainer>
          <MembersCardIconSelectContainer
            onClick={() => setModalStatus("Blocked")}
            $statusColor={modalStatus === "Blocked" ? "Blocked" : "Disabled"}
          >
            <MembersCardIcon
              title={"Blocked"}
              $statusColor={modalStatus === "Blocked" ? "Blocked" : "Disabled"}
            >
              <Icon icon={"NoSymbolIcon"} type={"outline"} />
            </MembersCardIcon>
            Blocked
          </MembersCardIconSelectContainer>
        </SelectContainerStatus>
        <ModalButtonsContainer>
          <div>
            {selectedIndex !== null && (
              <Button
                text="Delete"
                type="secondary"
                color="#f44336"
                onClick={() => deleteGoal()}
              />
            )}
          </div>
          <ModalButtonsContainerRight>
            <Button
              text={selectedIndex !== null ? "Edit" : "Add"}
              type="secondary"
              color="#048a24"
              onClick={() => {
                addOrEditGoal();
              }}
              disabled={newGoalsName === "" || newGoalsDescription === ""}
            />
            <Button
              text="Cancel"
              type="secondary"
              color="#f44336"
              onClick={() => {
                resetNewGoals();
              }}
            />
          </ModalButtonsContainerRight>
        </ModalButtonsContainer>
      </ModalDivConatiner>
    </Modal>
  );
});

FormModal.displayName = "FormModal";

export default FormModal;

import { Fragment } from 'react/jsx-runtime';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faWarning, 
  faCircleExclamation, 
  faCircleInfo,
  faFileCsv
} from '@fortawesome/free-solid-svg-icons';
import {
  Header,
  TitleContainer,
  Title,
  TableContainer,
  TableWrapper,
  Table,
  TableHead,
  TableBody,
  Th
} from './ClientDeliveryDashboard.styled';

const StyledIconWrapper = styled.span`
  margin-right: 0.375rem;
  height: 15px;
  width: 15px;
`;

export const IconButton = styled.div`
  width: 2rem;
  cursor: pointer;
`;

export const SectionContainer = styled.div`
  margin-top: 12px;
  min-width: 100%;
  background-color: white;
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

export const AlertIcon = ({ index }: { index: number }) => {
  const getIconProps = (index: number) => {
    switch (index) {
      case 0:
        return { icon: faWarning, color: 'rgb(255, 0, 0)' };
      case 1:
        return { icon: faWarning, color: 'rgb(255, 135, 46)' };
      case 2:
        return { icon: faCircleExclamation, color: 'rgb(247, 198, 4)' };
      case 3:
        return { icon: faWarning, color: 'rgb(247, 198, 4)' };
      case 4:
        return { icon: faWarning, color: 'rgb(105, 3, 117)' };
      case 5:
        return { icon: faWarning, color: 'rgb(50, 156, 173)' };
      default:
        return { icon: faCircleInfo, color: '#5FA5F9' };
    }
  };

  const { icon, color } = getIconProps(index);

  return (
    <StyledIconWrapper>
      <FontAwesomeIcon 
        icon={icon} 
        style={{ color }} 
        aria-hidden="true" 
      />
    </StyledIconWrapper>
  );
};


const Row = styled.tr`
  white-space: nowrap;
`;

const LabelCell = styled.td`
  padding: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #111827;
`;

const DataCell = styled.td`
  white-space: nowrap;
  padding: 1rem;
  font-size: 0.875rem;
  color: #6b7280;
  border-bottom: solid #F7F7F7;
`;

interface AlertRowProps {
  calc: {
    label: string;
    user: number;
    schedule: number;
    escalation: number;
    service: number;
    team: number;
  };
  index: number;
}
const Count = styled.span`
  font-weight: 500;
`;

export const AlertRow: React.FC<AlertRowProps> = ({ calc, index }) => (
  
  <Row>
    <LabelCell>{calc.label}</LabelCell>
    {Object.entries(calc)
      .filter(([key]) => key !== 'label')
      .map(([_, value], cellIndex) => (
        <DataCell key={cellIndex}>
          <AlertIcon index={index} />
          <Count>{value}</Count>
        </DataCell>
      ))}
  </Row>
);


const CustomerHeader = styled.th`
  padding: 0.5rem 0.875rem;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 600;
  color: #117170;
  background-color: rgba(18, 113, 112, 0.1);  // Lighter background
  border-top: 1px solid #e5e7eb;
  justify-content: space-between;
  align-items: center;
`;

const MonthHeader = styled.td`
  text-align: center;
  font-weight: 600;
  width: 100%;
  background-color: rgba(156, 163, 175, 0.2);
  padding: 0.5rem;
`;

const ExpandIcon = styled.svg`
  width: 20px;
  cursor: pointer;
`;

interface HealthCheckAlertsProps {
  showAlerts: boolean;
  setShowAlerts: (show: boolean) => void;
  alertsSummary: any[];
  isAlertExpanded: { [key: string]: boolean };
  setAlertExpanded: (customerName: string, expanded: boolean) => void;
}

export const HealthCheckAlerts: React.FC<HealthCheckAlertsProps> = ({
  showAlerts,
  setShowAlerts,
  alertsSummary,
  isAlertExpanded,
  setAlertExpanded
}) => (
  <SectionContainer>
    <Header>
      <TitleContainer>
        <Title>Health Check Alerts</Title>
      </TitleContainer>
      <IconButton onClick={() => setShowAlerts(!showAlerts)}>
        {showAlerts ? (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#117170">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#117170">
            <path
              fillRule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </IconButton>
    </Header>

    {alertsSummary.length > 0 && showAlerts && (
      <TableContainer>
        <TableWrapper>
          <Table>
            <TableHead>
              <tr>
                <Th></Th>
                <Th>User</Th>
                <Th>Schedule</Th>
                <Th>Escalation Policy</Th>
                <Th>Service</Th>
                <Th>Team</Th>
              </tr>
            </TableHead>
            <TableBody>
              {alertsSummary.map((alert) => (
                <Fragment key={alert.customer_name}>
                  <tr>
                  <CustomerHeader colSpan={6}>
                    <span>{alert.customer_name}</span>
                    <ExpandIcon
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      onClick={() => setAlertExpanded(alert.customer_name, !isAlertExpanded[alert.customer_name])}
                    >
                      <path
                        fillRule="evenodd"
                        d={isAlertExpanded[alert.customer_name]
                          ? "M14.77 12.79A.75.75 0 0114 13H6a.75.75 0 01-.53-1.28l4-4a.75.75 0 011.06 0l4 4a.75.75 0 01.24.53z"
                          : "M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        }
                      />
                    </ExpandIcon>
                  </CustomerHeader>
                  </tr>
                  {isAlertExpanded[alert.customer_name] && alert.data.map((monthData, idx) => (
                    <Fragment key={idx}>
                      <tr>
                        <MonthHeader colSpan={6}>
                          {monthData.month}
                        </MonthHeader>
                      </tr>
                      {monthData.calculations.map((calc, index) => (
                        <AlertRow 
                          key={index} 
                          calc={calc} 
                          index={index} 
                        />
                      ))}
                    </Fragment>
                  ))}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </TableContainer>
    )}
  </SectionContainer>
);
import { teamsTreeType } from "@/models";

export const teamsTreeMock = <teamsTreeType[]>[
    {
      name: "Brandon Colina",
      color: "#e09275",
      team: "Innovation",
      teamColor: "#e09275",
      teamMembers: [
        {
          name: "Amber Russek",
          color: "#e075c0",
          team: "Frontend",
          teamColor: "#e075c0",
          teamMembers: [
            {
              name: "Pamela McClure",
              color: "#53c98c",
              team: "Frontend",
              teamColor: "#e075c0",
              teamMembers: [],
            },
            {
              name: "Alexandra Hulme",
              color: "#6453c9",
              team: "Frontend",
              teamColor: "#e075c0",
              teamMembers: null,
            },
          ],
        },
      ],
    },
  ];
import React from 'react';
import { IconType } from './IconType.ts';
import outlineIconsLiteral from './outilineIcons.tsx';
import solidIconsLiteral from './solidIcons.tsx';

const Icon = ({ icon, type }: IconType) => {
  return type === 'outline'
    ? outlineIconsLiteral[icon]
    : solidIconsLiteral[icon];
};

Icon.displayName = 'Icon';

export default Icon;

import { useState, useEffect, useMemo } from 'react';
import { useGetQuery, useApiMutation } from '@/store/fetchClientInstance';
import { apiRoutes } from '@/services';
import { formatDay } from '@/utils/formatDate';

interface ServiceRequest {
  asana_sod_id: string;
  available_hours_percentage: number;
  closed_cases: number;
  customer_name: string;
  hours_available: number;
  hours_used: number;
  open_cases: number;
  percent_closed: number;
  percent_open: number;
  project_current_active_quarter_end_date: string;
  project_current_active_quarter_start_date: string;
  project_name: string;
  quarter_name: string;
  total_cases: number;
  used_hours_percentage: number;
}

const daysBetweenDates = (date1: Date, date2: Date): number => {
  const oneDay = 1000 * 60 * 60 * 24;
  const differenceMs = Math.abs(date1.getTime() - date2.getTime());
  return Math.ceil(differenceMs / oneDay);
};

const determineStatus = (days: number, hoursAvailable: number): string => {
  if (days >= 60) return '#048a24';
  if (days > 31 && hoursAvailable >= 30) return 'orange';
  if (days > 31 && hoursAvailable >= 20) return '#FACC14';
  if (days <= 31 && hoursAvailable < 20) return '#048a24';
  if (days <= 30 && hoursAvailable >= 30) return '#F77171';
  if (days <= 30 && hoursAvailable >= 20) return 'orange';
  if (days <= 30 && hoursAvailable >= 10) return '#FACC14';
  return '#048a24';
};

export const useServiceRequest = (selectedUser?: any) => {
  const isQuery = Boolean(selectedUser);

  const { 
    data: queryData, 
    isLoading: isLoadingQuery 
  } = useGetQuery({
    url: apiRoutes.getServiceRequestData,
    skip: isQuery
  });

  const [fetchData, { 
    data: mutationData, 
    isLoading: isLoadingMutation 
  }] = useApiMutation();

  useEffect(() => {
    if (selectedUser) {
      fetchData({
        url: `${apiRoutes.getServiceRequestData}/query`,
        config: {
          method: 'POST',
          body: selectedUser.length > 0 ? selectedUser : [selectedUser]
        }
      });
    }
  }, [selectedUser, fetchData]);

  const processedData = useMemo(() => {
    const data = isQuery ? mutationData : queryData;
    
    if (!data) return [];

    return data.map((item: ServiceRequest) => {
      const days = daysBetweenDates(
        new Date(item.project_current_active_quarter_end_date),
        new Date()
      );

      return {
        ...item,
        days_remaining: days,
        status: determineStatus(days, item.hours_available)
      };
    });
  }, [isQuery, mutationData, queryData]);

  return {
    serviceRequestData: processedData,
    isLoading: isQuery ? isLoadingMutation : isLoadingQuery,
    refetch: isQuery ? 
      () => fetchData({
        url: `${apiRoutes.getServiceRequestData}/query`,
        config: {
          method: 'POST',
          body: selectedUser.length > 0 ? selectedUser : [selectedUser]
        }
      }) : 
      undefined
  };
};
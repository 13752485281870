import React, { useState, useEffect } from "react";
import { Route, Routes} from 'react-router-dom';
import Jwt from "./utils/jwt";
import Paths from "./paths";
import {
  AddEditUser,
  Authorization,
  Board,
  CustomerSnapshots,
  CousesAndEvents,
  Dashboard,
  DeckGenerator,
  FAQ,
  Goals,
  HealthCheck,
  HealthCheckClient,
  HealthCheckDataViewer,
  Home,
  Login,
  Logout,
  MyAlertsApp,
  OnBoarding,
  PDUApp,
  Profile,
  PsStatus,
  Releases,
  SelectClient,
  SolutionGallery,
  SolutionSubCategory,
  SuccessOnDemand,
  Teams,
  Terraformer,
  Unauthorized,
  Users,
  V2Moms,
  Validate,
  ValidateOauth,
} from "./pages";
import PrivateRoute from "./PrivateRoutes";


const Auth = () => {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const tokenValue = Jwt.getItem("all");
    setLoading(true); 

    if (tokenValue !== null) {
      getMenu(tokenValue);
    } else {
      setLoading(false); 
    }
  }, []); 

  const getMenu = (tokenValue) => {
    let menu = tokenValue?.claims?.roles?.map((role) => {
      return role.apps.map((app) => {
        return app.app;
      });
    });

    setPermissions(menu[0] || []);
    setLoading(false); 
  };
  
  const checkIncludes = (page) => {
    return permissions.includes(page) || permissions.includes('Admin');
  };

  return (
    <div style={{ height: '100%' }}>
      <Routes>
        <Route element={<Login />} path={Paths.Login} exact />
        <Route element={<Logout />} path={Paths.Logout} exact />
        <Route element={<Unauthorized />} path={Paths.Unauthorized} exact />
        <Route element={<Validate />} path={Paths.Validate} exact />
        <Route exact path={Paths.SelectClient} element={<SelectClient />} />
        <Route element={<ValidateOauth />} path={Paths.ValidateOauth} exact />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
          <Route element={<FAQ />} path={Paths.FAQ} exact />
          <Route exact path={Paths.AddUser} element={<AddEditUser />} />
          <Route exact path={Paths.Authorization} element={<Authorization />} />
          <Route exact path={Paths.CustomerSnapshots} element={<CustomerSnapshots />} />
          <Route exact path={Paths.ManagementCoursesAndEvents} element={<CousesAndEvents />} />
          <Route exact path={Paths.DeckGenerator} element={<DeckGenerator />} />
          <Route exact path={Paths.EditUser} element={<AddEditUser />} />
          <Route exact path={Paths.HealthCheck} element={<HealthCheck />} />
          <Route exact path={Paths.HealthCheckClient} element={<HealthCheckClient />} />
          <Route exact path={Paths.HealthCheckDataViewer} element={<HealthCheckDataViewer />} />
          <Route exact path={Paths.Home} element={<Home />} />
          <Route exact path={Paths.Management} element={<Dashboard />} />
          <Route exact path={Paths.ManagementBoard} element={<Board />} />
          <Route exact path={Paths.ManagementDashboard} element={<Dashboard />} />
          <Route exact path={Paths.ManagementGoals} element={<Goals />} />
          <Route exact path={Paths.ManagementReleases} element={<Releases />} />
          <Route exact path={Paths.ManagementTeams} element={<Teams />} />
          <Route exact path={Paths.ManagementV2Moms} element={<V2Moms />} />
          <Route exact path={Paths.MyAlertsApp} element={<MyAlertsApp />} />
          <Route exact path={Paths.OnBoarding} element={<OnBoarding />} />
          <Route exact path={Paths.PDUApp} element={<PDUApp />} />
          <Route exact path={Paths.Profile} element={<Profile />} />
          <Route exact path={Paths.PsStatus} element={<PsStatus />} />
          <Route exact path={Paths.SolutionGallery} element={<SolutionGallery />} />
          <Route exact path={Paths.SolutionSubCategory} element={<SolutionSubCategory />} />
          <Route exact path={Paths.SuccessOnDemand} element={<SuccessOnDemand />} />
          <Route exact path={Paths.Terrafomer} element={<Terraformer />} />
          <Route exact path={Paths.Users} element={<Users />} />
          <Route exact path="/*" element={<Home />} />
        </Route>
      </Routes>
    </div>
  );
}

// Move the export statement outside of the Auth component definition
export default Auth;

import { useRive, useStateMachineInput } from '@rive-app/react-canvas';
import loadingpagey from './loadingpagey.riv';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { LoadingPageyType } from './LoadingPageyType';

const LoadingPagey = forwardRef(({ state }: LoadingPageyType, Ref) => {
  useImperativeHandle(Ref, () => ({ state }));
  const { rive, RiveComponent } = useRive({
    src: loadingpagey,
    stateMachines: 'State Machine 1',
    autoplay: true,
    onStateChange: () => {},
  });

  const stateInput = useStateMachineInput(rive, 'State Machine 1', 'state');

  useEffect(() => {
    if (stateInput) {
      stateInput.value = state === 'Success' ? 1 : state === 'Error' ? 2 : 0;
    }
  }, [stateInput, state]);

  return <RiveComponent style={{ width: '600px', height: '400px' }} />;
});

LoadingPagey.displayName = 'LoadingPagey';

export default LoadingPagey;

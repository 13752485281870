import { forwardRef, useImperativeHandle } from "react";
import { ProgressBarType } from "./ProgressBar.model.ts";
import { Progress, ProgressBarContainer } from "./ProgressBar.styled.tsx";

const ProgressBar = forwardRef(({ percentage }: ProgressBarType, ref) => {
  useImperativeHandle(ref, () => ({}));
  return (
    <ProgressBarContainer>
      <Progress percentage={percentage} />
    </ProgressBarContainer>
  );
});

ProgressBar.displayName = "ProgressBar";

export default ProgressBar;

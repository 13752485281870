import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { cleanedURL } from '../services/api-instance';
import type { InstanceHeaders } from '../services/api-types';

export interface QueryParams {
  url: string;
  params?: Record<string, any>;
  config?: {
    newDomain?: boolean;
    instanceHeaders?: InstanceHeaders;
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    body?: any;
  };
}

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: '',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem('token');
      
      if (token) {
        headers.set('Authorization', `CsgPDBearer ${token}`);
      }
      
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');
      
      return headers;
    },
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    get: builder.query<any, QueryParams>({
      query: ({ url, params, config }) => {
        const headers: Record<string, string> = {};
        if (!config?.newDomain && config?.instanceHeaders) {
          const { instanceId, instanceCompositeUrl, pathName } = config.instanceHeaders;
          if (instanceId) headers['instance-id'] = instanceId;
          if (instanceCompositeUrl) headers['instance-composite-url'] = instanceCompositeUrl;
          if (pathName) headers['path_name'] = pathName;
        }

        const fullUrl = cleanedURL({ url, newDomain: config?.newDomain });

        return {
          url: fullUrl,
          method: config?.method || 'GET',
          params,
          headers,
        };
      }
    }),
   api: builder.mutation<any, QueryParams>({
      query: ({ url, params, config }) => {
        const headers: Record<string, string> = {};
        if (!config?.newDomain && config?.instanceHeaders) {
          const { instanceId, instanceCompositeUrl, pathName } = config.instanceHeaders;
          if (instanceId) headers['instance-id'] = instanceId;
          if (instanceCompositeUrl) headers['instance-composite-url'] = instanceCompositeUrl;
          if (pathName) headers['path_name'] = pathName;
        }

        return {
          url: cleanedURL({ url, newDomain: config?.newDomain }),
          method: config?.method || 'POST',
          params,
          headers,
          body: config?.body,
        };
      },
    }),
  }),
});

// Export the hooks
export const { useGetQuery, useApiMutation } = baseApi;
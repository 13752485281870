import React, { useEffect, useState } from "react";
import {
  LoginPanel,
  Logo,
  LoginContainer,
  LoginForm,
  Divider,
  LoginDescription,
  LoginGreetings,
  LoginButton,
  LoginInfoFooter,
  LoginInfoFooterLink,
  CarouselDiv,
  NewsSection,
  NewsContainer,
  NewsTitle,
  InnerContainer,
  InnerContainerList,
  InnerContainerTitle,
  NewsDescription,
} from "./Login.styled";
import LogoSrc from "@/assets/images/innovation-logo.png";
import { useLogin, useLoginOauth } from "@/query/Login";
import { bannerSubject } from "@/services/subjects";
import { useFeatureFlags } from "@/query/Login/useFeatureFlags";
import Icon from "@/components/Icon";
import Alerts from "@/components/Alerts";
import { Alert } from "@/components/Alerts/Alerts.model";
import Carousel from "@/components/Carousel";
import Checkbox from "@/components/Checkbox";

const Login = () => {
  const { data: loginData, error: loginError } = useLogin();
  const { data: oauthData, error: oauthError } = useLoginOauth();
  const { data: featureFlags } = useFeatureFlags();

  const [alerts, setAlerts] = useState<Alert[]>([]);

  const handleLogin = () => {
    if (!loginError && loginData) {
      window.location.href = loginData.url;
    }
  };

  const handleOauthLogin = () => {
    if (!oauthError && oauthData) {
      window.location.href = oauthData.url;
    }
  };

  useEffect(() => {
    if (featureFlags && featureFlags.portal_maintenance && featureFlags.portal_alert) {
      setAlerts([
        {
          type: "warning",
          text: featureFlags.portal_alert,
        },
      ]);
      bannerSubject.next({
        message: featureFlags.portal_alert,
        type: "error",
        icon: <Icon icon={"AcademicCapIcon"} type={"outline"} />,
      });
    } else {
      bannerSubject.next(null);
    }
  }, [featureFlags]);


  return (
    <LoginContainer>
      <LoginForm>
        <Logo src={LogoSrc} />
        <LoginGreetings>
          Welcome to the CSG Premium Service Portal
          <LoginDescription>Sign in to your account</LoginDescription>
        </LoginGreetings>
        <LoginButton onClick={() => {handleOauthLogin()}}>Premium Customer</LoginButton>
        <Divider>or</Divider>
        <LoginButton onClick={() => {handleLogin()}}>Dutonian</LoginButton>
        <LoginInfoFooter>
          Problems?&nbsp;
          <LoginInfoFooterLink
            href="mailto:CSG-Innovation@pagerduty.com"
            target="_blank"
          >
            Contact Support
          </LoginInfoFooterLink>
        </LoginInfoFooter>
        <LoginDescription>
          Copyright © {new Date().getFullYear()} Csg Innovation Services -
          Pagerduty, Inc. All rights reserved.
        </LoginDescription>
      </LoginForm>
      <LoginPanel>
        <Alerts
          alerts={alerts}
          onRemove={(index: number) =>
            setAlerts(alerts.filter((_, i) => i !== index))
          }
        />
        <CarouselDiv>
          <Carousel interval={7000} height="100%">
            {featureFlags?.release_info?.map((news, index) => (
              <NewsContainer key={index}>
                <NewsSection>
                  <NewsTitle>{news.title}</NewsTitle>
                  {news.items.map((item, index) => (
                    <InnerContainer key={index}>
                      {item.title && (
                        <InnerContainerTitle>{item.title}</InnerContainerTitle>
                      )}
                      <InnerContainerList>
                        {item.item.map((subItem) => (
                          <Checkbox
                            key={subItem}
                            handleClick={() => {}}
                            checked={true}
                            label={subItem}
                            disabled={true}
                          />
                        ))}
                      </InnerContainerList>
                    </InnerContainer>
                  ))}
                </NewsSection>
                <NewsSection>
                  <NewsDescription>{news.description}</NewsDescription>
                </NewsSection>
              </NewsContainer>
            ))}
          </Carousel>
        </CarouselDiv>
      </LoginPanel>
    </LoginContainer>
  );
};

export default Login;

import styled from "styled-components";
import { IconStatusType } from "@/components/Icon/IconType";

export const MemberCardActionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: #117170;
  & svg {
    width: 20px;
    height: 20px;
  }
`;

interface MembersCardInterface {
  $isSuperior: boolean;
}

export const MembersCard = styled.div<MembersCardInterface>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: ${(props) => (props.$isSuperior ? "#E5F2E9" : "#FFFFFF")};
`;

interface MembersCardIconInterface {
  $statusColor: IconStatusType;
}

export const MembersCardIcon = styled.div<MembersCardIconInterface>`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: ${(props) => {
    switch (props.$statusColor) {
      case "In Progress":
        return "#e0bd00";
      case "Not Started":
        return "#117170";
      case "Done":
        return "#005A24";
      case "Blocked":
        return "#ce0000";
      case "Disabled":
        return "#8f8f8f";
      default:
        return "#FFFFFF";
    }
  }};
  color: white;
  border-radius: 8px;
  & svg {
    width: 60px;
    height: 60px;
  }
`;

export const MembersCardInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const MemberCardTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  background-color: #117170;
`;

export const MemberCardDescription = styled.div`
  font-size: 14px;
  margin: 8px 0;
  color: #313131;
`;

export const MemberCardStatus = styled.div`
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-self: flex-end;
  gap: 10px;
  & svg {
    width: 20px;
    height: 20px;
  }
`;

export const MemberCardTopDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const MembersCardIconSelectContainer = styled.div<MembersCardIconInterface>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  flex-direction: column;
  color: ${(props) => {
    switch (props.$statusColor) {
      case "In Progress":
        return "#e0bd00";
      case "Not Started":
        return "#117170";
      case "Done":
        return "#005A24";
      case "Blocked":
        return "#ce0000";
      case "Disabled":
        return "#8f8f8f";
      default:
        return "#FFFFFF";
    }
  }};
`;

export const MemberCardRelation = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
`;

export const MemberCardRelationText = styled.div<MembersCardIconInterface>`
  color: #313131;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  color: ${(props) => {
    switch (props.$statusColor) {
      case "In Progress":
        return "#e0bd00";
      case "Not Started":
        return "#117170";
      case "Done":
        return "#005A24";
      case "Blocked":
        return "#ce0000";
      case "Disabled":
        return "#8f8f8f";
      default:
        return "#FFFFFF";
    }
  }};
  & svg {
    width: 14px;
    height: 14px;
  }
`;

export const MemberCardRelationLabel = styled.div`
  color: #313131;
  font-size: 16px;
  font-weight: 600;
`;

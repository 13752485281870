import { navigationParamsType } from "@/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const EmptyState: navigationParamsType = {};

export const navigationParamsSlice = createSlice({
  name: "navigationParams",
  initialState: EmptyState,
  reducers: {
    setNavigationParams: (state, action: PayloadAction<navigationParamsType>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearNavigationParams: () => EmptyState,
  },
});

export const { setNavigationParams, clearNavigationParams } = navigationParamsSlice.actions;
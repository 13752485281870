import React, { useState, useEffect } from 'react';
import './FeatureModal.css';

const FeatureModal = ({
  subdomain,
  email,
  service,
  onFormDataChange,
  initialFormData,
}) => {
  const [formData, setFormData] = useState(initialFormData);
  const [isValid, setIsValid] = useState(false);

  const isValidField = (field) =>
    typeof field === 'string' && field.trim() !== '';

  const requiredFields = ['email', 'subject', 'description', 'useCase'];
  const allFields = [...requiredFields, 'subdomain'];
  const descriptionPlaceholder =
    'Why it’s important: A clear description helps us understand exactly what you need, ensuring we capture your idea accurately.';
  const useCasePlaceholder =
    'Why it’s important: Knowing how you plan to use the feature helps us design it to fit your needs. Understanding the business impact allows us to prioritize features that can significantly enhance your operations or customer satisfaction.';

  const valid = requiredFields.every((field) => isValidField(formData[field]));
  const subDomainExists = allFields.every((field) =>
    isValidField(formData[field])
  );

  const validateForm = () => {
    if (subdomain !== undefined || subdomain !== null) {
      setIsValid(valid);
    } else {
      setIsValid(subDomainExists);
    }
  };

  useEffect(() => {
    validateForm();
    onFormDataChange(formData);
  }, [formData, onFormDataChange]);

  useEffect(() => {
    const newFormData = {
      email,
      subject: formData.subject || '',
      description: formData.description || '',
      useCase: formData.useCase || '',
    };

    if (subdomain != null) {
      newFormData.subdomain = subdomain;
    }

    setFormData(newFormData);
  }, [
    subdomain,
    email,
    formData.subject,
    formData.description,
    formData.useCase,
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <section>
      <section className='premium-support-request__modal-form'>
        <section className='premium-support-request__modal-col2'>
          <div className='premium-support-request__modal-group'>
            <label
              htmlFor='subdomain'
              className='premium-support-request__modal-label'
            >
              Subdomain
            </label>
            <input
              id='subdomain'
              name='subdomain'
              className='premium-support-request__modal-input'
              type='text'
              value={subdomain || ''}
              disabled
            />
          </div>
          <div className='premium-support-request__modal-group'>
            <label
              htmlFor='email'
              className='premium-support-request__modal-label'
            >
              Email
            </label>
            <input
              id='email'
              name='email'
              className='premium-support-request__modal-input'
              type='email'
              value={email || ''}
              disabled
            />
          </div>
        </section>
        <section className='premium-support-request__modal-col1'>
          <div className='premium-support-request__modal-group'>
            <label
              htmlFor='subject'
              className='premium-support-request__modal-label'
            >
              Request Subject{' '}
              <span className='fw-light'>
                <em>(Required)</em>
              </span>
            </label>
            <input
              id='subject'
              name='subject'
              className='premium-support-request__modal-input'
              type='text'
              value={formData.subject || ''}
              onChange={handleInputChange}
            />
          </div>
        </section>
        <section className='premium-support-request__modal-col1'>
          <div className='premium-support-request__modal-group'>
            <label
              htmlFor='description'
              className='premium-support-request__modal-label'
            >
              Feature Description{' '}
              <span className='fw-light'>
                <em>(Required)</em>
              </span>
            </label>
            <textarea
              placeholder={descriptionPlaceholder}
              id='description'
              name='description'
              className='premium-support-request__modal-textarea'
              rows={10}
              value={formData.description || ''}
              onChange={handleInputChange}
            />
          </div>
        </section>
        <section className='premium-support-request__modal-col1'>
          <div className='premium-support-request__modal-group'>
            <label
              htmlFor='useCase'
              className='premium-support-request__modal-label'
            >
              Use Case and Business Impact{' '}
              <span className='fw-light'>
                <em>(Required)</em>
              </span>
            </label>
            <textarea
              placeholder={useCasePlaceholder}
              id='useCase'
              name='useCase'
              className='premium-support-request__modal-textarea'
              rows={10}
              value={formData.useCase || ''}
              onChange={handleInputChange}
            />
          </div>
        </section>
      </section>
    </section>
  );
};

export default FeatureModal;

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 10px;
`;

export const ColumnContainer = styled.div`
  flex: 1;
`;

interface ColumnHeaderInterface {
  $color?: string;
}

export const ColumnHeader = styled.div<ColumnHeaderInterface>`
  border-radius: 8px;
  opacity: 1;
  font-size: 16px;
  border: 1px solid ${(props) => props.$color || "#048a24"};
  padding: 8px 16px;
  color: ${(props) => props.$color || "#048a24"};
  text-align: center;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  background-color: transparent;
`;

export const Column = styled.div`
  padding: 10px;
  height: 100%;
  margin-top: 1px;
  border-radius: 5px;
  min-height: 200px;
  transition: background-color 0.2s ease-in-out;

  &.over {
    background-color: #d0d0d0;
  }
`;

export const Item = styled.div`
  padding: 10px;
  margin: 5px 0;
  background-color: #76c7c0;
  border-radius: 3px;
  width: 100%;
  transition: background-color 0.2s ease-in-out;
`;

export const ItemGrabbing = styled.div`
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    cursor: grabbing;
  }
`;

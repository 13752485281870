import styled from "styled-components";

export const QuarterContainer = styled.div`
  display: flex;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 16px 0px;
  width: 100%;
  max-width: 500px;
`;

interface QuarterInterface {
  $isSelected: boolean;
}

export const Quarter = styled.div<QuarterInterface>`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${(props) => (props.$isSelected ? "white" : "black")};
  background-color: ${(props) => (props.$isSelected ? "#005A24" : "white")};
  &:hover {
    background-color: ${(props) =>
      props.$isSelected ? "#005A24" : "lightgray"};
  }
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &:nth-child(2) {
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
  }
  &:nth-child(3) {
    border-right: 1px solid #e0e0e0;
  }
`;

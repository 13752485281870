import { userInfoType } from "@/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const EmptyState: userInfoType = {};

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: EmptyState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<userInfoType>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearUserInfo: () => EmptyState,
  },
});

export const { setUserInfo, clearUserInfo } = userInfoSlice.actions;
import React, { useState, useEffect } from 'react';
import { DatePicker } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';
import './ServiceModal.css';
import { CustomSelect, LabelCustomSelect } from '../../CustomSelect';
import MultiSelectPill from '../../MultiSelectPill/MultiSelectPill';

const ServiceModal = ({ onFormDataChange, initialFormData }) => {
  const [formData, setFormData] = useState(() => ({
    timeline: '',
    requestTypes: [],
    details: '',
    meetingDates: [],
    ...initialFormData,
  }));

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    validateForm();
    onFormDataChange(formData);
  }, [formData, onFormDataChange]);

  const validateForm = () => {
    const valid =
      formData.timeline !== '' &&
      Array.isArray(formData.requestTypes) &&
      formData.requestTypes.length > 0 &&
      formData.details &&
      formData.details.trim() !== '' &&
      Array.isArray(formData.meetingDates) &&
      formData.meetingDates.length > 0;
    setIsValid(valid);
  };

  const handleInputChange = (name, value) => {
    setFormData((prev) => {
      if (name === 'consultant' && value === '') {
        const { consultant, ...rest } = prev;
        return rest;
      }

      return { ...prev, [name]: value };
    });
  };

  const handleDateChange = (date) => {
    if (
      date &&
      Array.isArray(formData.meetingDates) &&
      formData.meetingDates.length < 3
    ) {
      const newDates = [...formData.meetingDates, date];
      setFormData((prev) => ({ ...prev, meetingDates: newDates }));
    }
  };

  const handleRequestTypesChange = (selected) => {
    setFormData((prev) => ({ ...prev, requestTypes: selected }));
  };

  const removeDate = (index) => {
    if (Array.isArray(formData.meetingDates)) {
      const newDates = formData.meetingDates.filter((_, i) => i !== index);
      setFormData((prev) => ({ ...prev, meetingDates: newDates }));
    }
  };

  const timelineOptions = [
    'No specific timeline',
    '1-3 Business days',
    '3-5 Business days',
    '5-7 Business days',
    '7-10 Business days',
    '10+ Business days',
  ];

  const consultants = [
    'Select Consultant',
    'Alejandro Spuler',
    'Alexis Acevedo',
    'Alexis Muñoz',
    'Amanda Veras',
    'Carmen Retamal',
    'Celeste Vega',
    'Dan Draper',
    'David Rodriguez',
    'Diego Cid',
    'Dutch Rapley',
    'Eddie Willits',
    'Felipe Onituka',
    'Felipe Polhwein',
    'Gavin Reynolds',
    'Gonzalo Robles',
    'Ignacio Garces',
    'Jorge Spuler',
    'José Reyes',
    'Lucas Coelho',
    'Loren Pearce',
    'Manuel Solis',
    'Matheus Garmatz',
    'Pablo Retes',
    'Patricio Cordero',
    'Raphael Souza',
    'Robinson Navas',
    'Rodrigo Morales',
    'Rodrigo Muñoz',
    'Ryan Derobertis',
    'Sebastian Bello',
    'Sebastian Villanelo',
    'Tom Bryant',
    'Victor Orozco',
  ];

  const requestTypes = [
    'Best Practices',
    'Consulting/Advisement',
    'Training/Enablement',
    'Team Optimization Session',
    'Services Optimization',
  ];

  return (
    <section>
      <section className='premium-support-request__modal-form'>
        <div className='premium-support-request__modal-group'>
          <CustomSelect
            label='Work Request Timeline'
            options={timelineOptions}
            placeholder='Select Timeline'
            style='default'
            onChange={(selected) => handleInputChange('timeline', selected)}
            value={formData.timeline || ''}
            tooltipContent={
              <ul>
                <li>
                  Kindly share your desired completion date. Please note that
                  requests will be addressed based on consultant availability.
                  Suggested urgency and timeline guidelines:
                </li>

                <li>
                  -{' '}
                  <span
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    Critical Urgency
                  </span>
                  : 1-3 Business Days.
                </li>
                <li>
                  -{' '}
                  <span
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    Major Urgency
                  </span>
                  : 3-5 Business Days.
                </li>
                <li>
                  -{' '}
                  <span
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    Minor Urgency
                  </span>
                  : 5-7 Business Days (ideal urgency and timeline).
                </li>
                <li>
                  -{' '}
                  <span
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    Normal Urgency
                  </span>
                  : 7-10 Business Days (ideal urgency and timeline).
                </li>
                <li>
                  -{' '}
                  <span
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    Not Urgent
                  </span>
                  : 10+ Business Days.
                </li>
              </ul>
            }
            isRequired
          />
        </div>
        <section className='premium-support-request__modal-col1'>
          <div className='premium-support-request__modal-group'>
            <LabelCustomSelect
              label=' Work Request Type'
              isRequired
            />

            <MultiSelectPill
              options={requestTypes}
              selected={formData.requestTypes || []}
              onChange={handleRequestTypesChange}
            />
          </div>
        </section>
        <section className='premium-support-request__modal-col1'>
          <div className='premium-support-request__modal-group'>
            <LabelCustomSelect
              label='Work Request Details and Additional Context'
              isRequired
            />

            <textarea
              className='premium-support-request__modal-textarea'
              value={formData.details || ''}
              onChange={(e) => handleInputChange('details', e.target.value)}
              rows={6}
            />
          </div>
        </section>
        <section className='premium-support-request__modal-col1_dates'>
          <div className='premium-support-request__modal-group'>
            <LabelCustomSelect
              label='Customer Follow Up Meeting Options'
              isRequired
            />
            <DatePicker
              placement='autoVerticalStart'
              format='MM/dd/yyyy hh:mm aa'
              onChange={handleDateChange}
              disabledDate={(date) => date && date.getTime() < Date.now()}
              showMeridian
              style={{ width: '100%' }}
              disabled={
                Array.isArray(formData.meetingDates) &&
                formData.meetingDates.length >= 3
              }
              placeholder={
                Array.isArray(formData.meetingDates) &&
                formData.meetingDates.length >= 3
                  ? 'Max selections reached'
                  : 'Select Meeting Date & Time'
              }
            />
          </div>
          <div className='selected-dates'>
            {Array.isArray(formData.meetingDates) &&
              formData.meetingDates.map((date, index) => (
                <div
                  key={index}
                  className='selected-date-chip'
                >
                  {date.toLocaleString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                  })}
                  <button
                    onClick={() => removeDate(index)}
                    className='remove-date-btn'
                  >
                    ×
                  </button>
                </div>
              ))}
          </div>
        </section>
        <section
          className='premium-support-request__modal-col1'
          style={{
            paddingBottom: 120,
          }}
        >
          <div className='premium-support-request__modal-group'>
            <CustomSelect
              label='Consultant'
              options={consultants}
              placeholder='Select consultant'
              style='consultant'
              onChange={(selected) => {
                if (selected === 'Select Consultant') {
                  selected = '';
                }

                handleInputChange('consultant', selected);
              }}
              value={formData.consultant || ''}
              tooltipContent='You have the option to select a consultant of your preference, though this is not required.'
              isOptional
            />
          </div>
        </section>
      </section>
    </section>
  );
};

export default ServiceModal;

import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import {
  NodeType,
  reactFlowEdgeType,
  reactFlowNodeType,
  TreeNodeType,
} from "./TreeNodeType";
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Controls,
  ReactFlow,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { getNodeDimensions, nodeTypes } from "./nodeTypes";

const TreeNode = forwardRef(
  ({ nodes: propNodes, onClick }: TreeNodeType, ref) => {
    const [nodes, setNodes] = useState<reactFlowNodeType[]>([]);
    const [edges, setEdges] = useState<reactFlowEdgeType[]>([]);
    useImperativeHandle(ref, () => ({}));

    const onNodesChange = useCallback(
      (changes: any) => setNodes((nds) => applyNodeChanges(changes, nds)),
      [],
    );
    const onEdgesChange = useCallback(
      (changes: any) => setEdges((eds) => applyEdgeChanges(changes, eds)),
      [],
    );

    const onConnect = useCallback(
      (connection: any) =>
        setEdges((eds) => addEdge({ ...connection, type: "step" }, eds)),
      [setEdges],
    );

    const getMaxDimensions = (nodes: NodeType[]) => {
      const maxDimensions = { width: 0, height: 0 };
      nodes.forEach((node) => {
        const dimensions = getNodeDimensions(node.type);
        maxDimensions.width = Math.max(maxDimensions.width, dimensions.width);
        maxDimensions.height = Math.max(
          maxDimensions.height,
          dimensions.height,
        );
      });
      return maxDimensions;
    };

    const getColumnDimensions = (
      maxDimensions: { height: number; width: number },
      padding: number,
    ) => {
      return {
        width: maxDimensions.width + padding,
        height: maxDimensions.height + padding,
      };
    };

    const globalYRef = useRef(0);

    const flapMapNodes = useCallback(
      (
        node: NodeType,
        depth: number,
        columnDimensions: { height: number; width: number },
        nodePositions: reactFlowNodeType[],
        edgesArray: reactFlowEdgeType[],
        parentId?: string,
      ): { x: number; y: number } => {
        const x = depth * columnDimensions.width;
        const nodeId = uuidv4();

        if (!node.teamMembers || node.teamMembers.length === 0) {
          const y = globalYRef.current * columnDimensions.height;
          globalYRef.current += 1;

          nodePositions.push({
            id: nodeId,
            data: { ...node, onClick: onClick },
            position: { x, y },
            type: node.type || "ProfileCircle",
          });

          if (parentId) {
            edgesArray.push({
              id: `${parentId}-${nodeId}`,
              source: parentId,
              target: nodeId,
              sourceHandle: "b",
              targetHandle: "a",
              type: "step",
            });
          }

          return { x, y };
        } else {
          const childPositions: { x: number; y: number }[] = [];
          node.teamMembers.forEach((child) => {
            const childPos = flapMapNodes(
              child,
              depth + 1,
              columnDimensions,
              nodePositions,
              edgesArray,
              nodeId,
            );
            childPositions.push(childPos);
          });

          const firstChildY = childPositions[0].y;
          const lastChildY = childPositions[childPositions.length - 1].y;
          const y = (firstChildY + lastChildY) / 2;

          nodePositions.push({
            id: nodeId,
            data: { ...node, onClick: onClick },
            position: { x, y },
            type: node.type || "ProfileCircle",
          });

          if (parentId) {
            edgesArray.push({
              id: `${parentId}-${nodeId}`,
              source: parentId,
              target: nodeId,
              sourceHandle: "b",
              targetHandle: "a",
              type: "step",
            });
          }

          return { x, y };
        }
      },
      [onClick],
    );

    useEffect(() => {
      globalYRef.current = 0;
      const maxDimensions = getMaxDimensions(propNodes);
      const columnDimensions = getColumnDimensions(maxDimensions, 100);

      const nodePositions: reactFlowNodeType[] = [];
      const edgesArray: reactFlowEdgeType[] = [];

      propNodes.forEach((node) => {
        flapMapNodes(node, 0, columnDimensions, nodePositions, edgesArray);
      });

      setNodes(nodePositions);
      setEdges(edgesArray);
    }, [propNodes, flapMapNodes]);

    return (
      <div style={{ height: "100%", width: "100%" }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          proOptions={{ hideAttribution: true }}
        >
          <Controls />
        </ReactFlow>
      </div>
    );
  },
);

TreeNode.displayName = "TreeNode";

export default TreeNode;

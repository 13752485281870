import styled from "styled-components";

export const TaskContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin: 5px 0px;
  gap: 5px;
  width: 100%;
`;

export const TopDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
`;

export const BottomDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 5px;
`;

type InitialsContainerInterface = {
  color: string;
};

export const InitialsContainer = styled.div<InitialsContainerInterface>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  border: 2px solid #e5e7eb;
  box-shadow: 0 0 0 2px #8f8f8f;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
`;

export const AssignedDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

export const TaskCardDescription = styled.div`
  margin: 10px 0px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 100%;
`;

export const StageDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

export const StageLabel = styled.div`
  font-weight: bold;
`;

export const StageName = styled.div``;

export const FeatureDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

export const FeatureLabel = styled.div`
  font-weight: bold;
`;

export const FeatureName = styled.div``;

export const TaskInfoDiv = styled.div`
  width: 100%;
  padding: 10px;
  border: 1px solid grey;
  margin: 10px;
  border-radius: 5px;
  font-size: 12px;
  gap: 5px;
  display: flex;
  justify-content: space-between;
`;

import { createSelector } from "@reduxjs/toolkit";
import { RootState, useAppSelector } from "@/store";

const sessionInfoSelector = (state: RootState) => state.sessionInfo;

const memoizedSelector = createSelector(sessionInfoSelector, (state) => {
  return state;
});

export const useSessionInfoSelector = () => useAppSelector(memoizedSelector);

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import {
  Header,
  TitleContainer,
  Title,
  TableContainer,
  TableWrapper,
  Table,
  TableHead,
  TableBody,
  Th,
  Td
} from './ClientDeliveryDashboard.styled';

export const SectionContainer = styled.div`
  margin-top: 12px;
  min-width: 100%;
  background-color: white;
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;


export const IconButton = styled.div`
  width: 2rem;
  cursor: pointer;
`;

const CustomerCell = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

const CustomerName = styled.div`
  margin-top: 4px;
  font-weight: 500;
  color: #1f2937;
  padding: 0.5rem;
`;

const CenterCell = styled(Td)`
  text-align: center;
`;

interface PremiumSupportProps {
  showPremiumSupport: boolean;
  setShowPremiumSupport: (show: boolean) => void;
  premiumSupport: any[];
}

export const PremiumSupport: React.FC<PremiumSupportProps> = ({
  showPremiumSupport,
  setShowPremiumSupport,
  premiumSupport,
}) => (
  <SectionContainer>
    <Header>
      <TitleContainer>
        <Title>Premium Support</Title>
      </TitleContainer>
      <IconButton onClick={() => setShowPremiumSupport(!showPremiumSupport)}>
        {showPremiumSupport ? (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#117170">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#117170">
            <path
              fillRule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </IconButton> 
    </Header>

    {premiumSupport.length > 0 && showPremiumSupport && (
      <TableContainer>
        <TableWrapper>
          <Table>
            <TableHead>
              <tr>
                <Th>Name</Th>
                <Th align="center">Open Cases</Th>
                <Th align="center">Closed</Th>
                <Th align="center">Total Cases</Th>
              </tr>
            </TableHead>
            <TableBody>
              {premiumSupport.map((item) => (
                <tr key={item.subdomain}>
                  <Td>
                    <CustomerCell>
                      <CustomerName>{item.customer_name}</CustomerName>
                    </CustomerCell>
                  </Td>
                  <CenterCell>
                    <div>{item.open_cases}</div>
                  </CenterCell>
                  <CenterCell>
                    <div>{item.closed_cases}</div>
                  </CenterCell>
                  <CenterCell>{item.total_cases}</CenterCell>
                </tr>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </TableContainer>
    )}
  </SectionContainer>
);
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ContainerCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ContainerCardsUpper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const PeopleOnMyTeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const PeopleOnMyTeamCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0px;
  gap: 20px;
`;

export const NothingHere = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0px;
  gap: 20px;
  height: 142px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const MemberCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0px;
  gap: 20px;
`;

export const NoDoneStatusTexy = styled.span`
  color: #005a24;
`;

export const TopContainer = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
`;

import { forwardRef, useImperativeHandle, useState } from "react";
import { PDInput, Icon, Modal, Dropdown, Button } from "@/components";
import {
  MembersCardIconSelectContainer,
  MembersCardIcon,
} from "../MemberCard/MemberCard.styled";
import {
  ModalDivConatiner,
  ModalSpan,
  SelectContainerStatus,
  ModalButtonsContainer,
  ModalButtonsContainerRight,
} from "./FormModal.styled";
import { useDispatch } from "react-redux";
import { formModalType, statusType, v2MomType, v2MomTypeArray } from "@/models";
import { deepCopy } from "@/utils/shared-functions";
import { setUserManagementInfo, useSuperiorInfoSelector, useUserManagementInfoSelector } from "@/store/states";

const FormModal = forwardRef(({ currentQuarter }: formModalType, ref) => {
  const dispatch = useDispatch();
  const userInfo = useUserManagementInfoSelector();
  const superiorInfo = useSuperiorInfoSelector();
  const [modalStatus, setModalStatus] = useState<statusType>("Not Started");
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [newV2MomName, setNewV2MomName] = useState<string>("");
  const [newV2MomDescription, setNewV2MomDescription] = useState<string>("");
  const [newV2MomRelation, setNewV2MomRelation] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    addNewV2Mom,
    editV2Mom,
  }));

  const addNewV2Mom = () => {
    setOpenModal(true);
  };

  const editV2Mom = (index: number, v2Mom: v2MomType) => {
    setSelectedIndex(index);
    setNewV2MomName(v2Mom.name);
    setNewV2MomDescription(v2Mom.description);
    setModalStatus(v2Mom.status);
    setNewV2MomRelation(v2Mom.relation || "");
    setOpenModal(true);
  };

  const resetNewV2Mom = () => {
    setOpenModal(false);
    setNewV2MomName("");
    setNewV2MomDescription("");
    setNewV2MomRelation("");
    setModalStatus("Not Started");
    setSelectedIndex(null);
  };

  const addOrEditV2Moms = () => {
    const userInfoCopy = deepCopy(userInfo);
    if (selectedIndex === null) {
      userInfoCopy.v2Moms[currentQuarter].push({
        name: newV2MomName,
        status: modalStatus,
        description: newV2MomDescription,
        relation: newV2MomRelation,
      });
    } else if (selectedIndex !== null) {
      userInfoCopy.v2Moms[currentQuarter][selectedIndex] = {
        name: newV2MomName,
        status: modalStatus,
        description: newV2MomDescription,
        relation: newV2MomRelation,
      };
    }
    dispatch(setUserManagementInfo(userInfoCopy));
    resetNewV2Mom();
  };

  const deleteV2Mom = () => {
    const userInfoCopy = deepCopy(userInfo);
    if (selectedIndex !== null) {
      userInfoCopy.v2Moms[currentQuarter].splice(selectedIndex, 1);
    }
    dispatch(setUserManagementInfo(userInfoCopy));
    resetNewV2Mom();
  };

  return (
    <Modal
      handleClose={() => resetNewV2Mom()}
      show={!!newV2MomName || openModal}
      title={"New V2Mom"}
    >
      <ModalDivConatiner>
        <ModalSpan>
          The following V2Mom will be added to{" "}
          {newV2MomName ? "edited from " : "added to "}
          {<span>{userInfo?.name}</span>}
        </ModalSpan>
        <div>V2Mom Name</div>
        <PDInput
          placeholder="V2Mom Name"
          value={newV2MomName}
          onChange={(value: string) => setNewV2MomName(value)}
          validationLabel={
            userInfo && (userInfo as any)[currentQuarter] ?.v2Moms[currentQuarter].find(
              (v2Mom: v2MomTypeArray) => v2Mom.name === newV2MomName,
            )
              ? "V2Mom name already exists"
              : ""
          }
        />
        <div>Description</div>
        <PDInput
          placeholder="Description"
          value={newV2MomDescription}
          onChange={(value: string) => setNewV2MomDescription(value)}
        />
        <div>Relation</div>
        <Dropdown
          displayValue={"name"}
          value={newV2MomRelation}
          onChange={(value: string) => setNewV2MomRelation(value)}
          options={superiorInfo.v2Moms[currentQuarter]
            .filter((v2Mom: v2MomTypeArray) => v2Mom.status !== "Done")
            .map((v2Mom: v2MomTypeArray) => {
              return { name: v2Mom.name };
            })}
        />
        <div>Status</div>
        <SelectContainerStatus>
          <MembersCardIconSelectContainer
            onClick={() => setModalStatus("Not Started")}
            $statusColor={
              modalStatus === "Not Started" ? "Not Started" : "Disabled"
            }
          >
            <MembersCardIcon
              title={"Not Started"}
              $statusColor={
                modalStatus === "Not Started" ? "Not Started" : "Disabled"
              }
            >
              <Icon icon={"BellAlertIcon"} type={"outline"} />
            </MembersCardIcon>
            Not Started
          </MembersCardIconSelectContainer>
          <MembersCardIconSelectContainer
            onClick={() => setModalStatus("In Progress")}
            $statusColor={
              modalStatus === "In Progress" ? "In Progress" : "Disabled"
            }
          >
            <MembersCardIcon
              title={"In Progress"}
              $statusColor={
                modalStatus === "In Progress" ? "In Progress" : "Disabled"
              }
            >
              <Icon icon={"RocketLaunchIcon"} type={"outline"} />
            </MembersCardIcon>
            In Progress
          </MembersCardIconSelectContainer>
          <MembersCardIconSelectContainer
            onClick={() => setModalStatus("Done")}
            $statusColor={modalStatus === "Done" ? "Done" : "Disabled"}
          >
            <MembersCardIcon
              title={"Done"}
              $statusColor={modalStatus === "Done" ? "Done" : "Disabled"}
            >
              <Icon icon={"CheckCircleIcon"} type={"outline"} />
            </MembersCardIcon>
            Done
          </MembersCardIconSelectContainer>
          <MembersCardIconSelectContainer
            onClick={() => setModalStatus("Blocked")}
            $statusColor={modalStatus === "Blocked" ? "Blocked" : "Disabled"}
          >
            <MembersCardIcon
              title={"Blocked"}
              $statusColor={modalStatus === "Blocked" ? "Blocked" : "Disabled"}
            >
              <Icon icon={"NoSymbolIcon"} type={"outline"} />
            </MembersCardIcon>
            Blocked
          </MembersCardIconSelectContainer>
        </SelectContainerStatus>
        <ModalButtonsContainer>
          <div>
            {selectedIndex !== null && (
              <Button
                text="Delete"
                type="secondary"
                color="#f44336"
                onClick={() => deleteV2Mom()}
              />
            )}
          </div>
          <ModalButtonsContainerRight>
            <Button
              text={selectedIndex !== null ? "Edit" : "Add"}
              type="secondary"
              color="#048a24"
              onClick={() => {
                addOrEditV2Moms();
              }}
              disabled={
                newV2MomName === "" ||
                newV2MomDescription === "" ||
                newV2MomRelation === ""
              }
            />
            <Button
              text="Cancel"
              type="secondary"
              color="#f44336"
              onClick={() => {
                resetNewV2Mom();
              }}
            />
          </ModalButtonsContainerRight>
        </ModalButtonsContainer>
      </ModalDivConatiner>
    </Modal>
  );
});

FormModal.displayName = "FormModal";

export default FormModal;

import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  DatePicker,
  Dropdown,
  Icon,
  Modal,
  PDInput,
} from "@/components";
import {
  FormColumn,
  FormLabel,
  FormRow,
  FormTextArea,
  ModalButtonsContainer,
  ModalButtonsContainerRight,
  ModalContainer,
  StatusTag,
} from "@/common.styled";
import { deepCopy } from "@/utils/shared-functions";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { releaseTaskModalType, statusElementsType, stagesType, featuresType, tasksType, managementReleasesType } from "@/models";
import { setManagementReleases } from "@/store/states";

const TasksModals = forwardRef(
  (
    {
      releases,
      releaseId,
      stageId,
      featureId,
      selectedTask,
      selectedRelease,
      onTaskAdded,
      onTaskEdited,
      onTaskDelete,
      openTaskModalAdd,
      openTaskModalDelete,
      onAddTaskModal,
      onDeleteTaskModal,
    }: releaseTaskModalType,
    Ref,
  ) => {
    const dispatch = useDispatch();
    const [taskModalName, setTaskModalName] = useState<string>("");
    const [taskModalDescription, setTaskModalDescription] =
      useState<string>("");
    const [taskModalStatus, setTaskModalStatus] = useState<statusElementsType>(
      selectedRelease?.statusList[0] as statusElementsType,
    );
    const [taskModalReasonFor, setTaskModalReasonFor] = useState<string>("");
    const [
      taskModalEstimatedTimeInMinutes,
      setTaskModalEstimatedTimeInMinutes,
    ] = useState<number>(0);
    const [taskModalRealTimeInMinutes, setTaskModalRealTimeInMinutes] =
      useState<number>(0);
    const [taskModalStartDate, setTaskModalStartDate] = useState<Date | null>(
      null,
    );
    const [taskModalEndDate, setTaskModalEndDate] = useState<Date | null>(null);
    const [taskModalRealStartDate, setTaskModalRealStartDate] =
      useState<Date | null>(null);
    const [taskModalRealEndDate, setTaskModalRealEndDate] =
      useState<Date | null>(null);
    const [taskAssignedTo, setTaskAssignedTo] = useState<string>("");
    const [isEditModeNewTask, setIsEditModeNewTask] = useState<boolean>(false);

    const userInfo = useSelector((state: any) => state.userInfo);
    const teamsInfo = useSelector((state: any) => state.teamsInfo);
    const initialStatusList = useMemo(
      () => selectedRelease?.statusList[0],
      [selectedRelease],
    );

    useImperativeHandle(Ref, () => ({ editTaskOpenModal }));

    useEffect(() => {
      setTaskModalStatus(selectedRelease?.statusList[0] as statusElementsType);
    }, [initialStatusList, selectedRelease?.statusList]);

    useEffect(() => {
      setTaskModalReasonFor("");
    }, [taskModalStatus]);

    const getUsersList = useCallback(() => {
      if (teamsInfo && userInfo) {
        const usersList: any = [];
        usersList.push({ name: userInfo.name });
        teamsInfo.map((team: any) => {
          usersList.push({ name: team.name });
        });
        return usersList;
      }
      return [];
    }, [teamsInfo, userInfo]);

    const [usersList, setUsersList] = useState<any>(getUsersList());

    useEffect(() => {
      setUsersList(getUsersList());
    }, [userInfo, teamsInfo, getUsersList]);

    const makeNewId = () => {
      const newID = uuidv4();
      if (!releases) return newID;
      const idAlreadyExists = (id: string): boolean => {
        return releases.some((release: managementReleasesType) =>
          release.stages.some((stage: stagesType) =>
            stage.features.some((feature: featuresType) =>
              feature.tasks.some((task: tasksType) => task.id === id),
            ),
          ),
        );
      };
      if (idAlreadyExists(newID)) {
        return makeNewId();
      }
      return newID;
    };

    const addNewTask = () => {
      if (!featureId) return;
      const releaseCopy = deepCopy(releases);
      const releaseIndex = releaseCopy.findIndex(
        (release: managementReleasesType) => release.id === releaseId,
      );
      const stageIndex = releaseCopy[releaseIndex].stages.findIndex(
        (stage: stagesType) => stage.id === stageId,
      );
      const featureIndex = releaseCopy[releaseIndex].stages[
        stageIndex
      ].features.findIndex((feature: featuresType) => feature.id === featureId);
      releaseCopy[releaseIndex].stages[stageIndex].features[
        featureIndex
      ].tasks.push({
        name: taskModalName,
        status: taskModalStatus,
        description: taskModalDescription,
        assignedTo: taskAssignedTo,
        estimatedTimeInMinutes: taskModalEstimatedTimeInMinutes,
        realTimeInMinutes: taskModalRealTimeInMinutes,
        reasonFor: taskModalStatus?.reasonFor ? taskModalReasonFor : null,
        startDate: taskModalStartDate,
        endDate: taskModalEndDate,
        realStartDate: taskModalRealStartDate,
        realEndDate: taskModalRealEndDate,
        id: makeNewId(),
      });
      dispatch(setManagementReleases(releaseCopy));
      onTaskAdded &&
        onTaskAdded(releaseCopy, releaseIndex, stageIndex, featureIndex);
    };

    const editTask = () => {
      if (!releaseId || !stageId || !featureId || !selectedTask?.id) return;
      const releaseCopy = deepCopy(releases);
      const releaseIndex = releaseCopy.findIndex(
        (release: managementReleasesType) => release.id === releaseId,
      );
      const stageIndex = releaseCopy[releaseIndex].stages.findIndex(
        (stage: stagesType) => stage.id === stageId,
      );
      const featureIndex = releaseCopy[releaseIndex].stages[
        stageIndex
      ].features.findIndex((feature: featuresType) => feature.id === featureId);
      const taskIndex = releaseCopy[releaseIndex].stages[stageIndex].features[
        featureIndex
      ].tasks.findIndex((task: tasksType) => task.id === selectedTask?.id);
      releaseCopy[releaseIndex].stages[stageIndex].features[featureIndex].tasks[
        taskIndex
      ] = {
        name: taskModalName,
        status: taskModalStatus,
        description: taskModalDescription,
        assignedTo: taskAssignedTo,
        estimatedTimeInMinutes: taskModalEstimatedTimeInMinutes,
        realTimeInMinutes: taskModalRealTimeInMinutes,
        reasonFor: taskModalStatus?.reasonFor ? taskModalReasonFor : null,
        startDate: taskModalStartDate,
        endDate: taskModalEndDate,
        realStartDate: taskModalRealStartDate,
        realEndDate: taskModalRealEndDate,
        id: selectedTask?.id,
      };
      dispatch(setManagementReleases(releaseCopy));
      onTaskEdited &&
        onTaskEdited(
          releaseCopy,
          releaseIndex,
          stageIndex,
          featureIndex,
          taskIndex,
        );
    };

    const editTaskOpenModal = () => {
      if (selectedTask) {
        setTaskModalName(selectedTask.name);
        setTaskModalDescription(selectedTask.description);
        setTaskModalStatus(selectedTask.status);
        setTaskAssignedTo(selectedTask.assignedTo);
        setTaskModalReasonFor(selectedTask?.reasonFor || "");
        setTaskModalEstimatedTimeInMinutes(selectedTask.estimatedTimeInMinutes);
        setTaskModalRealTimeInMinutes(selectedTask.realTimeInMinutes);
        setTaskModalStartDate(selectedTask.startDate);
        setTaskModalEndDate(selectedTask.endDate);
        setTaskModalRealStartDate(selectedTask.realStartDate);
        setTaskModalRealEndDate(selectedTask.realEndDate);
      }
      setIsEditModeNewTask(true);
      onAddTaskModal(true);
    };

    const removeTask = () => {
      if (!releases || !stageId || !featureId || !selectedTask?.id) return;
      const releaseCopy = deepCopy(releases);
      const releaseIndex = releaseCopy.findIndex(
        (release: managementReleasesType) => release.id === releaseId,
      );
      const stageIndex = releaseCopy[releaseIndex].stages.findIndex(
        (stage: stagesType) => stage.id === stageId,
      );
      const featureIndex = releaseCopy[releaseIndex].stages[
        stageIndex
      ].features.findIndex((feature: featuresType) => feature.id === featureId);
      const taskIndex = releaseCopy[releaseIndex].stages[stageIndex].features[
        featureIndex
      ].tasks.findIndex((task: tasksType) => task.id === selectedTask?.id);
      releaseCopy[releaseIndex].stages[stageIndex].features[
        featureIndex
      ].tasks.splice(taskIndex, 1);
      dispatch(setManagementReleases(releaseCopy));
      onAddTaskModal(false);
      onTaskDelete &&
        onTaskDelete(releaseCopy, releaseIndex, stageIndex, featureIndex);
    };

    useEffect(() => {
      if (!openTaskModalAdd) {
        setTaskModalName("");
        setTaskModalDescription("");
        setTaskModalStatus(
          selectedRelease?.statusList[0] as statusElementsType,
        );
        setTaskModalReasonFor("");
        setTaskModalEstimatedTimeInMinutes(0);
        setTaskModalRealTimeInMinutes(0);
        setTaskModalStartDate(null);
        setTaskModalEndDate(null);
        setTaskModalRealStartDate(null);
        setTaskModalRealEndDate(null);
        setTaskAssignedTo("");
        setIsEditModeNewTask(false);
      }
    }, [openTaskModalAdd, initialStatusList, selectedRelease?.statusList]);

    return (
      <>
        <Modal
          handleClose={() => {
            onAddTaskModal(false);
          }}
          show={openTaskModalAdd}
          title={isEditModeNewTask ? `Task ${taskModalName}` : "New Task"}
        >
          <ModalContainer>
            <FormLabel $mandatory="true">Task Name</FormLabel>
            <PDInput
              placeholder="Task Name"
              value={taskModalName}
              onChange={(value: string) => setTaskModalName(value)}
            />
            <FormLabel $mandatory="true">Task Description</FormLabel>
            <FormTextArea
              placeholder="Task Description"
              value={taskModalDescription}
              onChange={(value) => setTaskModalDescription(value.target.value)}
            />
            <FormLabel $mandatory="true">Task Assigned To</FormLabel>
            <Dropdown
              options={usersList}
              value={taskAssignedTo}
              onChange={(value: string) => setTaskAssignedTo(value)}
              displayValue={"name"}
            />
            <FormLabel $mandatory="true">Estimated Time In Minutes</FormLabel>
            <PDInput
              placeholder="Estimated Time In Minutes"
              type="number"
              value={taskModalEstimatedTimeInMinutes.toString()}
              onChange={(value: string) =>
                setTaskModalEstimatedTimeInMinutes(Number(value))
              }
            />
            <FormLabel>Real Time In Minutes</FormLabel>
            <PDInput
              placeholder="Real Time In Minutes"
              type="number"
              value={taskModalRealTimeInMinutes.toString()}
              onChange={(value: string) =>
                setTaskModalRealTimeInMinutes(Number(value))
              }
            />
            <FormRow>
              <FormColumn>
                <FormLabel $mandatory="true">Start Date</FormLabel>
                <DatePicker
                  value={taskModalStartDate}
                  onChange={(value: Date) => setTaskModalStartDate(value)}
                  size="Form"
                />
              </FormColumn>
              <FormColumn>
                <FormLabel $mandatory="true">End Date</FormLabel>
                <DatePicker
                  value={taskModalEndDate}
                  onChange={(value: Date) => setTaskModalEndDate(value)}
                  size="Form"
                />
              </FormColumn>
            </FormRow>
            {isEditModeNewTask && (
              <FormRow>
                <FormColumn>
                  <FormLabel>Real Start Date</FormLabel>
                  <DatePicker
                    value={selectedTask?.realStartDate}
                    onChange={(value: Date) => setTaskModalRealStartDate(value)}
                    size="Form"
                  />
                </FormColumn>
                <FormColumn>
                  <FormLabel>Real End Date</FormLabel>
                  <DatePicker
                    value={selectedTask?.realEndDate}
                    onChange={(value: Date) => setTaskModalRealEndDate(value)}
                    size="Form"
                  />
                </FormColumn>
              </FormRow>
            )}
            <FormLabel $mandatory="true">Status</FormLabel>
            <FormRow $position="center">
              {selectedRelease &&
                selectedRelease.statusList.map((status: statusElementsType) => (
                  <StatusTag
                    key={status.name}
                    $statusColor={
                      taskModalStatus?.name === status.name
                        ? status.color
                        : "#d0d0d0"
                    }
                    onClick={() => setTaskModalStatus(status)}
                  >
                    <Icon icon={status.icon} type={"outline"} />
                    {status.name}
                  </StatusTag>
                ))}
            </FormRow>
            {taskModalStatus?.reasonFor && (
              <>
                <FormLabel $mandatory="true">Reason for</FormLabel>
                <FormTextArea
                  value={taskModalReasonFor}
                  onChange={(value) =>
                    setTaskModalReasonFor(value.target.value)
                  }
                  placeholder="Reason for"
                />
              </>
            )}
            <ModalButtonsContainer>
              <div>
                {selectedTask && (
                  <Button
                    text="Delete"
                    type="secondary"
                    color="#f44336"
                    onClick={() => onDeleteTaskModal(true)}
                  />
                )}
              </div>
              <ModalButtonsContainerRight>
                <Button
                  text={isEditModeNewTask ? "Edit" : "Create"}
                  type="secondary"
                  color="#048a24"
                  onClick={() => {
                    isEditModeNewTask ? editTask() : addNewTask();
                  }}
                  disabled={
                    !taskModalName ||
                    !taskModalDescription ||
                    !taskAssignedTo ||
                    !taskModalStatus ||
                    !taskModalEstimatedTimeInMinutes ||
                    !taskModalStartDate ||
                    !taskModalEndDate ||
                    (taskModalStatus?.reasonFor && !taskModalReasonFor)
                  }
                />
                <Button
                  text="Cancel"
                  type="secondary"
                  color="#f44336"
                  onClick={() => {
                    onAddTaskModal(false);
                  }}
                />
              </ModalButtonsContainerRight>
            </ModalButtonsContainer>
          </ModalContainer>
        </Modal>
        <Modal
          handleClose={() => onDeleteTaskModal(false)}
          show={openTaskModalDelete}
          title={"Delete task confirmation"}
          modalFitContent={true}
        >
          <ModalContainer>
            <FormLabel>Are you sure you want to delete this task?</FormLabel>
            <ModalButtonsContainer>
              <Button
                text="Delete"
                type="secondary"
                color="#f44336"
                onClick={() => {
                  removeTask();
                  onDeleteTaskModal(false);
                }}
              />
              <Button
                text="Cancel"
                type="secondary"
                color="#048a24"
                onClick={() => {
                  onDeleteTaskModal(false);
                }}
              />
            </ModalButtonsContainer>
          </ModalContainer>
        </Modal>
      </>
    );
  },
);

TasksModals.displayName = "TasksModals";

export default TasksModals;

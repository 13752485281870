import { TabsType } from "./Tabs.model.ts";
import { TabsContainer, TabsStyled } from "./Tabs.styled.tsx";

const Tabs = ({ type = "border", activeTab, tabs, onTabChange }: TabsType) => {
  return (
    <TabsContainer type={type}>
      {tabs.map((tab) => {
        return (
          <TabsStyled
            key={tab}
            active={activeTab === tab}
            type={type}
            onClick={() => onTabChange(tab)}
          >
            {tab}
          </TabsStyled>
        );
      })}
    </TabsContainer>
  );
};

Tabs.displayName = "Tabs";

export default Tabs;

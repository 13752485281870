import Modal from "../../../components/Modal";
import Spinner from '../../../components/Spinner/Spinner'

export default function ModalGWP({setShowGWP, selectedGWP, showGWP, loadingGWP, GWP}) {

  const formatTime = (minutes) => {  
    if (minutes === 60) {
      return "1 hour";
    } else if (minutes % 60 === 0) {
      return `${minutes / 60} ${minutes / 60 === 1 ? 'hour' : 'hours' } `;
    } else if (minutes < 60) {
      return `${parseFloat(minutes).toFixed(0)} ${parseFloat(minutes).toFixed(0) === '1' ? 'minute' :  'minutes'}`;
    } else {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours > 0 ? `${hours} ${hours > 1 ? 'hours' : 'hour'}` : ''} ${remainingMinutes > 0 ? `${remainingMinutes.toFixed(0)}  ${remainingMinutes.toFixed(0) === 1 ?  'minute' :  'minutes' }` : ''}`.trim();
    }
  }

  return(
    <Modal
        show={showGWP}
        handleClose={() => setShowGWP(!showGWP)}
        modalFitContent
        title={`Get Well Plan: ${selectedGWP?.title}`}
        type="small"
      >
        <div>
          {loadingGWP &&  <Spinner/>}
          <div>
            {GWP?.map((item, index) =>
              <div key={index}>
                  <div className="title__steps">The following steps are provided to guide you in addressing this Health Check Alert. PagerDuty Professional Services is always available to help via a Service Request.</div>
                  <div className="description__steps">{item?.description}</div>
                  <div className="container__steps">
                    <div className="steps__title">
                      <div>Steps </div>
                      <div>Duration: {formatTime(item.total_effort)}</div>
                    </div>
                    <ul className="ul__steps">
                        {item?.steps?.map((item, index) => (
                          <li key={index} className="next_steps">
                            {item?.description}
                            <span className="effort__description">{formatTime(item.effort_value)}</span>
                          </li>
                        ))}
                    </ul>
                </div>
              </div>
            )}
          </div>
          {GWP.length === 0 && !loadingGWP &&
            <div className="not__foundGwp">
                No data found
            </div>
          }
        </div>
      </Modal>
  )
}
import styled from "styled-components";

interface ActionButtonInterface {
  $disabled?: boolean;
}

export const ActionButton = styled.button<ActionButtonInterface>`
  width: 100%;
  color: ${(props) => (props.$disabled ? "#000" : "black")};
  border: none;
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: transparent;
  &:hover {
    color: ${(props) => (props.$disabled ? "#000" : "var(--primary-color)")};
  }
`;

export const ActionsPopUpContainer = styled.div`
  left: 0px;
  top: 42px;
  width: 200px;
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  z-index: 2;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 10px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  & svg {
    height: 20px;
    width: 20px;
    color: #757575;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 8px 16px;
  color: #535353;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: white;
  width: fit-content;
  position: relative;
  min-width: 200px;
  gap: 10px;
  & svg {
    height: 24px;
    width: 24px;
    color: #757575;
  }
`;

export const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

import { coursesAndEventsType } from "@/models";

export const coursesAndEventsMock = <coursesAndEventsType[]>[
  {
    id: 100001,
    name: "Cybersecurity Fundamentals",
    totalEnroll: 147,
    type: "Course",
    description: "Learn the basics of Cybersecurity",
    requaried: true,
    personalStatus: "Not Enrolled",
    peopleOnMyTeamEnrolled: [
      {
        name: "Pamela McClure",
        status: "In Progress",
      },
      {
        name: "Alexandra Hulme",
        status: "Done",
      },
    ],
    dueDate: new Date("2024-12-31"),
  },
  {
    id: 100002,
    name: "Advanced Java Programming",
    totalEnroll: 15,
    type: "Course",
    description: "Learn advanced Java Programming",
    requaried: true,
    personalStatus: "Not Enrolled",
    peopleOnMyTeamEnrolled: [],
    dueDate: new Date("2024-12-07"),
  },
  {
    id: 100003,
    name: "Cloud Computing with AWS",
    totalEnroll: 125,
    type: "Course",
    description: "Learn Cloud Computing with AWS",
    requaried: false,
    personalStatus: "Done",
    peopleOnMyTeamEnrolled: [
      {
        name: "Pamela McClure",
        status: "In Progress",
      },
      {
        name: "Alexandra Hulme",
        status: "Done",
      },
    ],
  },
  {
    id: 100004,
    name: "Data Structures and Algorithms",
    totalEnroll: 45,
    type: "Course",
    description: "Learn Data Structures and Algorithms",
    requaried: false,
    personalStatus: "In Progress",
    peopleOnMyTeamEnrolled: [
      {
        name: "Pamela McClure",
        status: "In Progress",
      },
      {
        name: "Alexandra Hulme",
        status: "Done",
      },
    ],
  },
  {
    id: 100005,
    name: "Introduction to Machine Learning",
    totalEnroll: 67,
    type: "Course",
    description: "Learn the basics of Machine Learning",
    requaried: true,
    personalStatus: "Not Enrolled",
    peopleOnMyTeamEnrolled: [
      {
        name: "Pamela McClure",
        status: "In Progress",
      },
      {
        name: "Alexandra Hulme",
        status: "Done",
      },
    ],
    dueDate: new Date("2025-06-31"),
  },
  {
    id: 100006,
    name: "Web Development with React and Node.js",
    totalEnroll: 98,
    type: "Course",
    description: "Learn Web Development with React and Node.js",
    requaried: true,
    personalStatus: "In Progress",
    peopleOnMyTeamEnrolled: [
      {
        name: "Pamela McClure",
        status: "In Progress",
      },
      {
        name: "Alexandra Hulme",
        status: "Done",
      },
    ],
    dueDate: new Date("2025-02-06"),
  },
  {
    id: 100007,
    name: "Network Administration and Management",
    totalEnroll: 32,
    type: "Course",
    description: "Learn Network Administration and Management",
    requaried: false,
    personalStatus: "Not Enrolled",
    peopleOnMyTeamEnrolled: [],
  },
  {
    id: 100008,
    name: "Introduction to AI",
    totalEnroll: 78,
    type: "Event",
    description: "Learn the basics of AI",
    requaried: true,
    personalStatus: "Done",
    peopleOnMyTeamEnrolled: [
      {
        name: "Pamela McClure",
        status: "Done",
      },
      {
        name: "Alexandra Hulme",
        status: "Done",
      },
    ],
    dueDate: new Date("2022-12-31"),
  },
  {
    id: 100009,
    name: "Tech Innovators Conference",
    totalEnroll: 147,
    type: "Event",
    description: "Tech Innovators Conference",
    requaried: false,
    personalStatus: "In Progress",
    peopleOnMyTeamEnrolled: [],
  },
  {
    id: 100010,
    name: "Global Cybersecurity Summit",
    totalEnroll: 15,
    type: "Event",
    description: "Global Cybersecurity Summit",
    requaried: true,
    personalStatus: "In Progress",
    peopleOnMyTeamEnrolled: [
      {
        name: "Pamela McClure",
        status: "In Progress",
      },
      {
        name: "Alexandra Hulme",
        status: "Done",
      },
    ],
    dueDate: new Date("2024-12-07"),
  },
  {
    id: 100011,
    name: "AI & Machine Learning Expo",
    totalEnroll: 125,
    type: "Event",
    description: "AI & Machine Learning Expo",
    requaried: false,
    personalStatus: "Not Enrolled",
    peopleOnMyTeamEnrolled: [],
  },
  {
    id: 100012,
    name: "Cloud Computing Symposium",
    totalEnroll: 45,
    type: "Event",
    description: "Cloud Computing Symposium",
    requaried: true,
    personalStatus: "Not Enrolled",
    peopleOnMyTeamEnrolled: [],
    dueDate: new Date("2025-06-31"),
  },
  {
    id: 100013,
    name: "Annual Software Development Workshop",
    totalEnroll: 67,
    type: "Event",
    description: "Annual Software Development Workshop",
    requaried: true,
    personalStatus: "Not Enrolled",
    peopleOnMyTeamEnrolled: [
      {
        name: "Pamela McClure",
        status: "In Progress",
      },
      {
        name: "Alexandra Hulme",
        status: "Done",
      },
    ],
    dueDate: new Date("2025-07-31"),
  },
];

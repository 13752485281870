import { sessionModal } from "./subjects";

export const envs = [
  {
    url: "localhost",
    api: "https://backend-test.innovation.csg.pagerduty.com/",
  },
  {
    url: "portal-test.innovation.csg.pagerduty.com",
    api: "https://backend-test.innovation.csg.pagerduty.com/",
  },
  {
    url: "portalstg.innovation.csg.pagerduty.com",
    api: "https://backend-stg.innovation.csg.pagerduty.com",
  },
  {
    url: "develop-fe.innovation.csg.pagerduty.com",
    api: "https://backend-test.innovation.csg.pagerduty.com/",
  },
  {
    url: "portal.innovation.csg.pagerduty.com",
    api: "https://backend.innovation.csg.pagerduty.com/",
  },
];

export const newEnvs = [
  {
    url: "localhost",
    api: "https://test-services.csg.pagerduty.com/csg-ms-bo-healthcheck-ecs",
  },
  {
    url: "portal-test.innovation.csg.pagerduty.com",
    api: "http://test-services.csg.pagerduty.com/csg-ms-bo-healthcheck-ecs",
  },
  {
    url: "portalstg.innovation.csg.pagerduty.com",
    api: "https://backend-stg.innovation.csg.pagerduty.com",
  },
  {
    url: "develop-fe.innovation.csg.pagerduty.com",
    api: "http://test-services.csg.pagerduty.com/csg-ms-bo-healthcheck-ecs",
  },
  {
    url: "portal.innovation.csg.pagerduty.com",
    api: "http://services.csg.pagerduty.com/csg-ms-bo-healthcheck-ecs",
  },
];

export const getHostName = () => {
  const hostname = window.location.hostname;
  const currentEnv = envs.filter((env) => env.url === hostname);
  return currentEnv[0]?.api || "";
};

export const getNewHostName = () => {
  const hostname = window.location.hostname;
  const currentEnv = newEnvs.filter((env) => env.url === hostname);
  return currentEnv[0]?.api || "";
};

export const API_BASE_URL = getHostName();
export const NEW_API_BASE_URL = getNewHostName();


/**
 * @param {object} options
 * @param {string} options.path
 * @param {boolean} options.newDomain
 * @return {string}
 */
export const cleanedURL = ({ url, newDomain }) => {
  const baseURL = newDomain ? NEW_API_BASE_URL : API_BASE_URL;
  const cleanedBaseURL = baseURL.replace(/\/+$/, "");
  const cleanedURL = url.replace(/^\/+/, "");
  return `${cleanedBaseURL}/${cleanedURL}`;
}

const serviceNamesLiteral = {
    "/mfa/": () => "CSG INV SVC : LOGIN APP",
    "/mfa/oauth2": () => "CSG INV SVC : LOGIN APP",
    "/user/profile": () => "CSG INV SVC: HOME APP",
    "/user/session": () => "CSG INV SVC: HOME APP",
    "/user/destroySession": () => "CSG INV SVC : LOGIN APP",
    "ms-home/menu/user": () => "CSG INV SVC: HOME APP",
    "/ms-home/user/": () => "CSG INV SVC: HOME APP",
    "/ms-home/role/": () => "CSG INV SVC: HOME APP",
    "/ms-home/cst_dashboard_review/profile": () => "CSG INV SVC: HOME APP",
    "/ms-home/adm_dashboard_review/profile": () => "CSG INV SVC: HOME APP",
    "/ms-home/feedback/": () => "CSG INV SVC: HOME APP",
    "/ms-home/user/manager": () => "CSG INV SVC: HOME APP",
    "/ms-home/user/details": () => "CSG INV SVC: HOME APP",
    "/ms-sod/Tech_Details/querys_days_remaining/": () => "CSG INV SVC: SOD APP",
    "/ms-sod/apiReports/sla_report/": () => "CSG INV SVC: SOD APP",
    "ms-sod/apiGraph/graph_sku/1": () => "CSG INV SVC: SOD APP",
    "/ms-sod/apiGraph/graph_sku/": () => "CSG INV SVC: SOD APP",
    "/ms-sod/Projects/projectssectionDetails/": () => "CSG INV SVC: SOD APP",
    "ms-sod/apiUpdateAsana/updateAsanaProjects_csm/": () => "CSG INV SVC: SOD APP",
    "/ms-sod/Tech_Details/getlastinfo_sectionDetails/": () => "CSG INV SVC: SOD APP",
    "/ms-sod/Tech_Details/list_of_projects_hours/": () => "CSG INV SVC: SOD APP",
    "ms-sod/Tech_Details/getlastupdatecrone/": () => "CSG INV SVC: SOD APP",
    "/ms-sod/apiReports/sla_report_csv/": () => "CSG INV SVC: SOD APP",
    "/ms-sod/apiReports/csv_report_all_projects/": () => "CSG INV SVC: SOD APP",
    "/ms-sod/apiReports/csv_report_all_projects_detail_tasks/": () => "CSG INV SVC: SOD APP",
    "/ms-sod/apiUpdateAsana/updateAsanaProjects/": () => "CSG INV SVC: SOD APP",
    "/ms-optimizer/health-check/services/": () => "CSG INV SVC: MS OPTIMIZER APP",
    "/ms-optimizer/health-check/metrics/execution-date": () => "CSG INV SVC: MS OPTIMIZER APP",
    "/ms-optimizer/health-check/metrics/available-entities": () => "CSG INV SVC: MS OPTIMIZER APP",
    "/ms-optimizer/health-check/teams/": () => "CSG INV SVC: MS OPTIMIZER APP",
    "/ms-optimizer/health-check/metrics/services/incidents/top-ten": () => "CCSG INV SVC: MS OPTIMIZER APP",
    "/ms-optimizer/health-check/users/": () => "CCSG INV SVC: MS OPTIMIZER APP",
    "/ms-optimizer/health-check/metrics/services/best-practices/count": () => "CSG INV SVC: MS OPTIMIZER APP",
    "/ms-optimizer/health-check/users/best-practices/count": () => "CSG INV SVC: MS OPTIMIZER APP",
    "/ms-optimizer/health-check/metrics/core": () => "CSG INV SVC: MS OPTIMIZER APP",
    "/ms-optimizer/health-check/teams/meta_data?teams_ids=": () => "CSG INV SVC: MS OPTIMIZER APP",
    "/ms-optimizer/health-check/metrics/meditions/": () => "CSG INV SVC: MS OPTIMIZER APP",
    "/stacks/": () => "CSG INV SVC: STACKS",
    "/stacks/byClientName": () => "CSG INV SVC: STACKS",
    "/stacks/domains": () => "CSG INV SVC: STACKS",
    "/stacks/folders/": () => "CSG INV SVC: STACKS",
    "/stacks/analysis/": () => "CSG INV SVC: STACKS",
    "/stacks/analysis/entities/": () => "CSG INV SVC: STACKS",
    "/stacks/analysis/PDF": () => "CSG INV SVC: STACKS",
    "/stacks/analysis/PDF/email": () => "CSG INV SVC: STACKS",
    "/configuration/entities/": () => "CSG INV SVC: STACKS",
    "/ms-onboarding/team_role/": () => "CSG INV SVC: ONBOARDING APP",
    "/ms-onboarding/base_role/": () => "CSG INV SVC: ONBOARDING APP",
    "/ms-onboarding/schedule/": () => "CSG INV SVC: ONBOARDING APP",
    "/ms-onboarding/draft/": () => "CSG INV SVC: ONBOARDING APP",
    "/ms-onboarding/setup/": () => "CSG INV SVC: ONBOARDING APP",
    "/ms-onboarding/setup/subdomain/": () => "CSG INV SVC: ONBOARDING APP",
    "/ms-sod/apiGraph/graph_average_sku/": () => "CSG INV SVC: SOD APP",
    "/ms-sod/apiGraph/graph_average_days_not_kicked/": () => "CSG INV SVC: SOD APP",
    "/ms-sod/apiGraph/graph_projects_not_kicked/": () => "CSG INV SVC: SOD APP",
    "/ms-sod/apiGraph/filter_for_fiscal_years/": () => "CSG INV SVC: SOD APP",
    "/ms-ps-status/projects/execution_date": () => "CSG INV SVC: PS APP",
    "/ms-ps-status/projects/revenue": () => "CSG INV SVC: PS APP",
    "/ms-ps-status/projects/professional-services": () => "CSG INV SVC: PS APP",
    "/ms-ps-status/reports/general/historic": () => "CSG INV SVC: PS APP",
    "/ms-ps-status/projects/execution_date": () => "CSG INV SVC: PS APP",
    "/ms-ps-status/reports/revenue/portfolio-count": () => "CSG INV SVC: PS APP",
    "/ms-ps-status/reports/revenue/order-value-sum": () => "CSG INV SVC: PS APP",
    "/ms-ps-status/reports/revenue/portfolio-count/up-to-date": () => "CSG INV SVC: PS APP",
    "/ms-ps-status/reports/revenue/order-value-sum/up-to-date": () => "CSG INV SVC: PS APP",
    "/ms-ps-status/projects/portfolios": () => "CSG INV SVC: PS APP",
    "/ms-ps-status/reports/general/csv/historical": () => "CSG INV SVC: PS APP",
    "/ms-ps-status/reports/general/csv/up-to-date": () => "CSG INV SVC: PS APP",
    "/ms-ps-status/reports/revenue/csv/historical": () => "CSG INV SVC: PS APP",
    "/ms-ps-status/reports/revenue/csv/up-to-date": () => "CSG INV SVC: PS APP",
    "/ms-ps-status/projects/professional-services/filtered": () => "CSG INV SVC: PS APP",
    "/ms-ps-status/projects/skus": () => "CSG INV SVC: PS APP",
    "/ms-pdu-status/asana/execution_date": () => "CSG INV SVC: PDU APP",
    "/ms-pdu-status/skilljar/vilt/sessions/trained-users": () => "CSG INV SVC: PDU APP",
    "/ms-pdu-status/asana/projects/gold": () => "CSG INV SVC: PDU APP",
    "/ms-pdu-status/asana/projects/silver": () => "CSG INV SVC: PDU APP",
    "/ms-pdu-status/skilljar/groups": () => "CSG INV SVC: PDU APP",
    "/ms-pdu-status/asana/projects/": () => "CSG INV SVC: PDU APP",
    "/ms-pdu-status/skilljar/groups/not-valid-names": () => "CSG INV SVC: PDU APP",
    "/ms-nextgen/deck/generate_deck": () => "CSG INV SVC: DECK GENERATOR APP",
    "/ms-nextgen/deck/customers_list": () => "CSG INV SVC: DECK GENERATOR APP",
    "/ms-nextgen/deck/tour": () => "CSG INV SVC: DECK GENERATOR APP",
    "/ms-home/cst_dashboard_review/presupsum": () => "CSG INV SVC: HOME APP",
    "/ms-home/cst_dashboard_review/pdustatus": () => "CSG INV SVC: HOME APP",
    "/ms-home/adm_dashboard_review/pdustatus": () => "CSG INV SVC: HOME APP",
    "/ms-home/cst_dashboard_review/preserreq": () => "CSG INV SVC: HOME APP",
    "/ms-home/adm_dashboard_review/preserreq": () => "CSG INV SVC: HOME APP",
    "/ms-home/adm_dashboard_review/products": () => "CSG INV SVC: HOME APP",
    "/ms-home/cst_dashboard_review/products": () => "CSG INV SVC: HOME APP",
    "/ms-home/cst_dashboard_review/projects": () => "CSG INV SVC: HOME APP",
    "/ms-home/adm_dashboard_review/projects": () => "CSG INV SVC: HOME APP",
    "/ms-home/cst_dashboard_review/news": () => "CSG INV SVC: HOME APP",
    "/ms-home/adm_dashboard_review/news": () => "CSG INV SVC: HOME APP",
    "/ms-home/cst_dashboard_review/newstype": () => "CSG INV SVC: HOME APP",
    "/ms-home/cst_dashboard_review/news": () => "CSG INV SVC: HOME APP",
    "/ms-home/cst_dashboard_review/get_client": () => "CSG INV SVC: HOME APP",
    "/ms-home/adm_dashboard_review/get_client": () => "CSG INV SVC: HOME APP",
    "/ms-home/cst_dashboard_review/news_status": () => "CSG INV SVC: HOME APP",
    "/ms-home/cst_dashboard_review/get_team": () => "CSG INV SVC: HOME APP",
    "/ms-home/cst_dashboard_review/severity": () => "CSG INV SVC: HOME APP",
    "/ms-home/premium_request/": () => "CSG INV SVC: HOME APP",
    "/ms-home/adm_dashboard_review/customers_list": () => "CSG INV SVC: HOME APP",
    "/ms-home/adm_dashboard_review/customers_subdomain": () => "CSG INV SVC: HOME APP",
    "/ms-home/adm_dashboard_review/customers_email": () => "CSG INV SVC: HOME APP",
    "/ms-home/cst_dashboard_review/pdu_detail": () => "CSG INV SVC: HOME APP",
    "/ms-home/cst_dashboard_review/professional_services": () => "CSG INV SVC: HOME APP",
    "ms-home/cst_dashboard_review/support_summary_detail": () => "CSG INV SVC: HOME APP",
    "/ms-home/adm_dashboard_review/set_customer": () => "CSG INV SVC: HOME APP",
    "/ms-home/cst_dashboard_review/pd_entities": () => "CSG INV SVC: HOME APP",
  };

  export const handleAuthError = (status: number) => {
    if ((status === 401 || status === 403 || status === 405) &&
      !window.location.pathname.includes("/unauthorized") &&
      !window.location.pathname.includes("/logout") &&
      !window.location.pathname.includes("/login")
    ) {
      localStorage.clear();
      sessionModal.next(true);
    }
  };

  const handleResponse = async (response: Response) => {
    if (!response.ok) {
      const error = new Error(response.statusText);
      const errorData = {
        status: response.status,
        message: response.statusText,
      };
  
      try {
        const data = await response.json();
        errorData.message = data.message || data.error;
      } catch (e) {
        // Keep original error message if json parsing fails
      }
  
      handleAuthError(errorData.status);
      throw Object.assign(error, errorData);
    }
    
    return response.json().catch(() => response);
  };

/** @typedef {Object} APIRequestError
 * @property {Error} error
 * @property {string | null} message
 * @property {number | null} status
 */

/** @param {Object} options
  * @param {string} options.method
  * @param {string} options.url
  * @param {Object} options.data
  * @param {Object} options.headers
  * @param {boolean} options.newDomain
  * @returns {Promise<[APIRequestError, any, Response]>}
  */
export const apiRequest = async ({ method, url, data, headers = {}, newDomain }) => {
  const token = localStorage.getItem("token");
  
  // Prepare headers
  const requestHeaders = {
    'Content-Type': 'application/json',
    ...headers
  };

  if (token) {
    requestHeaders["Authorization"] = `CsgPDBearer ${token}`;
  }

  // Add instance headers if not new domain
  if (!newDomain) {
    if (headers["instance-id"]) {
      requestHeaders["instance-id"] = headers["instance-id"];
    }
    if (headers["instance-composite-url"]) {
      requestHeaders["instance-composite-url"] = headers["instance-composite-url"];
    }
    if (headers.path_name) {
      requestHeaders["path_name"] = headers.path_name;
    }
  }

  // Prepare request options
  const requestOptions = {
    method: method.toUpperCase(),
    headers: requestHeaders,
    credentials: 'include'
  };

  // Add body for non-GET requests
  if (method.toUpperCase() !== 'GET' && data) {
    requestOptions.body = JSON.stringify(data);
  }

  let error = null;
  let responseData = null;
  let rawResponse = null;

  try {
    rawResponse = await fetch(cleanedURL({ url, newDomain }), requestOptions);
    responseData = await handleResponse(rawResponse);
    return [error, responseData, rawResponse];
  } catch (e) {
    error = {
      error: e,
      message: e.message,
      status: e.status || rawResponse?.status
    };
    return [error, responseData, rawResponse];
  }
};

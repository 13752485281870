import { forwardRef, useImperativeHandle } from "react";
import { StyledButton } from "./Button.styled";
import { ButtonType } from "./ButtonType";
import Icon from "../Icon";

const Button = forwardRef(
  (
    {
      onClick,
      text,
      disabled,
      type = "primary",
      icon,
      iconType = "outline",
      color,
    }: ButtonType,
    Ref: any,
  ) => {
    useImperativeHandle(Ref, () => ({}));

    return (
      <StyledButton
        onClick={onClick}
        disabled={disabled}
        $type={type}
        $color={color}
      >
        {icon && <Icon icon={icon} type={iconType} />}
        {text}
      </StyledButton>
    );
  },
);

Button.displayName = "Button";

export default Button;

import { useEffect, useMemo } from 'react';
import { useGetQuery, useApiMutation } from '@/store/fetchClientInstance';
import { apiRoutes } from '@/services';

interface Calculation {
  entity_name: string;
  critical_count: number;
  major_count: number;
  high_count: number;
  mid_count: number;
  low_count: number;
  minor_count: number;
}

interface CalculationData {
  calculation: Calculation[];
  month: string;
  subdomain: string;
}

interface AlertData {
  calculations: CalculationData[];
  customer_name: string;
  subdomain: string;
}

export const useHealthCheckAlerts = (selectedUser?: any) => {
  const isQuery = Boolean(selectedUser);

  const { 
    data: queryData, 
    isLoading: isLoadingQuery 
  } = useGetQuery({
    url: apiRoutes.getHealthCheckData,
    skip: isQuery
  });

  const [fetchData, { 
    data: mutationData, 
    isLoading: isLoadingMutation 
  }] = useApiMutation();

  useEffect(() => {
    if (selectedUser) {
      fetchData({
        url: `${apiRoutes.getHealthCheckData}/query`,
        config: {
          method: 'POST',
          body: selectedUser.length > 0 ? selectedUser : [selectedUser]
        }
      });
    }
  }, [selectedUser, fetchData]);

  const processCalculations = (calculation: Calculation[]) => {
    return calculation.map(item => {
      const counts = {
        user: 0,
        schedule: 0,
        escalation: 0,
        service: 0,
        team: 0
      };
      
      if (item.entity_name === 'user') {
        counts.user = item.critical_count + item.major_count + item.high_count + item.mid_count + item.low_count + item.minor_count;
      } else if (item.entity_name === 'schedule') {
        counts.schedule = item.critical_count + item.major_count + item.high_count + item.mid_count + item.low_count + item.minor_count;
      } else if (item.entity_name === 'escalation_policy') {
        counts.escalation = item.critical_count + item.major_count + item.high_count + item.mid_count + item.low_count + item.minor_count;
      } else if (item.entity_name === 'service') {
        counts.service = item.critical_count + item.major_count + item.high_count + item.mid_count + item.low_count + item.minor_count;
      } else if (item.entity_name === 'team') {
        counts.team = item.critical_count + item.major_count + item.high_count + item.mid_count + item.low_count + item.minor_count;
      }

      return {
        label: item.entity_name.charAt(0).toUpperCase() + item.entity_name.slice(1),
        ...counts
      };
    });
  };

  const processedData = useMemo(() => {
    const data = isQuery ? mutationData : queryData;
    if (!data) return [];
    
    return data.map((alert: AlertData) => {
      if (!alert.calculations) return null;

      const lastThreeMonths = alert.calculations.slice(-3);
      return {
        customer_name: alert.customer_name,
        data: lastThreeMonths.map(calc => ({
          month: calc.month,
          calculations: processCalculations(calc.calculation)
        }))
      };
    }).filter(alert => alert !== null);
  }, [isQuery, mutationData, queryData]);

  return {
    alertsData: processedData || [],
    isLoading: isQuery ? isLoadingMutation : isLoadingQuery
  };
};
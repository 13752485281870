
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';

export const SectionContainer = styled.div`
  margin-top: 12px;
  min-width: 100%;
  background-color: white;
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  width: 2rem;
  cursor: pointer;
`;


export const TitleContainer = styled.div`
  display: inline-flex;
  align-items: baseline;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: #117170;
  margin-right: 12px;
`;

export const IconButton = styled.div`
  width: 2rem;
  cursor: pointer;
`;

export const TableContainer = styled.div`
  margin-top: 12px;
  overflow-x: auto;
`;

export const TableWrapper = styled.div`
  min-width: 100%;
  padding: 8px 24px;
`;

export const Table = styled.table`
  min-width: 100%;
  border-collapse: separate;
  border-color: #d1d5db;
`;

export const TableHead = styled.thead`
  background-color: #ffffff;
`;

export const TableBody = styled.tbody`
  border-color: #e5e7eb;
  background-color: #ffffff;
`;

export const Th = styled.th<{ align?: string }>`
  padding: 0.875rem;
  text-align: ${props => props.align || 'left'};
  font-size: 16px;
  font-weight: 600;
  color: #111827;
`;

export const Td = styled.td<{ align?: string }>`
  white-space: nowrap;
  font-size: 14px;
  color: #6b7280;
  border-bottom: solid #F7F7F7;
  text-align: ${props => props.align || 'left'};
  padding: 0.5rem;
`;

const StatusBadge = styled.div<{ backgroundColor: string }>`
  margin-top: 4px;
  border-radius: 9999px;
  padding: 0.125rem 0.625rem;
  width: fit-content;
  background-color: ${props => props.backgroundColor};
`;

const CustomerInfo = styled.div`
  display: flex;
  margin-left: 16px;
  flex-direction: column;
  border-bottom: solid #F7F7F7;
  width: 100%;
`;

const CustomerName = styled.div`
  cursor: pointer;
  font-weight: 500;
  color: #1f2937;
`;

const SubInfo = styled.div`
  margin-top: 4px;
  font-size: 14px;
  color: #6b7280;
`;

interface ProfessionalServicesProps {
  showPS: boolean;
  setShowPS: (show: boolean) => void;
  psStatusSummary: any[];
  goToPath: (path: string) => void;
  getPsBackgroundColor: (status: string) => string;
}

export const ProfessionalServices: React.FC<ProfessionalServicesProps> = ({
  showPS,
  setShowPS,
  psStatusSummary,
  goToPath,
  getPsBackgroundColor
}) => {
  return (
    <SectionContainer>
      <Header>
        <TitleContainer>
          <Title>Professional Services</Title>
        </TitleContainer>
        <IconWrapper onClick={() => setShowPS(!showPS)}>
          {showPS ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="#117170"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 20 20" 
              fill="#117170"
              aria-hidden="true"
            >
              <path 
                fillRule="evenodd" 
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" 
                clipRule="evenodd"
              />
            </svg>
          )}
        </IconWrapper> 
      </Header>

      {psStatusSummary.length > 0 && showPS && (
        <TableContainer>
          <TableWrapper>
            <Table>
              <TableHead>
                <tr>
                  <Th>Customer Name</Th>
                  <Th>Project Name</Th>
                  <Th>Status</Th>
                  <Th>Percentage</Th>
                </tr>
              </TableHead>
              <TableBody>
                {psStatusSummary.map((item, i) => (
                  <tr key={i}>
                    <Td>
                      <CustomerInfo>
                        <CustomerName onClick={() => goToPath('PsStatus')}>
                          {item.customer_name}
                        </CustomerName>
                        <SubInfo>{item.engagement_manager}</SubInfo>
                      </CustomerInfo>
                    </Td>
                    <Td>
                      <CustomerInfo>
                        <CustomerName>{item.project_name}</CustomerName>
                        <SubInfo>{item.project_owner}</SubInfo>
                      </CustomerInfo>
                    </Td>
                    <Td>
                      <StatusBadge backgroundColor={getPsBackgroundColor(item.ps_status)}>
                        {item.ps_status}
                      </StatusBadge>
                    </Td>
                    <Td>{item.progress_percentage}</Td>
                  </tr>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        </TableContainer>
      )}
    </SectionContainer>
  );
};
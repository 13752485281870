import { sessionInfoType } from "@/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const EmptyState: sessionInfoType = {
  exp: undefined,
  iat: undefined,
  sub: "",
  name: "",
  authentication_type: "",
  role: "",
  apps: [],
  allowedRead: [],
  allowedWrite: [],
  token: "",
};

export const sessionInfoSlice = createSlice({
  name: "sessionInfo",
  initialState: EmptyState,
  reducers: {
    setSessionInfo: (state, action: PayloadAction<sessionInfoType>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearSessionInfo: () => EmptyState,
  },
});

export const { setSessionInfo, clearSessionInfo } = sessionInfoSlice.actions;

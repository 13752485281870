import { forwardRef, useImperativeHandle, useState } from "react";
import { PDInput, Icon, Modal, Dropdown, Button } from "@/components";
import {
  formModalType,
  statusType,
  v2MomType,
  v2MomTypeArray,
} from "@/models";
import {
  MembersCardIconSelectContainer,
  MembersCardIcon,
} from "../MemberCard/MemberCard.styled";
import {
  ModalDivConatiner,
  ModalSpan,
  SelectContainerStatus,
  ModalButtonsContainer,
  ModalButtonsContainerRight,
} from "./TeamFormModal.styled";
import { useDispatch } from "react-redux";
import { deepCopy } from "@/utils/shared-functions";
import { setTeamsInfo, useTeamsInfoSelector, useUserManagementInfoSelector } from "@/store/states";

const TeamFormModal = forwardRef(({ currentQuarter }: formModalType, ref) => {
  const dispatch = useDispatch();
  const userInfo = useUserManagementInfoSelector();
  const teamsInfo = useTeamsInfoSelector();
  const [modalStatus, setModalStatus] = useState<statusType>("Not Started");
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [newV2MomName, setNewV2MomName] = useState<string>("");
  const [newV2MomDescription, setNewV2MomDescription] = useState<string>("");
  const [newV2MomRelation, setNewV2MomRelation] = useState<string>("");

  useImperativeHandle(ref, () => ({
    setUser,
    setTeamUser,
  }));

  const setUser = (memberIndex: number) => {
    setSelectedUser(memberIndex);
  };

  const setTeamUser = (
    memberIndex: number,
    index: number,
    v2Mom: v2MomType,
  ) => {
    setSelectedUser(memberIndex);
    setSelectedIndex(index);
    setNewV2MomName(v2Mom.name);
    setNewV2MomDescription(v2Mom.description);
    setModalStatus(v2Mom.status);
    setNewV2MomRelation(v2Mom.relation || "");
  };

  const resetNewV2Mom = () => {
    setNewV2MomName("");
    setNewV2MomDescription("");
    setNewV2MomRelation("");
    setModalStatus("Not Started");
    setSelectedIndex(null);
    setSelectedUser(null);
  };

  const addOrEditV2MomsPeopleOnMyTeam = () => {
    const teamsInfoCopy = deepCopy(teamsInfo);
    if (selectedUser !== null && selectedIndex === null) {
      teamsInfoCopy[selectedUser].v2Moms[currentQuarter].push({
        name: newV2MomName,
        status: modalStatus,
        description: newV2MomDescription,
        relation: newV2MomRelation,
      });
    } else if (selectedUser !== null && selectedIndex !== null) {
      teamsInfoCopy[selectedUser].v2Moms[currentQuarter][selectedIndex] = {
        name: newV2MomName,
        status: modalStatus,
        description: newV2MomDescription,
        relation: newV2MomRelation,
      };
    }
    dispatch(setTeamsInfo(teamsInfoCopy));
    resetNewV2Mom();
  };

  const deleteV2MomPeopleOnMyTeam = () => {
    const teamsInfoCopy = deepCopy(teamsInfo);
    if (selectedUser !== null && selectedIndex !== null) {
      teamsInfoCopy[selectedUser].v2Moms[currentQuarter].splice(
        selectedIndex,
        1,
      );
    }
    dispatch(setTeamsInfo(teamsInfoCopy));
    resetNewV2Mom();
  };

  return (
    <Modal
      handleClose={() => resetNewV2Mom()}
      show={selectedUser !== null}
      title={"New V2Mom"}
    >
      <ModalDivConatiner>
        <ModalSpan>
          The following V2Mom will be added to{" "}
          {selectedUser !== null ? (
            <span>{teamsInfo[selectedUser].name}</span>
          ) : (
            ""
          )}
        </ModalSpan>
        <div>V2Mom Name</div>
        <PDInput
          placeholder="V2Mom Name"
          value={newV2MomName}
          onChange={(value: string) => setNewV2MomName(value)}
          validationLabel={
            selectedUser &&
            teamsInfo[selectedUser].v2Moms[currentQuarter].find(
              (v2Mom: v2MomTypeArray) => v2Mom.name === newV2MomName,
            )
              ? "V2Mom name already exists"
              : ""
          }
        />
        <div>Description</div>
        <PDInput
          placeholder="Description"
          value={newV2MomDescription}
          onChange={(value: string) => setNewV2MomDescription(value)}
        />
        <div>Relation</div>
        <Dropdown
          displayValue={"name"}
          value={newV2MomRelation}
          onChange={(value: string) => setNewV2MomRelation(value)}
          options={userInfo && userInfo.v2Moms && userInfo.v2Moms[currentQuarter]
            .filter((v2Mom: v2MomTypeArray) => v2Mom.status !== "Done")
            .map((v2Mom: v2MomTypeArray) => {
              return { name: v2Mom.name };
            })}
        />
        <div>Status</div>
        <SelectContainerStatus>
          <MembersCardIconSelectContainer
            onClick={() => setModalStatus("Not Started")}
            $statusColor={
              modalStatus === "Not Started" ? "Not Started" : "Disabled"
            }
          >
            <MembersCardIcon
              title={"Not Started"}
              $statusColor={
                modalStatus === "Not Started" ? "Not Started" : "Disabled"
              }
            >
              <Icon icon={"BellAlertIcon"} type={"outline"} />
            </MembersCardIcon>
            Not Started
          </MembersCardIconSelectContainer>
          <MembersCardIconSelectContainer
            onClick={() => setModalStatus("In Progress")}
            $statusColor={
              modalStatus === "In Progress" ? "In Progress" : "Disabled"
            }
          >
            <MembersCardIcon
              title={"In Progress"}
              $statusColor={
                modalStatus === "In Progress" ? "In Progress" : "Disabled"
              }
            >
              <Icon icon={"RocketLaunchIcon"} type={"outline"} />
            </MembersCardIcon>
            In Progress
          </MembersCardIconSelectContainer>
          <MembersCardIconSelectContainer
            onClick={() => setModalStatus("Done")}
            $statusColor={modalStatus === "Done" ? "Done" : "Disabled"}
          >
            <MembersCardIcon
              title={"Done"}
              $statusColor={modalStatus === "Done" ? "Done" : "Disabled"}
            >
              <Icon icon={"CheckCircleIcon"} type={"outline"} />
            </MembersCardIcon>
            Done
          </MembersCardIconSelectContainer>
          <MembersCardIconSelectContainer
            onClick={() => setModalStatus("Blocked")}
            $statusColor={modalStatus === "Blocked" ? "Blocked" : "Disabled"}
          >
            <MembersCardIcon
              title={"Blocked"}
              $statusColor={modalStatus === "Blocked" ? "Blocked" : "Disabled"}
            >
              <Icon icon={"NoSymbolIcon"} type={"outline"} />
            </MembersCardIcon>
            Blocked
          </MembersCardIconSelectContainer>
        </SelectContainerStatus>
        <ModalButtonsContainer>
          <div>
            {selectedIndex !== null && (
              <Button
                text="Delete"
                type="secondary"
                color="#f44336"
                onClick={() => deleteV2MomPeopleOnMyTeam()}
              />
            )}
          </div>
          <ModalButtonsContainerRight>
            <Button
              text={selectedIndex !== null ? "Edit" : "Add"}
              type="secondary"
              color="#048a24"
              onClick={() => {
                addOrEditV2MomsPeopleOnMyTeam();
              }}
              disabled={
                newV2MomName === "" ||
                newV2MomDescription === "" ||
                newV2MomRelation === ""
              }
            />
            <Button
              text="Cancel"
              type="secondary"
              color="#f44336"
              onClick={() => {
                resetNewV2Mom();
              }}
            />
          </ModalButtonsContainerRight>
        </ModalButtonsContainer>
      </ModalDivConatiner>
    </Modal>
  );
});

TeamFormModal.displayName = "TeamFormModal";

export default TeamFormModal;

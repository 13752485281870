import { useCallback, useEffect, useRef, useState } from "react";
import {
  ChevronRowIconsContainer,
  ChevronRowTitle,
  ChevronRowTitleContainer,
  FormLabel,
  FormTextArea,
  ModalButtonsContainer,
  ModalButtonsContainerRight,
  ModalContainer,
  PageContainer,
  SpanButton,
  SpanIcon,
  StatusTag,
} from "@/common.styled.tsx";
import {
  Alerts,
  Button,
  DragDrop,
  DragDropList,
  Dropdown,
  Icon,
  Modal,
} from "@/components";
import TaskCard from "./TaskCard";
import { useDispatch } from "react-redux";
import {
  capitalizeName,
  deepCopy,
  getAllUsers,
  toggleChevronIcon,
} from "@/utils/shared-functions.js";
import {
  ContainerFilters,
  DragDropContainerDiv,
  FilterTitle,
  InitialsContainer,
} from "./Board.styled.tsx";
import {
  managementReleasesType,
  statusElementsListType,
  statusElementsType,
  tasksTypeExtended,
} from "@/models";
import { IconsType } from "@/components/Icon/IconType.ts";
import { Alert } from "@/components/Alerts/Alerts.model.ts";
import {
  setManagementReleases,
  useManagementReleasesSelector,
  useStatusElementsSelector,
  useTeamsTreeSelector,
  useUserManagementInfoSelector,
} from "@/store/states";
import {
  DeleteElementIcon,
  DragDropListContainer,
  StatusDragDropDiv,
} from "../Releases/StatusSettings/StatusSettings.styled.tsx";

function Board() {
  const dispatch = useDispatch();
  const dropdownRef = useRef<{ setDropdownState: () => void } | null>(null);
  const teamsTree = useTeamsTreeSelector();
  const statusElements = useStatusElementsSelector();
  const releases = useManagementReleasesSelector();
  const userInfo = useUserManagementInfoSelector();
  const [openReleses, setOpenReleases] = useState([releases[0].id]);
  const [releasesCopy, setReleasesCopy] = useState(deepCopy(releases));
  const [reasonFor, setReasonFor] = useState("");
  const [taskId, setTaskId] = useState<string | null>(null);
  const [selectedColumnName, setSelectedColumnName] = useState<string | null>(
    null
  );
  const [allUsers] = useState<any>(getAllUsers(teamsTree));
  const [currentUsersInFilter, setCurrentUsersInFilter] = useState<
    string[] | null
  >(null);
  const [openCreateNewStatusListModal, setOpenEditStatusListModal] =
    useState(false);
  const [currentStatusList, setCurrentStatusList] =
    useState<statusElementsListType>([]);
  const [selectedRelease, setSelectedRelease] =
    useState<managementReleasesType | null>(null);
  const [statusElementsListOptions, setStatusElementsListOptions] = useState<
    {
      color: string;
      icon: IconsType;
      name: string;
      reasonFor: boolean;
      value: string;
    }[]
  >([]);

  const [alertsElements, setAlertsElements] = useState<Alert[]>([
    {
      type: "info",
      text: "You can remove a status only if it has no tasks",
    },
  ]);

  const getStatusElementsListOptions = useCallback(() => {
    const options = statusElements
      .filter(
        (status: statusElementsType) =>
          !currentStatusList
            .map((status: statusElementsType) => status.name)
            .includes(status.name)
      )
      .map((status: statusElementsType) => {
        return { value: status.name, ...status };
      });
    return options;
  }, [currentStatusList, statusElements]);

  useEffect(() => {
    setStatusElementsListOptions(getStatusElementsListOptions());
  }, [currentStatusList, statusElements, getStatusElementsListOptions]);

  const toggleRelese = (id: string) => {
    if (openReleses.includes(id)) {
      setOpenReleases(openReleses.filter((releaseId) => releaseId !== id));
    } else {
      setOpenReleases([...openReleses, id]);
    }
  };

  const getRelestColumnsofTasks = (release: managementReleasesType) => {
    const statusCategories: { [key: string]: tasksTypeExtended[] } =
      release.statusList.reduce((acc, status) => {
        acc[status.name] = [];
        return acc;
      }, {} as { [key: string]: tasksTypeExtended[] });

    release.stages.forEach((stage) => {
      stage.features.forEach((feature) => {
        feature.tasks.forEach((task) => {
          statusCategories[task.status.name].push({
            id: task.id,
            name: task.name,
            task,
            release,
            stage,
            feature,
          });
        });
      });
    });

    return Object.entries(statusCategories).map(([name, items]) => ({
      name,
      items,
      color: release.statusList.find((status) => status.name === name)?.color,
    }));
  };

  useEffect(() => {
    setReleasesCopy(null);

    setTimeout(() => {
      let releasesCopy = deepCopy(releases);
      releasesCopy = releasesCopy.map((release: managementReleasesType) => {
        release.stages = release.stages.map((stage) => {
          stage.features = stage.features.map((feature) => {
            feature.tasks = feature.tasks.filter((task) => {
              if (currentUsersInFilter) {
                return currentUsersInFilter.includes(task.assignedTo);
              }
              return true;
            });
            return feature;
          });
          return stage;
        });
        return release;
      });
      setReleasesCopy(releasesCopy);
    }, 0);
  }, [releases, currentUsersInFilter]);

  const handleDrop = (item: tasksTypeExtended, columnName: string) => {
    const releasesCopy = [
      ...releases.map((release: managementReleasesType) => ({
        ...release,
        stages: release.stages.map((stage) => ({
          ...stage,
          features: stage.features.map((feature) => ({
            ...feature,
            tasks: feature.tasks.map((task) => {
              if (task.id === item.task.id) {
                return {
                  ...task,
                  status: release.statusList.find(
                    (status) => status.name === columnName
                  ) as statusElementsType,
                  reasonFor: null,
                };
              }
              return task;
            }),
          })),
        })),
      })),
    ];
    dispatch(setManagementReleases(releasesCopy));
  };

  const blockTask = () => {
    const releasesCopy = [
      ...releases.map((release: managementReleasesType) => ({
        ...release,
        stages: release.stages.map((stage) => ({
          ...stage,
          features: stage.features.map((feature) => ({
            ...feature,
            tasks: feature.tasks.map((task) => {
              if (task.id === taskId) {
                return {
                  ...task,
                  reasonFor: reasonFor,
                  status: release.statusList.find(
                    (status) => status.name === selectedColumnName
                  ) as statusElementsType,
                };
              }
              return task;
            }),
          })),
        })),
      })),
    ];
    dispatch(setManagementReleases(releasesCopy));
    setTaskId(null);
    setSelectedColumnName(null);
    setReasonFor("");
  };

  const editWithReasonFor = (task: tasksTypeExtended, columnName: string) => {
    setTaskId(task.task.id);
    setSelectedColumnName(columnName);
  };

  const dragAndDropElements = (release: managementReleasesType) => {
    if (openReleses.includes(release.id)) {
      const columns = getRelestColumnsofTasks(release);
      return (
        <DragDropContainerDiv>
          <DragDrop
            showNumberOfElements
            columns={columns}
            onDrop={(item: tasksTypeExtended, columnName: string) => {
              release.statusList.find((status) => status.name === columnName)
                ?.reasonFor
                ? editWithReasonFor(item, columnName)
                : handleDrop(item, columnName);
            }}
            renderItem={TaskCard}
          />
        </DragDropContainerDiv>
      );
    }
    return null;
  };

  const editReleaseStatusList = () => {
    const releasesCopy = [
      ...releases.map((release: managementReleasesType) => {
        if (release.id === selectedRelease?.id) {
          return {
            ...release,
            statusList: currentStatusList,
          };
        }
        return release;
      }),
    ];
    dispatch(setManagementReleases(releasesCopy));
    setOpenEditStatusListModal(false);
  };

  const getNumberOfTaskByStatus = (
    status: string,
    release: managementReleasesType
  ) => {
    let count = 0;
    release.stages.forEach((stage) => {
      stage.features.forEach((feature) => {
        feature.tasks.forEach((task) => {
          if (task.status.name === status) {
            count++;
          }
        });
      });
    });
    return count;
  };

  return (
    <>
      <PageContainer>
        <div>
          <FilterTitle>Filter by users</FilterTitle>
          <ContainerFilters>
            <Button
              text="All"
              type={currentUsersInFilter ? "secondary" : "primary"}
              color="#048a24"
              onClick={() => setCurrentUsersInFilter(null)}
            />
            <Button
              text="Mine"
              type={
                currentUsersInFilter &&
                currentUsersInFilter.length === 1 &&
                currentUsersInFilter[0] === userInfo.name
                  ? "primary"
                  : "secondary"
              }
              color="#048a24"
              onClick={() => {
                setCurrentUsersInFilter([userInfo.name]);
              }}
            />
            {allUsers.map((user: any) => (
              <InitialsContainer
                key={user.name}
                title={user.name}
                onClick={() => {
                  setCurrentUsersInFilter(
                    currentUsersInFilter?.includes(user.name)
                      ? currentUsersInFilter.filter(
                          (name) => name !== user.name
                        )
                      : [...(currentUsersInFilter || []), user.name]
                  );
                }}
                color={
                  currentUsersInFilter?.includes(user.name)
                    ? user.color
                    : "#8f8f8f"
                }
              >
                {capitalizeName(user.name)}
              </InitialsContainer>
            ))}
          </ContainerFilters>
        </div>
        {releasesCopy?.map((release: managementReleasesType) => (
          <div key={release.id}>
            <ChevronRowTitleContainer color={release.status.color}>
              <ChevronRowTitle>{release.name}</ChevronRowTitle>
              <ChevronRowIconsContainer>
                <SpanIcon
                  onClick={() => {
                    setCurrentStatusList(release.statusList);
                    setSelectedRelease(release);
                    setOpenEditStatusListModal(true);
                  }}
                >
                  <Icon icon={"PencilSquareIcon"} type={"outline"} />
                </SpanIcon>
                <SpanButton
                  onClick={() => {
                    toggleRelese(release.id);
                  }}
                >
                  <Icon
                    icon={toggleChevronIcon(openReleses.includes(release.id))}
                    type={"outline"}
                  />
                </SpanButton>
              </ChevronRowIconsContainer>
            </ChevronRowTitleContainer>
            {dragAndDropElements(release)}
          </div>
        ))}
      </PageContainer>
      <Modal
        handleClose={() => setTaskId(null)}
        show={!!taskId}
        title={`Move to ${selectedColumnName}`}
        modalFitContent={true}
      >
        <ModalContainer>
          <FormLabel>
            Please give a reason for Moving this task to {selectedColumnName}
          </FormLabel>
          <FormLabel $mandatory="true">
            Moving to {selectedColumnName} because:
          </FormLabel>
          <FormTextArea
            value={reasonFor}
            onChange={(value) => setReasonFor(value.target.value)}
            placeholder="Reason for moving this task"
          />
          <ModalButtonsContainer>
            <Button
              text={`Move to ${selectedColumnName}`}
              type="secondary"
              color="#f44336"
              disabled={!reasonFor}
              onClick={() => {
                blockTask();
              }}
            />
            <Button
              text="Cancel"
              type="secondary"
              color="#048a24"
              onClick={() => {
                setTaskId(null);
              }}
            />
          </ModalButtonsContainer>
        </ModalContainer>
      </Modal>
      <Modal
        handleClose={() => {
          setOpenEditStatusListModal(false);
          dropdownRef.current?.setDropdownState?.();
        }}
        show={openCreateNewStatusListModal}
        title={"Edit Status List"}
      >
        <ModalContainer>
          <FormLabel>Add Status</FormLabel>
          <Dropdown
            ref={dropdownRef}
            options={statusElementsListOptions}
            removeAfterAdd
            title="Select Status"
            onChange={(value, option) => {
              setCurrentStatusList([...currentStatusList, option]);
            }}
            displayValue={"name"}
          />
          <DragDropListContainer>
            <DragDropList
              items={currentStatusList}
              onChange={(newItems: statusElementsListType) =>
                setCurrentStatusList(newItems)
              }
              renderItem={({ name, color, icon }: statusElementsType) => {
                const numberOfTask = getNumberOfTaskByStatus(
                  name,
                  selectedRelease as managementReleasesType
                );
                return (
                  <StatusDragDropDiv>
                    <StatusTag $statusColor={color}>
                      <Icon icon={icon as IconsType} type={"outline"} />
                      {name} ({numberOfTask})
                    </StatusTag>
                    {!numberOfTask && (
                      <DeleteElementIcon
                        onClick={() =>
                          setCurrentStatusList(
                            currentStatusList.filter(
                              (status: any) => status.name !== name
                            )
                          )
                        }
                      >
                        <Icon icon="XMarkIcon" type={"outline"} />
                      </DeleteElementIcon>
                    )}
                  </StatusDragDropDiv>
                );
              }}
            />
          </DragDropListContainer>
          <Alerts
            alerts={alertsElements}
            onRemove={(index: number) =>
              setAlertsElements(alertsElements.filter((_, i) => i !== index))
            }
          />
          <ModalButtonsContainer>
            <div />
            <ModalButtonsContainerRight>
              <Button
                text="Edit"
                type="secondary"
                color="#048a24"
                onClick={() => {
                  editReleaseStatusList();
                }}
                disabled={currentStatusList.length === 0}
              />
              <Button
                text="Cancel"
                type="secondary"
                color="#f44336"
                onClick={() => {
                  setOpenEditStatusListModal(false);
                  dropdownRef.current?.setDropdownState?.();
                }}
              />
            </ModalButtonsContainerRight>
          </ModalButtonsContainer>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default Board;

import { useRef, useState } from "react";
import {
  ChevronRowIconsContainer,
  ChevronRowTitle,
  ChevronRowTitleContainer,
  ChevronRowTitleContainerTop,
  PageContainer,
  SpanButton,
} from  "@/common.styled.tsx";
import {
  Carousel,
  Icon,
  ProfileCircle,
  QuartersPill,
  SelectStatus,
} from "@/components";
import { currentQuarterType } from "@/components/QuartersPill/QuartersPillType.ts";
import {
  Container,
  ContainerCards,
  ContainerCardsUpper,
  MemberCardContainer,
  NoDoneStatusTexy,
  NothingHere,
  PeopleOnMyTeamCardsContainer,
  PeopleOnMyTeamContainer,
  TopContainer,
} from "./V2Moms.styled.tsx";
import { toggleChevronIcon } from "@/utils/shared-functions"
import MemberCardComponent from "./MemberCard/index.ts";
import TeamFormModal from "./TeamFormModal/index.ts";
import FormModal from "./FormModal/index.ts";
import { IconsType } from "@/components/Icon/IconType.ts";
import { statusType } from "@/models/status-type.model.ts";
import { teamsInfoType, v2MomTypeArray } from "@/models/index.ts";
import { useSuperiorInfoSelector, useTeamsInfoSelector, useUserManagementInfoSelector } from "@/store/states";

function V2Moms() {
  const formModal = useRef<any>();
  const teamFormModal = useRef<any>();
  const superiorInfo = useSuperiorInfoSelector();
  const userInfo = useUserManagementInfoSelector();
  const teamsInfo = useTeamsInfoSelector();
  const [currentQuarter, setCurrentQuarter] =
    useState<currentQuarterType>("Q1");
  const [peopleOnMyTeamOpenArray, setPeopleOnMyTeamOpenArray] = useState<
    Array<number>
  >([]);
  const [peopleOnMyTeamOpenArrayDone, setPeopleOnMyTeamOpenArrayDone] =
    useState<Array<number>>([]);
  const [peopleOnMyTeamOpen, setPeopleOnMyTeamOpen] = useState<boolean>(false);
  const [myV2MomsOpen, setMyV2MomsOpen] = useState<boolean>(false);
  const [myV2MomsOpenDone, setMyV2MomsOpenDone] = useState<boolean>(false);
  const [currentStatusFilters, setCurrentStatusFilters] = useState<
    Array<statusType>
  >(["In Progress", "Not Started", "Blocked"]);

  const handlePeopleOnMyTeamOpen = (index: number) => {
    if (peopleOnMyTeamOpenArray.includes(index)) {
      setPeopleOnMyTeamOpenArray(
        peopleOnMyTeamOpenArray.filter((item) => item !== index),
      );
    } else {
      setPeopleOnMyTeamOpenArray([...peopleOnMyTeamOpenArray, index]);
    }
  };

  const handlePeopleOnMyTeamOpenDone = (index: number) => {
    if (peopleOnMyTeamOpenArrayDone.includes(index)) {
      setPeopleOnMyTeamOpenArrayDone(
        peopleOnMyTeamOpenArrayDone.filter((item) => item !== index),
      );
    } else {
      setPeopleOnMyTeamOpenArrayDone([...peopleOnMyTeamOpenArrayDone, index]);
    }
  };

  const updateCurrentStatusFilters = (status: statusType) => {
    if (currentStatusFilters.includes(status)) {
      setCurrentStatusFilters(
        currentStatusFilters.filter((item) => item !== status),
      );
    } else {
      setCurrentStatusFilters([...currentStatusFilters, status]);
    }
  };

  const onIconClicked = (icon: IconsType) => {
    if (icon === "ChevronDownIcon" || icon === "ChevronUpIcon") {
      setMyV2MomsOpen(!myV2MomsOpen);
    }
    if (icon === "PlusCircleIcon") {
      formModal?.current?.addNewV2Mom();
    }
  };

  return (
    <>
      <PageContainer>
        <TopContainer>
          <QuartersPill
            onClick={(quarter: currentQuarterType) => {
              setCurrentQuarter(quarter);
            }}
            currentQuarter={currentQuarter}
          />
          <SelectStatus
            currentStatusFilters={currentStatusFilters}
            updateCurrentStatusFilters={updateCurrentStatusFilters}
          />
        </TopContainer>
        <Container>
          <ContainerCardsUpper>
            <ProfileCircle
              title={superiorInfo.name}
              users={[{ name: superiorInfo.name, color: superiorInfo.color }]}
            />
            {superiorInfo.v2Moms[currentQuarter].length > 0 ? (
              <MemberCardContainer>
                <Carousel interval={5000}>
                  {superiorInfo.v2Moms[currentQuarter].map(
                    (v2Mom: v2MomTypeArray) => (
                      <MemberCardComponent
                        key={v2Mom.name}
                        {...v2Mom}
                        isSuperior={true}
                        currentQuarter={currentQuarter}
                      />
                    ),
                  )}
                </Carousel>
              </MemberCardContainer>
            ) : (
              <NothingHere>No V2Moms</NothingHere>
            )}
          </ContainerCardsUpper>
          <ContainerCardsUpper>
            <ProfileCircle
              title={userInfo?.name}
              users={[{ name: userInfo?.name, color: userInfo?.color }]}
              arrayOfIcons={[
                { icon: "PlusCircleIcon", type: "outline" },
                { icon: toggleChevronIcon(myV2MomsOpen), type: "outline" },
              ]}
              onIconClick={(icon) => {
                onIconClicked(icon);
              }}
            />
            {myV2MomsOpen && (
              <>
                {userInfo?.v2Moms[currentQuarter].filter(
                  (v2Mom: v2MomTypeArray) =>
                    currentStatusFilters.includes(v2Mom.status),
                ).length > 0 ? (
                  <MemberCardContainer>
                    {userInfo?.v2Moms[currentQuarter]
                      .filter((v2Mom: v2MomTypeArray) =>
                        currentStatusFilters.includes(v2Mom.status),
                      )
                      .map((v2Mom: v2MomTypeArray, index: number) => (
                        <MemberCardComponent
                          key={v2Mom.name}
                          {...v2Mom}
                          isSuperior={false}
                          currentQuarter={currentQuarter}
                          onClickEdit={() => {
                            formModal?.current?.editV2Mom(index, v2Mom);
                          }}
                        />
                      ))}
                  </MemberCardContainer>
                ) : (
                  <NothingHere>No V2Moms</NothingHere>
                )}
                <ChevronRowTitleContainerTop $isOpen={myV2MomsOpenDone}>
                  <ChevronRowTitle>Done</ChevronRowTitle>
                  <ChevronRowIconsContainer>
                    <SpanButton
                      onClick={() => {
                        setMyV2MomsOpenDone(!myV2MomsOpenDone);
                      }}
                    >
                      <Icon
                        icon={toggleChevronIcon(myV2MomsOpenDone)}
                        type={"outline"}
                      />
                    </SpanButton>
                  </ChevronRowIconsContainer>
                </ChevronRowTitleContainerTop>
                {myV2MomsOpenDone && (
                  <>
                    {userInfo?.v2Moms[currentQuarter].filter(
                      (v2Mom: v2MomTypeArray) => v2Mom.status === "Done",
                    ).length > 0 ? (
                      <MemberCardContainer>
                        {userInfo?.v2Moms[currentQuarter]
                          .filter(
                            (v2Mom: v2MomTypeArray) => v2Mom.status === "Done",
                          )
                          .map((v2Mom: v2MomTypeArray) => (
                            <MemberCardComponent
                              key={v2Mom.name}
                              {...v2Mom}
                              isSuperior={false}
                              currentQuarter={currentQuarter}
                            />
                          ))}
                      </MemberCardContainer>
                    ) : (
                      <NothingHere>
                        No V2Moms with status{" "}
                        <NoDoneStatusTexy>Done</NoDoneStatusTexy>
                      </NothingHere>
                    )}
                  </>
                )}
              </>
            )}
          </ContainerCardsUpper>
          <ContainerCards>
            <ProfileCircle
              title={"People on my teams"}
              users={teamsInfo}
              arrayOfIcons={[
                {
                  icon: toggleChevronIcon(peopleOnMyTeamOpen),
                  type: "outline",
                },
              ]}
              onIconClick={(icon) =>
                (icon === "ChevronDownIcon" || icon === "ChevronUpIcon") &&
                setPeopleOnMyTeamOpen(!peopleOnMyTeamOpen)
              }
            />
            {peopleOnMyTeamOpen && (
              <ContainerCardsUpper>
                {teamsInfo.map((member: teamsInfoType, memberIndex: number) => (
                  <PeopleOnMyTeamContainer key={member.name + memberIndex}>
                    <ChevronRowTitleContainer>
                      <ChevronRowTitle>{member.name}</ChevronRowTitle>
                      <ChevronRowIconsContainer>
                        <SpanButton
                          onClick={() => {
                            teamFormModal?.current?.setUser(memberIndex);
                          }}
                        >
                          <Icon icon={"PlusCircleIcon"} type={"outline"} />
                        </SpanButton>
                        <SpanButton
                          onClick={() => {
                            handlePeopleOnMyTeamOpen(memberIndex);
                          }}
                        >
                          <Icon
                            icon={toggleChevronIcon(
                              peopleOnMyTeamOpenArray.includes(memberIndex),
                            )}
                            type={"outline"}
                          />
                        </SpanButton>
                      </ChevronRowIconsContainer>
                    </ChevronRowTitleContainer>
                    {peopleOnMyTeamOpenArray.includes(memberIndex) && (
                      <>
                        {member.v2Moms[currentQuarter].filter((v2Mom) =>
                          currentStatusFilters.includes(v2Mom.status),
                        ).length > 0 ? (
                          <PeopleOnMyTeamCardsContainer>
                            {member.v2Moms[currentQuarter]
                              .filter((v2Mom) =>
                                currentStatusFilters.includes(v2Mom.status),
                              )
                              .map((v2Mom, index) => (
                                <MemberCardComponent
                                  key={v2Mom.name}
                                  {...v2Mom}
                                  isSuperior={false}
                                  currentQuarter={currentQuarter}
                                  isFromTeam={true}
                                  onClickEdit={() => {
                                    teamFormModal?.current?.setTeamUser(
                                      memberIndex,
                                      index,
                                      v2Mom,
                                    );
                                  }}
                                />
                              ))}
                          </PeopleOnMyTeamCardsContainer>
                        ) : (
                          <NothingHere>No V2Moms</NothingHere>
                        )}

                        <ChevronRowTitleContainer>
                          <ChevronRowTitle>Done</ChevronRowTitle>
                          <ChevronRowIconsContainer>
                            <SpanButton
                              onClick={() => {
                                handlePeopleOnMyTeamOpenDone(memberIndex);
                              }}
                            >
                              <Icon
                                icon={toggleChevronIcon(
                                  peopleOnMyTeamOpenArrayDone.includes(
                                    memberIndex,
                                  ),
                                )}
                                type={"outline"}
                              />
                            </SpanButton>
                          </ChevronRowIconsContainer>
                        </ChevronRowTitleContainer>
                        {peopleOnMyTeamOpenArrayDone.includes(memberIndex) && (
                          <>
                            {member.v2Moms[currentQuarter].filter(
                              (v2Mom) => v2Mom.status === "Done",
                            ).length > 0 ? (
                              <PeopleOnMyTeamCardsContainer>
                                {member.v2Moms[currentQuarter]
                                  .filter((v2Mom) => v2Mom.status === "Done")
                                  .map((v2Mom) => (
                                    <MemberCardComponent
                                      key={v2Mom.name}
                                      currentQuarter={currentQuarter}
                                      isFromTeam={true}
                                      {...v2Mom}
                                      isSuperior={false}
                                    />
                                  ))}
                              </PeopleOnMyTeamCardsContainer>
                            ) : (
                              <NothingHere>
                                No V2Moms with status{" "}
                                <NoDoneStatusTexy>Done</NoDoneStatusTexy>
                              </NothingHere>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </PeopleOnMyTeamContainer>
                ))}
              </ContainerCardsUpper>
            )}
          </ContainerCards>
        </Container>
      </PageContainer>
      <FormModal ref={formModal} currentQuarter={currentQuarter} />
      <TeamFormModal ref={teamFormModal} currentQuarter={currentQuarter} />
    </>
  );
}

export default V2Moms;

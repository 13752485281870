import { useState, useEffect, useRef } from 'react';
import type { InstanceHeaders, SolutionProduct } from '../../services/api-types';
import { useGetQuery } from '@/store/fetchClientInstance';
import { apiRoutes } from '../../services';

export const useSolutionProduct = (instanceHeaders?: InstanceHeaders) => {
  const [cardTop, setCardTop] = useState<SolutionProduct[]>([]);
  const [card, setCard] = useState<SolutionProduct[]>([]);
  const originalCardListRef = useRef<SolutionProduct[]>([]);

  const {
    data,
    isLoading,
    error,
    refetch
  } = useGetQuery({
    url: apiRoutes.solutionProduct,
    config: {
      method: 'GET',
      newDomain: false,
      instanceHeaders
    }
  });

  useEffect(() => {
    if (data && Array.isArray(data?.data)) {
      const top: SolutionProduct[] = [];
      const cards: SolutionProduct[] = [];
      
      data?.data?.forEach(item => {
        if (item?.top !== null) {
          top.push(item);
        } else {
          cards.push(item);
        }
      });
      
      top.sort((a, b) => (a.top || 0) - (b.top || 0));
      
      originalCardListRef.current = data;
      setCardTop(top);
      setCard(cards);
    }
  }, [data]);

  return {
    cardTop,
    setCardTop,
    setCard,
    card,
    originalCardList: originalCardListRef.current,
    isLoading,
    error,
    refetch,
  };
};
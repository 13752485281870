import React, { ReactNode, useState } from 'react';
import { InfoIcon, TooltipContainer, TooltipContent } from './Tooltip.styled';

interface TooltipProps {
  backgroundColor?: string;
  children?: ReactNode;
  content: ReactNode;
  delay?: number;
  position?: 'top' | 'bottom' | 'left' | 'right';
  textColor?: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  backgroundColor,
  children,
  content,
  delay = 300,
  position = 'bottom',
  textColor,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  let timeoutId: NodeJS.Timeout;

  const showTooltip = () => {
    timeoutId = setTimeout(() => setIsVisible(true), delay);
  };

  const hideTooltip = () => {
    clearTimeout(timeoutId);
    setIsVisible(false);
  };

  return (
    <TooltipContainer
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onFocus={showTooltip}
      onBlur={hideTooltip}
    >
      {children || <InfoIcon aria-hidden='true'>?</InfoIcon>}
      <TooltipContent
        backgroundColor={backgroundColor}
        isVisible={isVisible}
        position={position}
        textColor={textColor}
        role='tooltip'
      >
        {content}
      </TooltipContent>
    </TooltipContainer>
  );
};

export { Tooltip };

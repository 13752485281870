import { userManagementInfoType } from "@/models";

export const userManagementInfoMock = <userManagementInfoType>{
  name: "Amber Russek",
  color: "#e075c0",
  goals: {
    Q1: [
      {
        name: "Clean up Santiago montains",
        status: "Not Started",
        type: "Event",
        associations: [100009, 100008, 100011],
        description:
          "Clean up the Santiago montains from trash and other waste",
      },
      {
        name: "Create a database using MongoDB",
        status: "Done",
        type: "Course",
        associations: [100009, 100002, 100011],
        description:
          "Create a database using MongoDB and NodeJS for a new project",
      },
    ],
    Q2: [],
    Q3: [],
    Q4: [],
  },
  v2Moms: {
    Q1: [
      {
        name: "Automate the process of creating a new user",
        status: "Not Started",
        description:
          "Automate the process of creating a new user in the new portal infrastructure",
        relation: "Onboard new team member",
      },
      {
        name: "Create a new user interface",
        status: "Done",
        description:
          "Create a new user interface for the new portal infrastructure",
        relation: "Finish portal migration",
      },
    ],
    Q2: [],
    Q3: [],
    Q4: [],
  },
  timeoff: [
    new Date("2024-12-31"),
    new Date("2024-12-07"),
    new Date("2025-06-31"),
  ],
};

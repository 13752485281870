import  styled  from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: white;
`;

type InitialsContainerInterface = {
  $color: string;
  $index: number;
};

export const InitialsContainer = styled.div<InitialsContainerInterface>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${(props) => props.$color};
  border: 2px solid #e5e7eb;
  box-shadow: 0 0 0 2px #8f8f8f;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${(props) => props.$index};
  font-size: 16px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

export const CircleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const CirclesContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const IconLeftContainer = styled.div`
display: flex;
align-items: flex-end;
justify-content: flex-end;
height: 50px;
color: #8f8f8f;
   & svg {
   width: 20px;
   height: 20px;
   `;

export const IconContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
height: 50px;
color: #8f8f8f;
   & svg {
   width: 24px;
   height: 24px;
   `;

export const IconsRightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

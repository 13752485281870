import styled from "styled-components";

export const ColorSwatch = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  background: ${({ color }) => color};
  border: 1px solid #ddd;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
`;

export const HiddenInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

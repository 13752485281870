import { useState } from "react";
import { PageContainer } from "@/common.styled.tsx";
import { Alerts } from "@/components";
import { Alert } from "@/components/types.ts";

function Dashboard() {
  const [alerts, setAlerts] = useState<Alert[]>([
    {
      type: "info",
      text: "Here you can see the status of your Goals and the progress you have made.",
    },
  ]);

  return (
    <PageContainer>
      <Alerts
        alerts={alerts}
        onRemove={(index: number) =>
          setAlerts(alerts.filter((_, i) => i !== index))
        }
      />
    </PageContainer>
  );
}

export default Dashboard;

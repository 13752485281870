import styled from 'styled-components';

export const SelectLabel = styled.label`
  display: block;
  margin: 0;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 14px;
`;

export const OptionalLabel = styled.span`
  font-weight: 100;
  color: #646464;
`;

export const CustomSelectWrapper = styled.div`
  position: relative;
`;

export const SelectedOption = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px 30px 10px 10px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
`;

export const SelectArrow = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
`;

export const SelectItems = styled.ul`
  position: absolute;
  background-color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 0;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const SelectItem = styled.li`
  padding: 10px;
  cursor: pointer;
  list-style: none;
  font-size: 14px;
  color: #333;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const SelectContainer = styled.div`
  &.default .select-selected {
    background-color: #f8f8f8;
  }

  &.default ${SelectItems} li {
    border-bottom: none;
  }

  &.default ${SelectItems} li.selected {
    background-color: #e6f2ff;
    color: #007bff;
  }

  &.default ${SelectItems} li.selected::before {
    content: '✓';
    margin-right: 10px;
    color: #007bff;
  }

  &.consultant ${SelectItems} li {
    border-bottom: 1px solid #e0e0e0;
  }

  &.consultant ${SelectItems} li:last-child {
    border-bottom: none;
  }

  &.consultant ${SelectItems} li.selected {
    background-color: #e6f2ff;
  }
`;

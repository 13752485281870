import { teamsTreeType } from "@/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const EmptyState: teamsTreeType[] = [];

export const teamsTreeSlice = createSlice({
  name: "teamsTree",
  initialState: EmptyState,
  reducers: {
    setTeamsTree: (state, action: PayloadAction<teamsTreeType[]>) => {
      return [...action.payload];
    },
    clearTeamsTree: () => EmptyState,
  },
});

export const { setTeamsTree, clearTeamsTree } = teamsTreeSlice.actions;
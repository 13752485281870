import styled from 'styled-components';
import { faWarning,faCircleCheck,faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SectionContainer = styled.div`
  margin-top: 12px;
  min-width: 100%;
  background-color: white;
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: inline-flex;
  align-items: baseline;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: #117170;
  margin-right: 12px;
`;

const IconWrapper = styled.div`
  width: 2rem;
  cursor: pointer;
`;

const TableContainer = styled.div`
  margin-top: 12px;
  overflow-x: auto;
`;

const TableWrapper = styled.div`
  min-width: 100%;
  padding: 8px 24px;
`;

const Table = styled.table`
  min-width: 100%;
  border-collapse: separate;
  border-color: #d1d5db;
`;

const TableHead = styled.thead`
  tr {
    th {
      padding: 5px;
      text-align: ${props => props.align || 'left'};
      font-size: 16px;
      font-weight: 600;
      color: #1f2937;
    }
  }
`;

const TableBody = styled.tbody`
  border-color: #e5e7eb;
  background-color: #ffffff;
`;

const TableRow = styled.tr`
  white-space: nowrap;
  font-size: 14px;
`;

const NameCell = styled.td`
  white-space: nowrap;
  font-size: 14px;
  border-bottom: solid #F7F7F7;
`;

const StatusCell = styled.td`
  white-space: nowrap;
  font-size: 14px;
  color: #6b7280;
  border-bottom: solid #F7F7F7;
  display: flex;
  margin: auto;
  align-items: center;
  height: 44.5px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 0.375rem;
  height: 15px;
  width: 15px;
  color: ${props => props.color};
`;

const HoursCell = styled.td<{ isPositive?: boolean }>`
  white-space: nowrap;
  font-size: 14px;
  color: #6b7280;
  border-bottom: solid #F7F7F7;
  text-align: center;
  color: ${props => props.isPositive ? '#6b7280' : 'red'};
`;

const ExpirationCell = styled.td`
  white-space: nowrap;
  font-size: 14px;
  color: #6b7280;
  text-align: center;
  border-bottom: solid #F7F7F7;
`;

export const ServiceRequestSection = ({
  showServiceRequest,
  setShowServiceRequest,
  serviceRequest,
}) => {
  return (
    <SectionContainer>
      <Header>
        <TitleContainer>
          <Title>Service Request Summary</Title>
        </TitleContainer>
        <IconWrapper onClick={() => setShowServiceRequest(!showServiceRequest)}>
          {showServiceRequest ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="#117170"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 20 20" 
              fill="#117170"
              aria-hidden="true"
            >
              <path 
                fillRule="evenodd" 
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" 
                clipRule="evenodd"
              />
            </svg>
          )}
        </IconWrapper> 
      </Header>

      {serviceRequest.length > 0 && showServiceRequest && (
        <TableContainer>
          <TableWrapper>
            <Table>
              <TableHead>
                <tr>
                  <th>Name</th>
                  <th align="center">Status</th>
                  <th align="center">Hours Available</th>
                  <th align="center">Hours Used</th>
                  <th align="center">Expiration Date</th>
                </tr>
              </TableHead>
              <TableBody>
                {serviceRequest.map((item) => (
                  <TableRow key={item.asana_sod_id}>
                    <NameCell>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginLeft: '16px' }}>
                          <div style={{ marginTop: '4px', fontWeight: '500', color: '#1f2937', padding: '.5rem' }}>
                            {item.customer_name}
                          </div>
                        </div>
                      </div>
                    </NameCell>
                    <StatusCell>
                      <StyledIcon 
                        icon={item.status === '#048a24' ? faCircleCheck : item.status === "#FACC14" ? faWarning : faCircleExclamation}
                        color={item.status}
                        aria-hidden="true" 
                      />
                      {item.days_remaining} days
                    </StatusCell>
                    <HoursCell isPositive={item.hours_available > 0}>
                      {item.hours_available} hrs
                    </HoursCell>
                    <HoursCell isPositive={item.hours_used !== 0}>
                      {item.hours_used} hrs
                    </HoursCell>
                    <ExpirationCell>
                      {item.project_current_active_quarter_end_date}
                    </ExpirationCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        </TableContainer>
      )}
    </SectionContainer>
  );
};
import styled from 'styled-components';

export const DeliverySectionWrapper = styled.div`
  margin-top: 12px;
  max-width: 100%;
  background-color: white;
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;


export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  display: inline-flex;
  align-items: baseline;
`;

export const TableHead = styled.thead`
  background-color: #ffffff;
`;

export const TableBody = styled.tbody`
  border-color: #e5e7eb;
  background-color: #ffffff;
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: #117170;
`;

export const ToggleButton = styled.div`
  width: 2rem;
  cursor: pointer;
  color: #117170;
`;

export const TableContainer = styled.div`
  margin-top: 12px;
  overflow-x: auto;
`;

export const TableWrapper = styled.div`
  min-width: 100%;
  padding: 8px 24px;
  display: grid;
`;

export const Table = styled.table`
  min-width: 100%;
  border-collapse: separate;
  border-color: #d1d5db;
`;

export const Th = styled.th`
  padding: 5px;
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  color: #1f2937;
`;

export const Td = styled.td<{
  isName?: boolean;
  isCenter?: boolean;
}>`
  padding: 0.5rem;
  white-space: nowrap;
  font-size: 14px;
  color: ${props => props.isName ? '#000' : '#6b7280'};
  font-weight: ${props => props.isName ? '500' : 'normal'};
  text-align: ${props => props.isCenter ? 'center' : 'left'};
  border-bottom: solid #F7F7F7;
`;

export const EditInput = styled.input`
  width: 100%;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 0.25rem;
  padding: 4px 8px;
`;

export const ActionButton = styled.a<{ isEdit?: boolean }>`
  color: ${props => props.isEdit ? '#048a24' : 'inherit'};
  font-size: 14px;
  font-weight: ${props => props.isEdit ? '600' : 'normal'};
  cursor: pointer;
`;

export const IconButton = styled.button<{ isSuccess?: boolean }>`
  cursor: pointer;
  margin-right: 0.375rem;
  height: 20px;
  width: 20px;
  color: ${props => props.isSuccess ? '#048a24' : 'red'};
  background: none;
  border: none;
  padding: 0;
`;

export const SectionContainer = styled.div`
margin: 20px;
padding: 40px;
margin-left: 45px;
background: #ffffff 0% 0% no-repeat padding-box;
border: 1px solid #dededf;
border-radius: 4px;
position: relative;
height: auto;
margin-right: 45px;
margin-bottom: 15px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
@media (max-width: 768px) {
  width: calc(100vw - 120px);
  margin-right: 16px;
  margin-left: 16px;
}
`;
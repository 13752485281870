import styled from "styled-components";

export const StatusElementListDiv = styled.div`
  display: flex;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 16px;
  border-top: 1px solid #e0e0e0;
  &:last-child {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 16px;
  }
`;

export const StatusElementListDivContaner = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin: 16px 0px;
  flex-direction: column;
  width: 100%;
`;

export const StatusElementColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const StatusElementColumnIcon = styled.div<{ $hasReasonFor: boolean }>`
  color: ${(props) => (props.$hasReasonFor ? "#048a24" : "#ce0000")};
  width: 20px;
  height: 20px;
  & svg {
    width: 20px;
    height: 20px;
  }
`;

export const FormIconContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 50px;
  height: 100px;

  & svg {
    width: 50px;
    height: 50px;
  }
`;

export const ColorPickerContainer = styled.div`
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const ColorPickerModalTaskContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StatusListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StatusListRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const StatusListBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const StatusDragDropDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

export const DragDropListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  overflow: auto;
`;

export const DeleteElementIcon = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  color: white;
  background-color: #ce0000;
  border-radius: 50%;
  padding: 4px;
  & svg {
    width: 20px;
    height: 20px;
  }
`;

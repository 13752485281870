
// src/services/index.ts
import * as apiRoutesAlias from "./api-routes";
import { apiRequest, API_BASE_URL } from "./api-instance";

// Create type for apiRoutes
type ApiRoutes = typeof apiRoutesAlias.default;

// Create the transformed routes
const createApiRoutes = (): ApiRoutes => {
  if (API_BASE_URL?.includes('127.0.0')) {
    return Object.keys(apiRoutesAlias.default).reduce<Partial<ApiRoutes>>((acc, key) => {
      acc[key as keyof ApiRoutes] = (apiRoutesAlias.default[key as keyof ApiRoutes] as string).replace(/ms-home\//g, '');
      return acc;
    }, {}) as ApiRoutes;
  }
  return apiRoutesAlias.default;
};

export const apiRoutes = createApiRoutes();

// Export everything needed
export { 
  apiRequest,
  API_BASE_URL,
};

// Optional: Export type for route keys
export type ApiRouteKey = keyof typeof apiRoutes;
import React from 'react';
import { useEffect, useState } from 'react';
import {
  CheckboxContainer,
  CheckboxContainerTop,
  HiddenCheckbox,
  Icon,
  LabelText,
  StyledCheckbox,
} from './Checkbox.styled';
import { checkboxType } from './CheckboxType';

const Checkbox = ({
  className,
  checked,
  handleClick,
  disabled,
  label,
}: checkboxType) => {
  const [checkedState, setCheckedState] = useState<boolean>(checked);
  const onHandleClick = (state: boolean) => {
    if (!disabled) {
      setCheckedState(state);
      handleClick(state);
    }
  };

  useEffect(() => {
    setCheckedState(checked);
  }, [checked]);

  return (
    <CheckboxContainerTop>
      <CheckboxContainer className={className}>
        <HiddenCheckbox checked={checkedState} readOnly />
        <StyledCheckbox
          data-testid="checkbox"
          checked={checkedState}
          disabled={disabled}
          onClick={() => onHandleClick(!checkedState)}
        >
          <Icon viewBox="0 0 24 24" disabled={disabled}>
            <polyline points="18 6 10 16 6 12" />
          </Icon>
        </StyledCheckbox>
      </CheckboxContainer>
      {label && <LabelText>{label}</LabelText>}
    </CheckboxContainerTop>
  );
};

export default Checkbox;

import apiRoutes from '@/services/api-routes';
import { useGetQuery } from "@/store/fetchClientInstance";

export const useLoginOauth = () => {
  const { data, isLoading, error, refetch } = useGetQuery(
    {
      url: apiRoutes.loginOauth
    }
  );
  return { data, isLoading, error, refetch };
};

import { useSelector } from "react-redux";
import { v2MomType, v2MomTypeArray } from "@/models/teams-info.model";
import { Icon } from "@/components";
import { IconsType } from "@/components/Icon/IconType";
import {
  MembersCard,
  MembersCardIcon,
  MembersCardInner,
  MemberCardTopDiv,
  MemberCardTitle,
  MemberCardActionIcon,
  MemberCardDescription,
  MemberCardRelation,
  MemberCardRelationLabel,
  MemberCardRelationText,
  MemberCardStatus,
} from "./MemberCard.styled";
import { useSuperiorInfoSelector, useUserManagementInfoSelector } from "@/store/states";

const IconStatusLiteral = {
  "In Progress": "RocketLaunchIcon",
  "Not Started": "BellAlertIcon",
  Done: "CheckCircleIcon",
  Blocked: "NoSymbolIcon",
};

const MemberCardComponent = ({
  name,
  status,
  description,
  relation,
  isSuperior,
  isFromTeam,
  currentQuarter,
  onClickEdit,
}: v2MomType) => {
  const superiorInfo = useSuperiorInfoSelector();
  const userInfo = useUserManagementInfoSelector();
  const getSuperiorV2MomStatus = isSuperior
    ? null
    : isFromTeam
      ? userInfo.v2Moms[currentQuarter].find((v2Mom: v2MomTypeArray) => {
          return v2Mom.name === relation;
        })
      : superiorInfo.v2Moms[currentQuarter].find((v2Mom: v2MomTypeArray) => {
          return v2Mom.name === relation;
        });

  return (
    <MembersCard $isSuperior={isSuperior}>
      <MembersCardIcon title={status} $statusColor={status}>
        <Icon icon={IconStatusLiteral[status] as IconsType} type={"outline"} />
      </MembersCardIcon>
      <MembersCardInner>
        <MemberCardTopDiv>
          <MemberCardTitle>{name.toUpperCase()}</MemberCardTitle>
          {!isSuperior && onClickEdit && (
            <MemberCardActionIcon onClick={() => onClickEdit()}>
              <Icon icon={"PencilSquareIcon"} type={"outline"} />
            </MemberCardActionIcon>
          )}
        </MemberCardTopDiv>
        <MemberCardDescription>{description}</MemberCardDescription>
        {relation && (
          <MemberCardRelation>
            {" "}
            <MemberCardRelationLabel>Relation:</MemberCardRelationLabel>
            <MemberCardRelationText
              $statusColor={getSuperiorV2MomStatus?.status || "Disabled"}
            >
              <Icon icon={"AtSymbolIcon"} type={"outline"} />
              {relation}
            </MemberCardRelationText>
          </MemberCardRelation>
        )}
        <MemberCardStatus>
          <Icon
            icon={IconStatusLiteral[status] as IconsType}
            type={"outline"}
          />
          {status}
        </MemberCardStatus>
      </MembersCardInner>
    </MembersCard>
  );
};

export default MemberCardComponent;

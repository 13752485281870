import {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  DragEvent,
} from "react";
import { dragDropType } from "./DragDrop.model";
import {
  Column,
  ColumnContainer,
  ColumnHeader,
  Container,
  Item,
  ItemGrabbing,
} from "./DragDrop.styled";

const DragDrop = forwardRef(
  (
    { columns, onDrop, renderItem, showNumberOfElements }: dragDropType,
    ref,
  ) => {
    const [draggedItem, setDraggedItem] = useState<any | null>(null);
    const [overColumn, setOverColumn] = useState<string | null>(null);
    const [columnsState, setColumnsState] = useState(columns);

    useEffect(() => {
      setColumnsState(columns);
    }, [columns]);

    useImperativeHandle(ref, () => ({
      removeItem: (itemToRemove: any) => {
        setColumnsState((prevColumns) =>
          prevColumns.map((column) => ({
            ...column,
            items: column.items.filter((item) => item.id !== itemToRemove.id),
          })),
        );
      },
    }));

    const handleDragStart = (e: DragEvent<HTMLDivElement>, item: any) => {
      setDraggedItem(item);
    };

    const handleDragOver = (
      e: DragEvent<HTMLDivElement>,
      columnName: string,
    ) => {
      e.preventDefault();
      setOverColumn(columnName);
    };

    const handleDragLeave = () => {
      setOverColumn(null);
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>, columnName: string) => {
      e.preventDefault();
      if (draggedItem) {
        onDrop(draggedItem, columnName);
        setDraggedItem(null);
        setOverColumn(null);
      }
    };

    return (
      <Container>
        {columnsState.map((column) => (
          <ColumnContainer key={column.name}>
            <ColumnHeader $color={column.color || "#048a24"}>
              {column.name}{" "}
              {!!showNumberOfElements && `(${column.items.length})`}
            </ColumnHeader>
            <Column
              className={overColumn === column.name ? "over" : ""}
              onDragOver={(e) => handleDragOver(e, column.name)}
              onDrop={(e) => handleDrop(e, column.name)}
              onDragLeave={handleDragLeave}
            >
              {column.items.map((item, index) => (
                <ItemGrabbing
                  key={index}
                  draggable
                  onDragStart={(e) => handleDragStart(e, item)}
                >
                  {renderItem ? renderItem(item) : <Item>{item.name}</Item>}
                </ItemGrabbing>
              ))}
            </Column>
          </ColumnContainer>
        ))}
      </Container>
    );
  },
);

DragDrop.displayName = "DragDrop";

export default DragDrop;

import { statusElementsType } from "@/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const EmptyState: statusElementsType[] = [];

export const statusElementsSlice = createSlice({
  name: "statusElements",
  initialState: EmptyState,
  reducers: {
    setStatusElements: (state, action: PayloadAction<statusElementsType[]>) => {
      return [...action.payload];
    },
    clearStatusElements: () => EmptyState,
  },
});

export const { setStatusElements, clearStatusElements } =
  statusElementsSlice.actions;

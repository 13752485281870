import { teamInfoType } from "@/models";

export const teamsInfoMock = <teamInfoType[]>[
  {
    name: "Pamela McClure",
    color: "#53c98c",
    goals: {
      Q1: [
        {
          associations: [100009, 100008, 100011],
          name: "Clean up Santiago montains",
          status: "Not Started",
          description:
            "Clean up the Santiago montains from trash and other waste",
        },
        {
          associations: [100002, 100003, 100006],
          name: "Create a database using MongoDB",
          status: "Done",
          description:
            "Create a database using MongoDB and NodeJS for a new project",
        },
      ],
      Q2: [],
      Q3: [],
      Q4: [],
    },
    v2Moms: {
      Q1: [
        {
          name: "Finish portal migration",
          status: "In Progress",
          description: "Migrate the portal to the new infrastructure",
          relation: "Create a new user interface",
        },
        {
          name: "Onboard new team member",
          status: "Not Started",
          description: "Onboard the new team member",
          relation: "Automate the process of creating a new user",
        },
      ],
      Q2: [],
      Q3: [],
      Q4: [],
    },
    timeoff: [
      new Date("2024-12-31"),
      new Date("2024-12-07"),
      new Date("2025-06-31"),
    ],
  },
  {
    name: "Alexandra Hulme",
    color: "#6453c9",
    goals: {
      Q1: [
        {
          associations: [100010, 100004, 100001],
          name: "Clean up Santiago montains",
          status: "Not Started",
          description:
            "Clean up the Santiago montains from trash and other waste",
        },
        {
          associations: [100002, 100008, 100010],
          name: "Create a database using MongoDB",
          status: "Done",
          description:
            "Create a database using MongoDB and NodeJS for a new project",
        },
      ],
      Q2: [],
      Q3: [],
      Q4: [],
    },
    v2Moms: {
      Q1: [
        {
          name: "Create new team structure",
          status: "Done",
          description: "Create the new team structure",
          relation: "Automate the process of creating a new user",
        },
        {
          name: "Migrate Repositorie A to B",
          status: "Blocked",
          description: "Migrate the repositories from A to B",
          relation: "Create a new user interface",
        },
      ],
      Q2: [],
      Q3: [],
      Q4: [],
    },
    timeoff: [
      new Date("2024-12-31"),
      new Date("2024-12-07"),
      new Date("2025-06-31"),
    ],
  },
];

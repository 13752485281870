import styled from "styled-components";
import { TabsTypes } from "./Tabs.model";

interface TabsContainerInterface {
  active?: boolean;
  type: TabsTypes;
}

export const TabsContainer = styled.div<TabsContainerInterface>`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  border-bottom: 1px solid #e9e9e9;
  height: ${(props) => (props.type === "border" ? "40px" : "60px")};
  padding-bottom: ${(props) => (props.type === "border" ? "0px" : "20px")};
`;

export const TabsStyled = styled.div<TabsContainerInterface>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px 8px 0px 0px;
  cursor: pointer;
  border: ${(props) => (props.type === "border" ? "1px solid" : "none")};
  border-bottom: ${(props) => (props.type === "border" ? "none" : "2px solid")};
  color: ${(props) =>
    props.active ? (props.type === "color" ? "#117170" : "#000") : "#646464"};
  border-color: ${(props) =>
    props.active
      ? props.type === "color"
        ? "#117170"
        : "#E9E9E9"
      : "transparent"};
  height: 41px;
  background-color: ${(props) => (props.active ? "#fff" : "transparent")};
  font-weight: 400;
  font-size: 14px;
`;

import {
  Alerts,
  Button,
  DatePicker,
  Icon,
  ProgressBar,
  TimeSchedule,
} from "@/components";
import {
  ChevronRowTitleContainerNoMargin,
  ChevronRowTitle,
  ChevronRowIconsContainer,
  ReturnIcon,
  FormRow,
  FormColumn,
  FormLabel,
  ReturnIconDiv,
  StatusTag,
  SpanButton,
} from "@/common.styled";
import {
  AssignedDiv,
  ContainerReleaseCard,
  DescriptionDiv,
  DescriptionText,
  EditTopIcon,
  InitialsContainer,
  LabelTitle,
  ReleaseTitle,
} from "./ReleaseCard.styled";
import { ButtonsContainer } from "../Releases.styled";
import {
  capitalizeName,
  convertMinutesToHours,
} from "@/utils/shared-functions";
import { IconsType } from "@/components/Icon/IconType";
import { releaseCardType, stagesType, featuresType, tasksType } from "@/models";
import { useTeamsInfoSelector, useUserManagementInfoSelector } from "@/store/states";

const ReleaseCard = ({
  release,
  quarter,
  year,
  onAddNewStage,
  onAddNewFeature,
  onAddNewTask,
  onResetRelease,
  onEditReleaseModal,
  onEditStageModal,
  onEditFeatureModal,
  onEditTaskModal,
  selectedStage,
  setSelectedStage,
  selectedFeature,
  setSelectedFeature,
  selectedTask,
  setSelectedTask,
}: releaseCardType) => {
  const {
    name,
    stages,
    description,
    dueDate,
    startDate,
    realEndDate,
    realStartDate,
    status,
    reasonFor,
  } = release;
  const teamsInfo = useTeamsInfoSelector();
  const userInfo = useUserManagementInfoSelector();

  const getColor = (name: string) => {
    const member = teamsInfo.find((member: any) => member.name === name);
    if (member) {
      return member.color;
    }
    if (name === userInfo.name) {
      return userInfo.color;
    }
    return "#000";
  };

  return (
    <ContainerReleaseCard>
      {!selectedStage && (
        <>
          <ReleaseTitle>
            <ReturnIconDiv $color={status.color}>
              <ReturnIcon onClick={() => onResetRelease()}>
                <Icon icon="ArrowLeftIcon" type={"outline"} />
              </ReturnIcon>
              {name}
            </ReturnIconDiv>
            <EditTopIcon
              onClick={() => {
                onEditReleaseModal();
              }}
            >
              <Icon icon="PencilSquareIcon" type={"outline"} />
            </EditTopIcon>
          </ReleaseTitle>
          {reasonFor && (
            <Alerts
              displayCloseButton={false}
              alerts={[
                {
                  text: `This release is in status ${status.name} because: ${reasonFor}`,
                  type: "error",
                },
              ]}
              onRemove={() => {}}
            />
          )}
          <DescriptionDiv>
            <DescriptionText>{description}</DescriptionText>
          </DescriptionDiv>
          <FormRow>
            <FormColumn>
              <FormLabel>Start Date</FormLabel>
              <DatePicker disabled={true} value={startDate} size="Form" />
            </FormColumn>
            <FormColumn>
              <FormLabel>Due Date</FormLabel>
              <DatePicker disabled={true} value={dueDate} size="Form" />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FormLabel>Real Start Date</FormLabel>
              <DatePicker disabled={true} value={realStartDate} size="Form" />
            </FormColumn>
            <FormColumn>
              <FormLabel>Real End Date</FormLabel>
              <DatePicker disabled={true} value={realEndDate} size="Form" />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FormLabel>Status</FormLabel>
              <StatusTag $statusColor={status.color}>
                <Icon icon={status.icon as IconsType} type={"outline"} />
                {status.name}
              </StatusTag>
            </FormColumn>
          </FormRow>
          <LabelTitle>Time Schedule</LabelTitle>
          <TimeSchedule quarter={quarter} year={year} stages={stages} />
          <LabelTitle>Stages</LabelTitle>
          {stages.map((stage: stagesType) => (
            <ChevronRowTitleContainerNoMargin
              $color={stage.status.color}
              key={stage.id}
            >
              <ChevronRowTitle>{stage.name}</ChevronRowTitle>
              <ChevronRowIconsContainer>
                <SpanButton onClick={() => setSelectedStage(stage)}>
                  <Icon icon="ChevronRightIcon" type={"outline"} />
                </SpanButton>
              </ChevronRowIconsContainer>
            </ChevronRowTitleContainerNoMargin>
          ))}
          <ButtonsContainer>
            <Button
              text="New Stage"
              onClick={() => {
                onAddNewStage();
              }}
            />
          </ButtonsContainer>
        </>
      )}
      {selectedStage && !selectedFeature && (
        <>
          <ReleaseTitle>
            <ReturnIconDiv $color={selectedStage.status.color}>
              <ReturnIcon onClick={() => setSelectedStage(null)}>
                <Icon icon="ArrowLeftIcon" type={"outline"} />
              </ReturnIcon>
              {selectedStage.name}
            </ReturnIconDiv>
            <EditTopIcon
              onClick={() => {
                onEditStageModal();
              }}
            >
              <Icon icon="PencilSquareIcon" type={"outline"} />
            </EditTopIcon>
          </ReleaseTitle>
          {selectedStage.reasonFor && (
            <Alerts
              displayCloseButton={false}
              alerts={[
                {
                  text: `This stage is in status ${selectedStage.status.name} because: ${selectedStage.reasonFor}`,
                  type: "error",
                },
              ]}
              onRemove={() => {}}
            />
          )}
          <DescriptionDiv>
            <DescriptionText>{selectedStage.description}</DescriptionText>
          </DescriptionDiv>
          <FormRow>
            <FormColumn>
              <FormLabel>Start Date</FormLabel>
              <DatePicker
                disabled={true}
                value={selectedStage.startDate}
                size="Form"
              />
            </FormColumn>
            <FormColumn>
              <FormLabel>End Date</FormLabel>
              <DatePicker
                disabled={true}
                value={selectedStage.endDate}
                size="Form"
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FormLabel>Real Start Date</FormLabel>
              <DatePicker
                disabled={true}
                value={selectedStage.realStartDate}
                size="Form"
              />
            </FormColumn>
            <FormColumn>
              <FormLabel>Real End Date</FormLabel>
              <DatePicker
                disabled={true}
                value={selectedStage.realEndDate}
                size="Form"
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FormLabel>Status</FormLabel>
              <StatusTag $statusColor={selectedStage.status.color}>
                <Icon
                  icon={selectedStage.status.icon as IconsType}
                  type={"outline"}
                />
                {selectedStage.status.name}
              </StatusTag>
            </FormColumn>
          </FormRow>
          <LabelTitle>Features</LabelTitle>
          {selectedStage.features.map((feature: featuresType) => (
            <ChevronRowTitleContainerNoMargin
              $color={feature.status.color}
              key={feature.id}
            >
              <ChevronRowTitle>{feature.name}</ChevronRowTitle>
              <ChevronRowIconsContainer>
                <SpanButton onClick={() => setSelectedFeature(feature)}>
                  <Icon icon="ChevronRightIcon" type={"outline"} />
                </SpanButton>
              </ChevronRowIconsContainer>
            </ChevronRowTitleContainerNoMargin>
          ))}
          <ButtonsContainer>
            <Button
              text="New Feature"
              onClick={() => {
                onAddNewFeature();
              }}
            />
          </ButtonsContainer>
        </>
      )}
      {selectedFeature && !selectedTask && (
        <>
          <ReleaseTitle>
            <ReturnIconDiv $color={selectedFeature.status.color}>
              <ReturnIcon onClick={() => setSelectedFeature(null)}>
                <Icon icon="ArrowLeftIcon" type={"outline"} />
              </ReturnIcon>
              {selectedFeature.name}
            </ReturnIconDiv>
            <EditTopIcon
              onClick={() => {
                onEditFeatureModal();
              }}
            >
              <Icon icon="PencilSquareIcon" type={"outline"} />
            </EditTopIcon>
          </ReleaseTitle>
          {selectedFeature.reasonFor && (
            <Alerts
              displayCloseButton={false}
              alerts={[
                {
                  text: `This feature is in status ${selectedFeature.status.name} because: ${selectedFeature.reasonFor}`,
                  type: "error",
                },
              ]}
              onRemove={() => {}}
            />
          )}
          <DescriptionDiv>
            <DescriptionText>{selectedFeature.description}</DescriptionText>
          </DescriptionDiv>
          <FormRow>
            <FormColumn>
              <FormLabel>Status</FormLabel>
              <StatusTag $statusColor={selectedFeature.status.color}>
                <Icon
                  icon={selectedFeature.status.icon as IconsType}
                  type={"outline"}
                />
                {selectedFeature.status.name}
              </StatusTag>
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FormLabel>Assigned To</FormLabel>
              <AssignedDiv>
                <InitialsContainer
                  title={selectedFeature.assignedTo}
                  color={getColor(selectedFeature.assignedTo)}
                >
                  {capitalizeName(selectedFeature.assignedTo)}
                </InitialsContainer>
                {selectedFeature.assignedTo}
              </AssignedDiv>
            </FormColumn>
          </FormRow>
          <LabelTitle>Time Schedule</LabelTitle>
          <TimeSchedule
            quarter={quarter}
            year={year}
            stages={selectedFeature.tasks}
          />
          <LabelTitle>Tasks</LabelTitle>
          {selectedFeature.tasks.map((task: tasksType) => (
            <ChevronRowTitleContainerNoMargin
              $color={task.status.color}
              key={task.id}
            >
              <ChevronRowTitle>{task.name}</ChevronRowTitle>
              <ChevronRowIconsContainer>
                <SpanButton onClick={() => setSelectedTask(task)}>
                  <Icon icon="ChevronRightIcon" type={"outline"} />
                </SpanButton>
              </ChevronRowIconsContainer>
            </ChevronRowTitleContainerNoMargin>
          ))}
          <ButtonsContainer>
            <Button
              text="New Task"
              onClick={() => {
                onAddNewTask();
              }}
            />
          </ButtonsContainer>
        </>
      )}
      {selectedTask && (
        <>
          <ReleaseTitle>
            <ReturnIconDiv $color={selectedTask.status.color}>
              <ReturnIcon onClick={() => setSelectedTask(null)}>
                <Icon icon="ArrowLeftIcon" type={"outline"} />
              </ReturnIcon>
              {selectedTask.name}
            </ReturnIconDiv>
            <EditTopIcon
              onClick={() => {
                onEditTaskModal();
              }}
            >
              <Icon icon="PencilSquareIcon" type={"outline"} />
            </EditTopIcon>
          </ReleaseTitle>
          {selectedTask.reasonFor && (
            <Alerts
              displayCloseButton={false}
              alerts={[
                {
                  text: `This task is in status ${selectedTask.status.name} because: ${selectedTask.reasonFor}`,
                  type: "error",
                },
              ]}
              onRemove={() => {}}
            />
          )}
          <DescriptionDiv>
            <DescriptionText>{selectedTask.description}</DescriptionText>
          </DescriptionDiv>
          <FormRow>
            <FormColumn>
              <FormLabel>Start Date</FormLabel>
              <DatePicker
                disabled={true}
                value={selectedTask.startDate}
                size="Form"
              />
            </FormColumn>
            <FormColumn>
              <FormLabel>End Date</FormLabel>
              <DatePicker
                disabled={true}
                value={selectedTask.endDate}
                size="Form"
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FormLabel>Real Start Date</FormLabel>
              <DatePicker
                disabled={true}
                value={selectedTask.realStartDate}
                size="Form"
              />
            </FormColumn>
            <FormColumn>
              <FormLabel>Real End Date</FormLabel>
              <DatePicker
                disabled={true}
                value={selectedTask.realEndDate}
                size="Form"
              />
            </FormColumn>
          </FormRow>
          <FormLabel>Estimated Time / Effective Time</FormLabel>
          <ProgressBar
            percentage={
              (selectedTask.realTimeInMinutes /
                selectedTask.estimatedTimeInMinutes) *
              100
            }
          />
          <p>
            {convertMinutesToHours(selectedTask.estimatedTimeInMinutes)} /{" "}
            {convertMinutesToHours(selectedTask.realTimeInMinutes)}{" "}
          </p>
          <FormRow>
            <FormColumn>
              <FormLabel>Status</FormLabel>
              <StatusTag $statusColor={selectedTask.status.color}>
                <Icon
                  icon={selectedTask.status.icon as IconsType}
                  type={"outline"}
                />
                {selectedTask.status.name}
              </StatusTag>
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FormLabel>Assigned To</FormLabel>
              <AssignedDiv>
                <InitialsContainer
                  title={selectedTask.assignedTo}
                  color={getColor(selectedTask.assignedTo)}
                >
                  {capitalizeName(selectedTask.assignedTo)}
                </InitialsContainer>
                {selectedTask.assignedTo}
              </AssignedDiv>
            </FormColumn>
          </FormRow>
        </>
      )}
    </ContainerReleaseCard>
  );
};

export default ReleaseCard;

import { useState, useEffect } from 'react';
import type { InstanceHeaders} from '@/services/api-types';
import { useGetQuery } from '@/store/fetchClientInstance';
import { apiRoutes } from '@/services';

export const useRoles = () => {
  const [roles, setRoles] = useState<Role[]>([]);

  const {
    data,
    isLoading,
    error,
    refetch
  } = useGetQuery({
    url: apiRoutes.clientDeliveryTypesOptions,
    config: {
      method: 'GET'
    }
  });

  useEffect(() => {
    if (data && Array.isArray(data)) {
      setRoles(data);
    }
  }, [data]);

  return {
    roles,
    isLoading,
    error,
    refetch
  };
};
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface InitialsContainerInterface {
  $color: string;
}

export const InitialsContainer = styled.div<InitialsContainerInterface>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${(props) => props.$color};
  border: 2px solid #e5e7eb;
  box-shadow: 0 0 0 2px #8f8f8f;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

export const TeamsContainer = styled.div<InitialsContainerInterface>`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${(props) => props.$color};
  border: 2px solid #e5e7eb;
  box-shadow: 0 0 0 2px #8f8f8f;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
`;

export const NameContainer = styled.div<InitialsContainerInterface>`
  font-size: 16px;
  color: ${(props) => props.$color};
  margin-top: 10px;
`;

import { capitalizeName } from "@/utils/shared-functions.js";
import Icon from "../Icon/Icon.tsx";
import {
  CircleContainer,
  CirclesContainer,
  Container,
  IconContainer,
  IconLeftContainer,
  IconsRightContainer,
  InitialsContainer,
  Title,
} from "./ProfileCircle.styled.tsx";
import { ProfileCircleType } from "./ProfileCircleType.ts";

const ProfileCircle = ({
  users,
  arrayOfIcons,
  title,
  onIconClick,
}: ProfileCircleType) => {
  return (
    <Container>
      <CircleContainer>
        <CirclesContainer>
          {users.slice(0, 3).map((data, indexCircle) => (
            <InitialsContainer
              key={indexCircle + data.name}
              $index={indexCircle}
              title={data.name}
              $color={data.color}
            >
              {capitalizeName(data.name)}
            </InitialsContainer>
          ))}
          {users.length > 3 && (
            <IconLeftContainer>
              <Icon icon="EllipsisHorizontalIcon" type="outline" />
            </IconLeftContainer>
          )}
        </CirclesContainer>
        <Title>{title}</Title>
      </CircleContainer>
      <IconsRightContainer>
        {arrayOfIcons &&
          arrayOfIcons.map((icon, index) => (
            <IconContainer
              key={index + icon.icon}
              onClick={() => onIconClick && onIconClick(icon.icon)}
            >
              <Icon icon={icon.icon} type={icon.type} key={index} />
            </IconContainer>
          ))}
      </IconsRightContainer>
    </Container>
  );
};

ProfileCircle.displayName = "ProfileCircle";

export default ProfileCircle;

import { coursesAndEventsType } from "@/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const EmptyState: coursesAndEventsType[] = [];

export const coursesAndEventsSlice = createSlice({
  name: "coursesAndEvents",
  initialState: EmptyState,
  reducers: {
    setCoursesAndEvents: (state, action: PayloadAction<coursesAndEventsType[]>) => {
      return [...action.payload];
    },
    clearCoursesAndEvents: () => EmptyState,
  },
});

export const { setCoursesAndEvents, clearCoursesAndEvents } = coursesAndEventsSlice.actions;
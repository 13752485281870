import React, { useState, useRef, useEffect } from 'react';
import { Tooltip } from '../Tooltip';
import {
  SelectContainer,
  SelectLabel,
  OptionalLabel,
  CustomSelectWrapper,
  SelectedOption,
  SelectArrow,
  SelectItems,
  SelectItem,
} from './CustomSelect.styled';

interface CustomSelectProps {
  label: string | React.ReactNode;
  options: string[];
  placeholder: string;
  style?: string;
  isRequired?: boolean;
  isOptional?: boolean;
  onChange?: (option: string) => void;
  tooltipContent?: string;
}

export const LabelCustomSelect: React.FC<CustomSelectProps> = (props) => {
  const { label, isRequired, isOptional, tooltipContent } = props;

  return (
    <SelectLabel>
      <label>{label}</label>
      {isRequired && (
        <OptionalLabel>
          <span>
            <em> (Required)</em>
          </span>
        </OptionalLabel>
      )}
      {isOptional && (
        <OptionalLabel>
          <span>
            <em> (Optional)</em>
          </span>
        </OptionalLabel>
      )}
      {tooltipContent && <Tooltip content={tooltipContent} />}
    </SelectLabel>
  );
};

export const CustomSelect: React.FC<CustomSelectProps> = (props) => {
  const { onChange, options, placeholder, style } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onChange) {
      onChange(option);
    }
  };

  return (
    <SelectContainer
      className={style}
      ref={dropdownRef}
    >
      <LabelCustomSelect {...props} />

      <CustomSelectWrapper>
        <SelectedOption
          onClick={handleToggleDropdown}
          role='button'
          tabIndex={0}
        >
          {selectedOption || placeholder}
          <SelectArrow />
        </SelectedOption>
        {isOpen && (
          <SelectItems>
            {options.map((option, index) => (
              <SelectItem
                key={index}
                onClick={() => handleOptionClick(option)}
                className={selectedOption === option ? 'selected' : ''}
                role='option'
                aria-selected={selectedOption === option}
              >
                {option}
              </SelectItem>
            ))}
          </SelectItems>
        )}
      </CustomSelectWrapper>
    </SelectContainer>
  );
};

export default CustomSelect;

import styled from "styled-components";

export const TreeContainer = styled.div`
  width: 100%;
  padding: 32px;
`;

export const NoSelectedNodeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 32px;
  font-size: 32px;
  text-align: center;
  color: #d7d7d7;
  -webkit-text-stroke: 1px #b9b9b9;
  font-weight: 700;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: center;
  gap: 16px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
`;

interface InitialsContainerInterface {
  color: string;
}

export const InitialsContainer = styled.div<InitialsContainerInterface>`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  border: 2px solid #e5e7eb;
  box-shadow: 0 0 0 2px #8f8f8f;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  margin-bottom: 16px;
`;

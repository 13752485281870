import { PDInput, Icon, Modal, Dropdown, Button } from "@/components";
import AssociationCard from "../AssociationCard";
import {
  ModalDivConatiner,
  ModalSpan,
  AssociationCardsDiv,
  SelectContainerStatus,
  MembersCardIconSelectContainer,
  MembersCardIcon,
  ModalButtonsContainer,
  ModalButtonsContainerRight,
} from "./TeamFormModal.styled";
import { deepCopy } from "@/utils/shared-functions";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";
import { coursesAndEventsType, formModalType, goalsType, statusType } from "@/models";
import { setTeamsInfo, useCoursesAndEventsSelector, useTeamsInfoSelector } from "@/store/states";

const TeamFormModal = forwardRef(({ currentQuarter }: formModalType, ref) => {
  const dispatch = useDispatch();
  const teamsInfo = useTeamsInfoSelector();
  const coursesAndEvents = useCoursesAndEventsSelector();
  const [modalStatus, setModalStatus] = useState<statusType>("Not Started");
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [newgoalsName, setNewgoalsName] = useState<string>("");
  const [newgoalsDescription, setNewgoalsDescription] = useState<string>("");
  const [newgoalsAssociations, setNewgoalsAssociations] = useState<
    coursesAndEventsType[]
  >([]);

  useImperativeHandle(ref, () => ({
    setUser,
    setTeamUser,
  }));

  const setUser = (memberIndex: number) => {
    setSelectedUser(memberIndex);
    setNewgoalsAssociations([]);
  };

  const setTeamUser = (
    memberIndex: number,
    index: number,
    goals: goalsType,
  ) => {
    setSelectedUser(memberIndex);
    setSelectedIndex(index);
    setNewgoalsName(goals.name);
    setNewgoalsDescription(goals.description);
    setModalStatus(goals.status);
    setNewgoalsAssociations(
      coursesAndEvents.filter((association: coursesAndEventsType) =>
        goals.associations.includes(association.id),
      ),
    );
  };

  const resetNewgoals = () => {
    setNewgoalsName("");
    setNewgoalsDescription("");
    setNewgoalsAssociations([]);
    setModalStatus("Not Started");
    setSelectedIndex(null);
    setSelectedUser(null);
  };

  const addOrEditGoalsPeopleOnMyTeam = () => {
    const teamsInfoCopy = deepCopy(teamsInfo);
    if (selectedUser !== null && selectedIndex === null) {
      teamsInfoCopy[selectedUser].goals[currentQuarter].push({
        name: newgoalsName,
        status: modalStatus,
        description: newgoalsDescription,
        associations: newgoalsAssociations.map((association) => association.id),
      });
    } else if (selectedUser !== null && selectedIndex !== null) {
      teamsInfoCopy[selectedUser].goals[currentQuarter][selectedIndex] = {
        name: newgoalsName,
        status: modalStatus,
        description: newgoalsDescription,
        associations: newgoalsAssociations.map((association) => association.id),
      };
    }
    dispatch(setTeamsInfo(teamsInfoCopy));
    resetNewgoals();
  };

  const deletegoalsPeopleOnMyTeam = () => {
    const teamsInfoCopy = deepCopy(teamsInfo);
    if (selectedUser !== null && selectedIndex !== null) {
      teamsInfoCopy[selectedUser].goals[currentQuarter].splice(
        selectedIndex,
        1,
      );
    }
    dispatch(setTeamsInfo(teamsInfoCopy));
    resetNewgoals();
  };

  const removeCard = (id: number) => {
    setNewgoalsAssociations(
      newgoalsAssociations.filter(
        (association: coursesAndEventsType) => association.id !== id,
      ),
    );
  };

  return (
    <Modal
      handleClose={() => setSelectedUser(null)}
      show={selectedUser !== null}
      title={newgoalsName || "New goals"}
    >
      <ModalDivConatiner>
        <ModalSpan>
          The following goal will be{" "}
          {newgoalsName ? "edited from " : "added to "}
          {selectedUser !== null ? (
            <span>{teamsInfo[selectedUser].name}</span>
          ) : (
            ""
          )}
        </ModalSpan>
        <div>Goal Name</div>
        <PDInput
          placeholder="Goal Name"
          value={newgoalsName}
          onChange={(value: string) => setNewgoalsName(value)}
        />
        <div>Description</div>
        <PDInput
          placeholder="Description"
          value={newgoalsDescription}
          onChange={(value: string) => setNewgoalsDescription(value)}
        />
        <div>Associations</div>
        <Dropdown
          displayValue={"name"}
          title={"Select Associations"}
          options={coursesAndEvents.filter(
            (course: coursesAndEventsType) =>
              !newgoalsAssociations
                .map((association) => association.id)
                .includes(course.id),
          )}
          onChange={(_, value) =>
            setNewgoalsAssociations([
              ...newgoalsAssociations,
              coursesAndEvents.find(
                (course: coursesAndEventsType) => course.id === value.id,
              ) as coursesAndEventsType,
            ])
          }
        />
        <AssociationCardsDiv>
          {newgoalsAssociations.map((association) => (
            <AssociationCard
              key={association.id}
              id={association.id}
              onClose={() => removeCard(association.id)}
            />
          ))}
        </AssociationCardsDiv>
        <div>Status</div>
        <SelectContainerStatus>
          <MembersCardIconSelectContainer
            onClick={() => setModalStatus("Not Started")}
            $statusColor={
              modalStatus === "Not Started" ? "Not Started" : "Disabled"
            }
          >
            <MembersCardIcon
              title={"Not Started"}
              $statusColor={
                modalStatus === "Not Started" ? "Not Started" : "Disabled"
              }
            >
              <Icon icon={"BellAlertIcon"} type={"outline"} />
            </MembersCardIcon>
            Not Started
          </MembersCardIconSelectContainer>
          <MembersCardIconSelectContainer
            onClick={() => setModalStatus("In Progress")}
            $statusColor={
              modalStatus === "In Progress" ? "In Progress" : "Disabled"
            }
          >
            <MembersCardIcon
              title={"In Progress"}
              $statusColor={
                modalStatus === "In Progress" ? "In Progress" : "Disabled"
              }
            >
              <Icon icon={"RocketLaunchIcon"} type={"outline"} />
            </MembersCardIcon>
            In Progress
          </MembersCardIconSelectContainer>
          <MembersCardIconSelectContainer
            onClick={() => setModalStatus("Done")}
            $statusColor={modalStatus === "Done" ? "Done" : "Disabled"}
          >
            <MembersCardIcon
              title={"Done"}
              $statusColor={modalStatus === "Done" ? "Done" : "Disabled"}
            >
              <Icon icon={"CheckCircleIcon"} type={"outline"} />
            </MembersCardIcon>
            Done
          </MembersCardIconSelectContainer>
          <MembersCardIconSelectContainer
            onClick={() => setModalStatus("Blocked")}
            $statusColor={modalStatus === "Blocked" ? "Blocked" : "Disabled"}
          >
            <MembersCardIcon
              title={"Blocked"}
              $statusColor={modalStatus === "Blocked" ? "Blocked" : "Disabled"}
            >
              <Icon icon={"NoSymbolIcon"} type={"outline"} />
            </MembersCardIcon>
            Blocked
          </MembersCardIconSelectContainer>
        </SelectContainerStatus>
        <ModalButtonsContainer>
          <div>
            {selectedIndex !== null && (
              <Button
                text="Delete"
                type="secondary"
                color="#f44336"
                onClick={() => deletegoalsPeopleOnMyTeam()}
              />
            )}
          </div>
          <ModalButtonsContainerRight>
            <Button
              text={selectedIndex !== null ? "Edit" : "Add"}
              type="secondary"
              color="#048a24"
              onClick={() => {
                addOrEditGoalsPeopleOnMyTeam();
              }}
              disabled={newgoalsName === "" || newgoalsDescription === ""}
            />
            <Button
              text="Cancel"
              type="secondary"
              color="#f44336"
              onClick={() => {
                resetNewgoals();
              }}
            />
          </ModalButtonsContainerRight>
        </ModalButtonsContainer>
      </ModalDivConatiner>
    </Modal>
  );
});

TeamFormModal.displayName = "TeamFormModal";

export default TeamFormModal;

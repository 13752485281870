import React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import { forwardRef, useImperativeHandle } from 'react';
import {} from 'styled-components';
import {
  CarouselContainer,
  SlidesContainer,
  Slide,
  Arrows,
  ArrowLeft,
  ArrowRight,
  Dots,
  Dot,
} from './Carousel.styled.tsx';
import { CarouselType } from './CarouselType.ts';
import Icon from '../Icon/Icon.tsx';

const Carousel = forwardRef(
  ({ children, interval = 3000, height = '200px' }: CarouselType, ref) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const slidesCount = React.Children.count(children);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    useImperativeHandle(ref, () => ({
      nextSlide: () => goToNextSlide(),
      prevSlide: () => goToPrevSlide(),
    }));

    const goToNextSlide = useCallback(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slidesCount);
    }, [slidesCount]);

    const goToPrevSlide = useCallback(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + slidesCount) % slidesCount
      );
    }, [slidesCount]);

    const goToSlide = (index: number) => {
      setCurrentIndex(index);
    };

    useEffect(() => {
      if (!isHovered) {
        intervalRef.current = setInterval(goToNextSlide, interval);
      }
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }, [isHovered, interval, goToNextSlide]);

    return (
      <CarouselContainer
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ height }}
      >
        <SlidesContainer currentIndex={currentIndex} style={{ height }}>
          {React.Children.map(children, (child, index) => (
            <Slide key={index}>{child}</Slide>
          ))}
        </SlidesContainer>
        <Arrows>
          <ArrowLeft onClick={goToPrevSlide}>
            <Icon icon={'ChevronLeftIcon'} type={'outline'} />
          </ArrowLeft>
          <ArrowRight onClick={goToNextSlide}>
            <Icon icon={'ChevronRightIcon'} type={'outline'} />
          </ArrowRight>
        </Arrows>
        <Dots>
          {Array.from({ length: slidesCount }).map((_, index) => (
            <Dot
              key={index}
              active={index === currentIndex}
              onClick={() => goToSlide(index)}
            />
          ))}
        </Dots>
      </CarouselContainer>
    );
  }
);

Carousel.displayName = 'Carousel';

export default Carousel;

import apiRoutes from '@/services/api-routes';
import { useGetQuery } from "@/store/fetchClientInstance";
import { useEffect, useState } from 'react';
import { featureFlags } from './loginQueryTypes.model';

export const useFeatureFlags = () => {
    const [features, setFeatures] = useState<featureFlags | null>(null);
  const { data, isLoading, error, refetch } = useGetQuery({
    url: apiRoutes.featureFlags
  });

  useEffect(() => {
    if (data) {
        setFeatures(data.features);
    }
  }, [data]);
  return { data: features, isLoading, error, refetch };
};

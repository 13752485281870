import { superiorInfoType } from "@/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const EmptyState: superiorInfoType = {
  name: "",
  color: "",
  v2Moms: {
    Q1: [],
    Q2: [],
    Q3: [],
    Q4: [],
  },
};

export const superiorInfoSlice = createSlice({
  name: "superiorInfo",
  initialState: EmptyState,
  reducers: {
    setSuperiorInfo: (state, action: PayloadAction<superiorInfoType>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearSuperiorInfo: () => EmptyState,
  },
});

export const { setSuperiorInfo, clearSuperiorInfo } = superiorInfoSlice.actions;

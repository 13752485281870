import { QuartersPillType } from "./QuartersPillType.ts";
import { Quarter, QuarterContainer } from "./QuartersPill.styled.tsx";

const QuartersPill = ({ onClick, currentQuarter = "Q1" }: QuartersPillType) => {
  return (
    <QuarterContainer>
      <Quarter
        $isSelected={currentQuarter === "Q1"}
        onClick={() => onClick("Q1")}
      >
        Q1
      </Quarter>
      <Quarter
        $isSelected={currentQuarter === "Q2"}
        onClick={() => onClick("Q2")}
      >
        Q2
      </Quarter>
      <Quarter
        $isSelected={currentQuarter === "Q3"}
        onClick={() => onClick("Q3")}
      >
        Q3
      </Quarter>
      <Quarter
        $isSelected={currentQuarter === "Q4"}
        onClick={() => onClick("Q4")}
      >
        Q4
      </Quarter>
    </QuarterContainer>
  );
};

QuartersPill.displayName = "QuartersPill";

export default QuartersPill;

import { teamInfoType } from "@/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const EmptyState: teamInfoType[] = [];

export const teamsInfoSlice = createSlice({
  name: "teamsInfo",
  initialState: EmptyState,
  reducers: {
    setTeamsInfo: (state, action: PayloadAction<teamInfoType[]>) => {
      return [...action.payload];
    },
    clearTeamsInfo: () => EmptyState,
  },
});

export const { setTeamsInfo, clearTeamsInfo } = teamsInfoSlice.actions;
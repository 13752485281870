import {  useEffect, useMemo } from 'react';
import { useApiMutation } from '@/store/fetchClientInstance';
import { apiRoutes } from '@/services';

export const useTeamManager = (selectedUser?: any, selectedRole?: any) => {
  const isTeamManagerRole = selectedRole?.type_key === "DELIVERY_MANAGER_TEAM_MANAGER";
  const isQuery = Boolean(selectedUser && isTeamManagerRole);

  const [fetchData, { 
    data: mutationData, 
    isLoading: isLoadingMutation 
  }] = useApiMutation();
  useEffect(() => {
    if (isQuery) {
      fetchData({
        url: `${apiRoutes.teamManager}`,
        config: {
          method: 'POST',
          body: selectedUser
        }
      });
    }
  }, [selectedUser, isQuery, fetchData]);

  const processedData = useMemo(() => {
    const data =  mutationData;
    if (!data) return null;
    
    return data;
  }, [isQuery, mutationData]);

  return {
    manager: processedData,
    isLoading:  isLoadingMutation ,
    refetch: isQuery ? 
      () => fetchData({
        url: apiRoutes.teamManager,
        config: {
          method: 'POST',
          body: {
            query: selectedUser
          }
        }
      }) : 
      undefined
  };
};

import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faFilter,
  faUser,
  faProjectDiagram
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState, useCallback, useMemo } from "react";
import { apiRequest, apiRoutes } from "@/services";
import MultiSelect from "@/components/MultiSelect";
import ClientDeliverySkeleton from "@/components/Skeletons/ClientDeliverySkeleton/ClientDeliverySkeleton";
import useCSV from "@/components/DownloadCSV/index.js"
import { cleanAndSortCustomers } from "@/utils/customers";
import LoadingSpinner from "@/components/LoadingSpinner";
import './Dashboard.css';
import { 
  useRoles,
  useUsers,
  usePDUData,
  useDeliveryData,
  useTeamManager,
  usePremiumSupportData,
  useHealthCheckAlerts,
  useProfessionalServicesData,
  useProductData,
  useServiceRequest
 } from '@/query';
import { SuperAdminView } from './AdminView';
import { DeliverySection } from './DeliverySection';
import { PDUSection } from './PDUSection';
import { ProductSubscriptions } from './ProductSection';
import { HealthCheckAlerts } from './HealthCheckAlertsSection';
import { ProfessionalServices } from './ProfessionalServicesSection';
import { PremiumSupport } from './PremiumSupportSection';
import { ServiceRequestSection } from './ServiceRequestSection';
import { WarningBox } from "./WarningBox";
import { Role, DashboardUser as User, DashboardProps } from './types';


export default function Dashboard({ role, name, clientRole }) {
  const history = useNavigate();
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [filteredData, setFilteredData] = useState({
    deliveryData: [],
    pduData: [],
    psData: [],
    productData: [],
    premiumSupportData: [],
    serviceRequestData: [],
    alertsData: []
  });

  const handleWarningReset = useCallback(() => {
    setSelectedRole(null);
    setSelectedUser(null);
    setSelectedCheckboxes([]);
    setIsSuperAdmin(true);
    setFilters(null);
  }, []);

  const [isSuperAdminView, setIsSuperAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [userName, setUserName] = useState(name);
  const [userRole, setUserRole] = useState(role);
  const [editIndex, setEditIndex] = useState(null);
  const [alertsSummary, setAlertsSummary] = useState([]);
  const [pduSummary, setPduSummary] = useState([]);
  const [psStatusSummary, setPsStatusSummary] = useState([]);
  const [productSummary, setProductSummary] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [premiumSupport, setPremiumSupport] = useState([]);
  const [serviceRequest, setServiceRequest] = useState([]);
  const [originalData, setOriginalData] = useState({});
  const [originalDeliveryData, setOriginalDeliveryData] = useState({});
  const [showPDU, setShowPdu] = useState(true);
  const [currentTab, setCurrentTab] = useState("All");
  const [showPS, setShowPS] = useState(true);
  const [showProducts, setShowProducts] = useState(true);
  const [showAlerts, setShowAlerts] = useState(true);
  const [csm, setCSM] = useState('');
  const [showServiceRequest, setShowServiceRequest] = useState(true);
  const [delivery, setDelivery] = useState(true);
  const [showPremiumSupport, setShowPremiumSupport] = useState(true);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedTab, setSelectedTab] = useState([]);
  const [isProductExpanded, setIsProductExpanded] = useState({});
  const [isAlertExpanded, setIsAlertExpanded] = useState({});
  const [deliveryModels, setDeliveryModels] = useState([]);
  const [filters, setFilters] = useState(null);
  const [editData, setEditData] = useState({});
  const { downloadCSV } = useCSV();
  const [sectionVisibility, setSectionVisibility] = useState({
    pdu_summary: true,
    ps_status_summary: true,
    delivery: true,
    products_summary: true,
    premium_support_summary: true,
    health_check_alerts_summary: true,
    services_request_summary: true,
  });

  const [activeFilter, setActiveFilter] = useState(false);

  const goToPath = (path) => {
    history(path);
  }

  useEffect(() => {
    const expandState = {};
    productSummary.length > 0 && productSummary.forEach(item => {
      expandState[item.customer_name] = false;
    });
    setIsProductExpanded(expandState);
  }, [productSummary]);


  useEffect(() => {
    const expandState = {};
    alertsSummary.forEach(item => {
      expandState[item.customer_name] = false;

    });
    setIsAlertExpanded(expandState);
  }, [alertsSummary]);

  const handleEdit = (index) => {
    setEditIndex(index);
  };

  const handleChange = (field, value) => {
    setEditData(prevData => ({
      ...prevData,
      ...(field.includes('.')
        ? {
          [field.split('.')[0]]: {
            ...prevData[field.split('.')[0]],
            [field.split('.')[1]]: value
          }
        }
        : { [field]: value })
    }));
  };

  const handleSave = async (data, index) => {
    const updatedData = {
      account_id: data.account_id,
      ...editData.delivery_summary_data
    };

    const success = await updateDeliveryData(updatedData);
    
    if (success) {
      setEditIndex(null);
      setEditData({});
    }
  };


  const setAlertExpanded = (alert, expanded) => {
    setIsAlertExpanded(prevState => ({
      ...prevState,
      [alert]: expanded
    }));
  };

  const setProductExpanded = (customerName, expanded) => {
    setIsProductExpanded(prevState => ({
      ...prevState,
      [customerName]: expanded
    }));
  };

  const buildAvailableFilters = (deliveryData, selectedUser) => {
    const uniqueCustomerTypes = new Set();
    const uniquePremiumTypes = new Set();
    
    if (deliveryData) {
      deliveryData.forEach(item => {
        uniqueCustomerTypes.add(item.theater);
        uniquePremiumTypes.add(item.customer_type);
      });
    }
  
    const filters = {
      customerType: Array.from(uniqueCustomerTypes).map(type => ({
        label: type,
        checked: false,
        id: "theater"
      })),
      premiumType: Array.from(uniquePremiumTypes).map(type => ({
        label: type,
        checked: false,
        id: "customer_type"
      }))
    };
  
    if (selectedUser?.length > 0) {
      filters.DeliveryManager = selectedUser.map(csm => ({
        label: csm.full_name,
        checked: false,
        id: "csm"
      }));
    }
  
    return filters;
  };

  const filterUniqueCustomers = (data) => {
    const uniqueCustomerNames = new Set();
    const uniqueNames = data.filter(item => {
      if (!uniqueCustomerNames.has(item.customer_name)) {
        uniqueCustomerNames.add(item.customer_name);
        return true;
      }
      return false;
    }).map(item => item.customer_name);
    return uniqueNames;
  };

  const sortOptions = [
    { name: 'Most Popular', href: '#', current: true },
    { name: 'Best Rating', href: '#', current: false },
    { name: 'Newest', href: '#', current: false },
  ];

  const [tabs, setTabs] = useState([
    { name: 'All', href: '#', current: true },
    { name: 'Client Delivery', href: '#', current: false },
    { name: 'PagerDuty University', href: '#', current: false },
    { name: 'Service Request', href: '#', current: false },
    { name: 'Professional Services', href: '#', current: false },
    { name: 'Product Services', href: '#', current: false },
    { name: 'Premium Support', href: '#', current: false },
    { name: 'Health Check Alerts', href: '#', current: false },
  ]);


  const buttonStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '0.375rem',
    padding: '0.5rem 0.75rem',
    fontSize: '0.875rem',
    fontWeight: '600',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
    outline: 'none',
    backgroundColor: "#048a24"
  };
  useEffect(() => {
    if (clientRole === 'SUPER_USER') {
      setIsSuperAdmin(true);
      setLoading(false);
    } else {
      //getData();
      //getDeliveryModels();
    }
  }, []);

  const hoverStyle = {
    backgroundColor: '#f3f4f6',
  };

  const handleChangeMultiSelect = (
    values,
    deliveryData,
    pduData,
    psData,
    productData,
    premiumSupportData,
    serviceRequestData,
    alertsData
  ) => {
    if (values.length > 0) {
      const selectedCustomerNames = values.map(v => v.value);
      
      return {
        deliveryData: deliveryData?.filter(item => selectedCustomerNames.includes(item.customer_name)),
        pduData: pduData?.filter(item => selectedCustomerNames.includes(item.customer_name)),
        psData: psData?.filter(item => selectedCustomerNames.includes(item.customer_name)),
        productData: productData?.filter(item => selectedCustomerNames.includes(item.customer_name)),
        premiumSupportData: premiumSupportData?.filter(item => selectedCustomerNames.includes(item.customer_name)),
        serviceRequestData: serviceRequestData?.filter(item => selectedCustomerNames.includes(item.customer_name)),
        alertsData: alertsData?.filter(item => selectedCustomerNames.includes(item.customer_name))
      };
    }

    return {
      deliveryData,
      pduData,
      psData, 
      productData,
      premiumSupportData,
      serviceRequestData,
      alertsData
    };
  };

  const formatServiceRequest = (data) => {
    const serviceRequest = data.map((item) => {
      const days = daysBetweenDates(new Date(item.project_current_active_quarter_end_date), new Date())
      return {
        customer_name: item.customer_name,
        hours_available: item.hours_available,
        hours_used: item.hours_used,
        project_current_active_quarter_end_date: item.project_current_active_quarter_end_date,
        days_remaining: daysBetweenDates(new Date(item.project_current_active_quarter_end_date), new Date()),
        status: days >= 60 ? '#048a24' :
          days > 31 && item.hours_available >= 30 ? 'orange' :
            days > 31 && item.hours_available >= 20 ? '#FACC14' :
              days <= 31 && item.hours_available < 20 ? '#048a24' :
                days <= 30 && item.hours_available >= 30 ? '#F77171' :
                  days <= 30 && item.hours_available >= 20 ? 'orange' :
                    days <= 30 && item.hours_available >= 10 ? '#FACC14' :
                      '#048a24',
      }
    });
    return serviceRequest;
  };

  const getPsBackgroundColor = (status) => {
    switch (status) {
      case 'Complete':
        return '#DCFCE6';
      case 'On track - New Order':
      case 'On track - Scheduled - Customer Intro':
        return 'rgb(30,144,255, 0.2)';
      case 'Off Track - Not Started - Customer':
        return '#FEFCE8';
      case 'At Risk - Not Started - Customer':
        return '#FDE2E1';
      case 'Expired':
        return 'rgb(156, 163, 175 , 0.2)';
      default:
        return '#9CA3AF';
    }
  };

  const getDeliveryModels = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getCSMdashboardModels,
    });
    if (!error) {
      setDeliveryModels(data);
    }
  };

  const handleCustomerType = (
    selectedCheckboxes,
    deliveryData,
    pduData,
    psData,
    productData,
    premiumSupportData,
    serviceRequestData,
    alertsData,
    manager
  ) => {
    let filteredDeliveryData = [...deliveryData];
    
    const csmFilters = selectedCheckboxes.filter(filter => filter.id === "csm");
    const theaterFilters = selectedCheckboxes.filter(filter => filter.id === "theater");
    const customerTypeFilters = selectedCheckboxes.filter(filter => filter.id === "customer_type");

    if (csmFilters.length > 0) {
      filteredDeliveryData = filteredDeliveryData.filter(customer =>
        csmFilters.some(filter =>
          (manager?.full_name === 'Alonso Lobos'
            ? customer.portfolio_success_manager === filter.label
            : customer.delivery_manager === filter.label)
        )
      );
    }

    if (theaterFilters.length > 0) {
      filteredDeliveryData = filteredDeliveryData.filter(customer =>
        theaterFilters.some(filter => customer.theater === filter.label)
      );
    }

    if (customerTypeFilters.length > 0) {
      filteredDeliveryData = filteredDeliveryData.filter(customer =>
        customerTypeFilters.some(filter => customer.customer_type === filter.label)
      );
    }

    const filteredCustomerNames = filteredDeliveryData.map(customer => customer.customer_name);

    const filteredData = {
      deliveryData: filteredDeliveryData,
      pduData: pduData?.filter(item => filteredCustomerNames.includes(item.customer_name)),
      psData: psData?.filter(item => filteredCustomerNames.includes(item.customer_name)),
      productData: productData?.filter(item => filteredCustomerNames.includes(item.customer_name)),
      premiumSupportData: premiumSupportData?.filter(item => filteredCustomerNames.includes(item.customer_name)),
      serviceRequestData: serviceRequestData?.filter(item => filteredCustomerNames.includes(item.customer_name)),
      alertsData: alertsData?.filter(item => filteredCustomerNames.includes(item.customer_name))
    };
  
    return filteredData;
  };

  const handleCheckboxChange = (e, label, key, id) => {
    const isChecked = e.target.checked;

    setSelectedCheckboxes((prev) => {
      const newSelectedCheckboxes = isChecked
        ? [...prev, { label, id }]
        : prev.filter((item) => item.label !== label || item.id !== id);

      const newFilteredData = handleCustomerType(
        newSelectedCheckboxes,
        deliveryData,
        pduData,
        psData,
        productData,
        premiumSupportData,
        serviceRequestData,
        alertsData,
        manager
      );

      setFilteredData(newFilteredData);

      return newSelectedCheckboxes;
    });
  };

  const handleTabChange = (name) => {
    setCurrentTab(name);
    setTabs(tabs.map(tab => ({
      ...tab,
      current: tab.name === name
    })));
  };

  const handleToggleUI = (label) => {
    switch (label) {
      case 'PDU':
        setSectionVisibility((prev) => ({ ...prev, pdu_summary: !prev.pdu_summary }));
        break;
      case 'PS Status':
        setSectionVisibility((prev) => ({ ...prev, ps_status_summary: !prev.ps_status_summary }));
        break;
      case 'Health Check Alerts':
        setSectionVisibility((prev) => ({ ...prev, health_check_alerts_summary: !prev.health_check_alerts_summary }));
        break;
      case 'Product Services':
        setSectionVisibility((prev) => ({ ...prev, products_summary: !prev.products_summary }));
        break;
      case 'Premium Support':
        setSectionVisibility((prev) => ({ ...prev, premium_support_summary: !prev.premium_support_summary }));
        break;
      case 'Service Request':
        setSectionVisibility((prev) => ({ ...prev, services_request_summary: !prev.services_request_summary }));
        break;
      default:
        break;
    }
  };

  const handleMultiSelectChange = (values) => {
    if (values.length > 0) {
      const selectedCustomerNames = values.map(v => v.value);
      
      const newFilteredData = {
        deliveryData: deliveryData?.filter(item => selectedCustomerNames.includes(item.customer_name)),
        pduData: pduData?.filter(item => selectedCustomerNames.includes(item.customer_name)),
        psData: psData?.filter(item => selectedCustomerNames.includes(item.customer_name)),
        productData: productData?.filter(item => selectedCustomerNames.includes(item.customer_name)),
        premiumSupportData: premiumSupportData?.filter(item => selectedCustomerNames.includes(item.customer_name)),
        serviceRequestData: serviceRequestData?.filter(item => selectedCustomerNames.includes(item.customer_name)),
        alertsData: alertsData?.filter(item => selectedCustomerNames.includes(item.customer_name))
      };
  
      setFilteredData(newFilteredData);
    } else {
      setFilteredData({
        deliveryData,
        pduData,
        psData,
        productData,
        premiumSupportData,
        serviceRequestData,
        alertsData
      });
    }
  };

  const {
    manager,
    isLoading: isLoadingManager,
    error: managerError
  } = useTeamManager(selectedUser, selectedRole);
  
  const effectiveUser = useMemo(() => {
    if (selectedRole?.type_key === "DELIVERY_MANAGER_TEAM_MANAGER" && manager) {
      return manager;
    }
    return selectedUser;
  }, [selectedRole?.type_key, manager, selectedUser]);
  
  const { 
    roles = [], 
    isLoading: isLoadingRoles,
    error: rolesError
  } = useRoles();
  
  const { 
    users = [], 
    isLoading: isLoadingUsers,
    error: usersError 
  } = useUsers(selectedRole);
  
  const { 
    deliveryData, 
    isLoading: isLoadingDelivery,
    updateDeliveryData 
  } = useDeliveryData(effectiveUser);
  
  
  const { 
    productData,
    isLoading:isLoadingProducts
  } = useProductData(effectiveUser);
  
  const { 
    serviceRequestData,
    isLoading: isLoadingServiceRequest
  } = useServiceRequest(effectiveUser);
  
  const { 
    alertsData, 
    isLoading: isLoadingAlerts 
  } = useHealthCheckAlerts(effectiveUser);
  
  const { 
    psData, 
    isLoading: isLoadingPS 
  } = useProfessionalServicesData(effectiveUser);
  
  const { 
    premiumSupportData, 
    isLoading: isLoadingPremiumSupport 
  } = usePremiumSupportData(effectiveUser);
  
  
  const { 
    pduData,
    isLoading:isLoadingPDU
  } = usePDUData(effectiveUser);

  useEffect(() => {
    if (deliveryData) {
      const newFilters = buildAvailableFilters(deliveryData, selectedUser);
      setFilters(newFilters);
    }
  }, [deliveryData, selectedUser]);

  useEffect(() => {
    if (deliveryData) {
      const customerNames = deliveryData.map((item) => ({
        customer_name: item.customer_name,
        subdomain: item.subdomain,
        value: item.customer_name
      }));
      setCustomers(cleanAndSortCustomers(customerNames));
    }
  }, [deliveryData])

  useEffect(() => {
    setFilteredData({
      deliveryData,
      pduData,
      psData,
      productData,
      premiumSupportData, 
      serviceRequestData,
      alertsData
    });
  }, [
    deliveryData,
    pduData,
    psData,
    productData,
    premiumSupportData,
    serviceRequestData,
    alertsData
  ]);

  const handleRoleSelect = (role: Role) => {
    setSelectedRole(role);
  };

  const handleUserSelect = (user: User) => {
    setSelectedUser(user);
    setIsSuperAdmin(false);
  };


  const isLoading = isLoadingPDU || isLoadingRoles || isLoadingUsers || isLoadingManager ||isLoadingAlerts || isLoadingPS || isLoadingProducts || isLoadingPremiumSupport;

  if (isLoadingRoles && !roles.length) {
    return <LoadingSpinner />;
  }

  if (isSuperAdminView && clientRole === 'SUPER_USER') {
    return (
      <SuperAdminView
        roles={roles || []}
        users={users || []}
        isLoading={isLoadingUsers}
        onRoleSelect={handleRoleSelect}
        onUserSelect={handleUserSelect}
        selectedRole={selectedRole}
      />
    );
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <div className="dashboard-header">
          <h1 className="dashboard-title">Client Delivery Dashboard</h1>
            <>
              <WarningBox 
                clientRole={clientRole}
                manager={manager}
                selectedUser={selectedUser}
                onReset={handleWarningReset}
              />
              <h2 className="user-name">{userName || "Giselle Vazquez"}</h2>
              <div className="user-info">
                <div className="user-info-item">
                  <FontAwesomeIcon icon={faBriefcase} className="user-info-icon" aria-hidden="true" />
                  {userRole || "Customer Success Manager"}
                </div>
                <div className="user-info-item">
                  <FontAwesomeIcon icon={faUser} className="user-info-icon" aria-hidden="true" />
                  {customers.length || 0} Customers
                </div>
                <div className="user-info-item">
                  <FontAwesomeIcon icon={faProjectDiagram} className="user-info-icon" aria-hidden="true" />
                  {psStatusSummary.length || 0} Projects
                </div>
              </div>
              <div className="dashboard-body">
                <section className="dashboard-section">
                  <div>
                    <div className="section-header">
                      <h3 className="section-title">Sections</h3>
                    </div>
                    <nav className="tabs-nav" aria-label="Tabs">
                      {tabs.map((tab) => (
                        <a
                          key={tab.name}
                          href={tab.href}
                          className={`tab ${tab.current ? 'tab-active' : ''}`}
                          aria-current={tab.current ? 'page' : undefined}
                          onClick={(e) => handleTabChange(tab.name)}
                        >
                          {tab.name}
                        </a>
                      ))}
                    </nav>
                  </div>
                  <div className="filter-section">
                    <div className="search-container">
                      <MultiSelect
                        options={customers}
                        onChange={(e) => handleMultiSelectChange(e)}
                        valueToDisplay={"value"}
                        allowOnlyOne={false}
                        defaultValues={null}
                        placeholder={"Customer Name search..."}
                      />
                      <div className="search-shortcut">
                        <kbd className="shortcut-key">⌘K</kbd>
                      </div>
                    </div>
                    <div className="filter-controls">
                      <button onClick={() => setActiveFilter(!activeFilter)} className="filter-button">
                        Filters
                        <FontAwesomeIcon icon={faFilter} className="filter-icon" aria-hidden="true" />
                      </button>

                    </div>
                  </div>
                  {activeFilter && (
                    <div className="active-filters">
                      <div className="filter-grid">
                        {Object.keys(filters).map((key) => (
                          <fieldset key={key} className="filter-fieldset">
                            <legend className="filter-legend">{key.charAt(0).toUpperCase() + key.slice(1)}</legend>
                            <div className="filter-options">
                              {filters[key].map((option, index) => {
                                const isChecked = selectedCheckboxes.some(
                                  checkbox => checkbox.label === option.label && checkbox.id === option.id
                                );
                                return (
                                
                                <div key={index} className="filter-option">
                                  <input
                                    id={option.id}
                                    type="checkbox"
                                    className="filter-checkbox"
                                    checked={isChecked}
                                    value={option.label}
                                    onChange={(e) => handleCheckboxChange(e, option.label, key, option.id)}
                                  />
                                  <label className="filter-label">{option.label}</label>
                                </div>
                              )})}
                            </div>
                          </fieldset>
                        ))}
                      </div>
                    </div>
                  )}
                </section>
              </div>
            </>
        </div>
      </div>
      <>
      {isLoadingDelivery ? (
        <ClientDeliverySkeleton /> 
      ) : (
        sectionVisibility.delivery && 
        !isSuperAdminView && 
        (currentTab === 'All' || currentTab === 'Client Delivery') && (
          <DeliverySection
            loading={isLoadingDelivery}
            delivery={delivery}
            setDelivery={setDelivery}
            deliveryData={filteredData.deliveryData || []}
            editIndex={editIndex}
            handleEdit={handleEdit}
            handleChange={handleChange}
            handleSave={handleSave}
            setEditIndex={setEditIndex}
          />
        )
      )}
      {sectionVisibility.pdu_summary && 
        !isSuperAdminView && 
        (currentTab === 'All' || currentTab === 'PagerDuty University') && (
          <PDUSection
            loading={isLoadingPDU}
            showPDU={showPDU}
            setShowPdu={setShowPdu}
            pduData={filteredData.pduData || []}
            goToPath={goToPath}
          />
      )}
      {sectionVisibility.services_request_summary && 
        !isSuperAdminView && 
        (currentTab === 'All' || currentTab === 'Service Request') && (
          <ServiceRequestSection
            showServiceRequest={showServiceRequest}
            setShowServiceRequest={setShowServiceRequest}
            serviceRequest={filteredData.serviceRequestData || []}
          />
      )}
       {sectionVisibility.ps_status_summary && 
        !isSuperAdminView && 
        (currentTab === 'All' || currentTab === 'Professional Services') && (
          <ProfessionalServices
            showPS={showPS}
            setShowPS={setShowPS}
            psStatusSummary={filteredData.psData || []}
            goToPath={goToPath}
            getPsBackgroundColor={getPsBackgroundColor}
          />
      )}

      {sectionVisibility.products_summary && 
        !isSuperAdminView && 
        (currentTab === 'All' || currentTab === 'Product Services') && (
          <ProductSubscriptions
            showProducts={showProducts}
            setShowProducts={setShowProducts}
            productSummary={filteredData.productData || []}
            isProductExpanded={isProductExpanded}
            setProductExpanded={setProductExpanded}
            goToPath={goToPath}
          />
      )}

      {sectionVisibility.premium_support_summary && 
        !isSuperAdminView && 
        (currentTab === 'All' || currentTab === 'Premium Support') && (
          <PremiumSupport
            showPremiumSupport={showPremiumSupport}
            setShowPremiumSupport={setShowPremiumSupport}
            premiumSupport={filteredData.premiumSupportData || []}
          />
      )}

      {sectionVisibility.health_check_alerts_summary && 
        !isSuperAdminView && 
        (currentTab === 'All' || currentTab === 'Health Check Alerts') && (
          <HealthCheckAlerts
            showAlerts={showAlerts}
            setShowAlerts={setShowAlerts}
            alertsSummary={filteredData.alertsData  || []}
            isAlertExpanded={isAlertExpanded}
            setAlertExpanded={setAlertExpanded}
          />
      )}
      </>
    </div>
  )
}
import { capitalizeName } from "@/utils/shared-functions";
import {
  AssignedDiv,
  BottomDiv,
  FeatureDiv,
  FeatureLabel,
  FeatureName,
  InitialsContainer,
  StageDiv,
  StageLabel,
  StageName,
  TaskCardDescription,
  TaskContainerCard,
  TaskInfoDiv,
  TopDiv,
} from "./TaskCard.styled";
import { useRef, useState } from "react";
import TasksModals from "../../Releases/TasksModals";
import { SpanButton } from "@/common.styled";
import { tasksTypeExtended } from "@/models";
import { Icon } from "@/components";
import { useManagementReleasesSelector, useTeamsInfoSelector, useUserManagementInfoSelector } from "@/store/states";

const TaskCard = ({ task, release, stage, feature }: tasksTypeExtended) => {
  const tasksModals = useRef<any>();
  const userInfo = useUserManagementInfoSelector();
  const teamsInfo = useTeamsInfoSelector();
  const releases = useManagementReleasesSelector();
  const [removeTaskModal, setRemoveTaskModal] = useState(false);
  const [addTaskModal, setAddTaskModal] = useState(false);

  const getColor = (name: string) => {
    const member = teamsInfo.find((member: any) => member.name === name);
    if (member) {
      return member.color;
    }
    if (name === userInfo.name) {
      return userInfo.color;
    }
    return "#000";
  };

  return (
    <>
      <TaskContainerCard>
        <TopDiv>
          {task.name}
          <SpanButton
            onClick={() => {
              tasksModals?.current?.editTaskOpenModal();
            }}
          >
            <Icon icon={"PencilSquareIcon"} type={"outline"} />
          </SpanButton>
        </TopDiv>
        <TaskCardDescription title={task.description}>
          {task.description}
        </TaskCardDescription>
        <TaskInfoDiv>
          <StageDiv>
            <StageLabel>Stage:</StageLabel>
            <StageName>{stage.name}</StageName>
          </StageDiv>
          <FeatureDiv>
            <FeatureLabel>Feature:</FeatureLabel>
            <FeatureName>{feature.name}</FeatureName>
          </FeatureDiv>
        </TaskInfoDiv>
        <BottomDiv>
          <div>Assigned To:</div>
          <AssignedDiv>
            <InitialsContainer
              title={task.assignedTo}
              color={getColor(task.assignedTo)}
            >
              {capitalizeName(task.assignedTo)}
            </InitialsContainer>
            {task.assignedTo}
          </AssignedDiv>
        </BottomDiv>
      </TaskContainerCard>
      <TasksModals
        ref={tasksModals}
        releases={releases}
        releaseId={release.id}
        stageId={stage.id}
        featureId={feature.id}
        selectedTask={task}
        selectedRelease={release}
        openTaskModalAdd={addTaskModal}
        onAddTaskModal={(status: boolean) => {
          setAddTaskModal(status);
        }}
        openTaskModalDelete={removeTaskModal}
        onDeleteTaskModal={(status: boolean) => {
          setRemoveTaskModal(status);
        }}
        onTaskEdited={() => setAddTaskModal(!addTaskModal)}
      />
    </>
  );
};

export default TaskCard;

import { Handle, Position } from "@xyflow/react";
import {
  Container,
  InitialsContainer,
  NameContainer,
  TeamsContainer,
} from "./ProfileCircle.styled";
import { capitalizeName } from "@/utils/shared-functions";

const ProfileCircle = ({ data }: any) => {
  const { name, color, teamColor, team, onClick } = data;
  return (
    <>
      <Handle type="target" position={Position.Left} id="a" />
      <Container onClick={() => onClick(data)}>
        <TeamsContainer $color={teamColor} title={team}>
          <InitialsContainer title={name} $color={color}>
            {capitalizeName(name)}
          </InitialsContainer>
        </TeamsContainer>
        <NameContainer $color={teamColor}>{name}</NameContainer>
      </Container>
      <Handle type="source" position={Position.Right} id="b" />
    </>
  );
};

export default ProfileCircle;

import styled from "styled-components";
import { IconStatusType } from "../Icon/IconType";

export const Container = styled.div`
  display: flex;
  gap: 10px;
`;

interface FilterButtonInterface {
  $statusColor: IconStatusType;
}

export const FilterButton = styled.div<FilterButtonInterface>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  color: #ffffff;
  background-color: ${(props) => {
    switch (props.$statusColor) {
      case "In Progress":
        return "#e0bd00";
      case "Not Started":
        return "#117170";
      case "Done":
        return "#005A24";
      case "Blocked":
        return "#ce0000";
      case "Disabled":
        return "#8f8f8f";
      default:
        return "#FFFFFF";
    }
  }};

  & svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: ${(props) => {
      switch (props.$statusColor) {
        case "In Progress":
          return "#b89800";
        case "Not Started":
          return "#0c5656";
        case "Done":
          return "#00451b";
        case "Blocked":
          return "#a00000";
        case "Disabled":
          return "#6e6e6e";
        default:
          return "#FFFFFF";
      }
    }};
  }
`;

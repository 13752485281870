import styled from "styled-components";

export const MenuContainer = styled.div`
  background-color: white;
  height: 100vh;
  box-shadow: 1px 1px 2px 0px #88888840;
  transition: 0.2s;
  color: rgb(108, 117, 125);
  display: flex;
  flex-direction: column;
  z-index: 10;
  &.expandedMenu {
    padding-left: 16px;
  }
`;

export const MenuSubMenu = styled.div`
  position: absolute;
  left: 60px;
  width: 200px;
  display: none;
  box-shadow: 0px 3px 3px 1px #8888881f;
  background-color: white;
  z-index: 1000;
`;

export const LogoIMG = styled.img`
  height: 30px;
`;

export const LogoIMGFull = styled.img`
  height: 28px;
  display: block;
  overflow: hidden;
`;

interface MenuSelectedProps {
  selected?: boolean;
  open?: boolean;
}

export const MenuIconContainer = styled.div<MenuSelectedProps>`
  color: ${({ selected }) => (selected ? "#06ac38" : "rgb(108,117,125)")};
  width: 60px;
  height: 60px;
  z-index: 1000;
  display: inline-block;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: ${({ selected }) => (selected ? "#005A24" : "white")};
  & span {
    color: ${({ selected }) => (selected ? "#fafafafa" : "rgb(108,117,125)")};
  }
`;

export const MenuIconIcon = styled.div`
  width: 33px;
  height: 33px;
  display: flex;
  background: #e5f2e9;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  color: #005a24;
  font-size: 16px;
  div {
    display: flex;
  }
`;
export const IconSection = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MenuSubMenuFirstLabel = styled.div`
  width: 200px;
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 18px;
  z-index: 100;
`;

export const MenuLabel = styled.div<MenuSelectedProps>`
  color: ${({ selected }) => (selected ? "#06ac38" : "rgb(108,117,125)")};
  height: 40px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: ${({ selected }) =>
    selected ? "1px solid #06ac38" : "1px solid rgb(108 117 125 / 17%)"};
  &:hover {
    border-bottom: 1px solid #06ac38;
    color: #06ac38;
  }
`;

export const MenuIcon = styled.div`
  height: 60px;
  &:hover {
    ${MenuIconContainer} {
      color: #06ac38;
      width: auto;
      box-shadow: inset 0 -6px 3px -6px #83838340,
        inset 0 6px 3px -6px #83838340, inset 0 0px 3px 1px #83838340;
      display: inline-flex;
      background-clip: border-box;
      overflow-x: visible;
    }
    ${MenuSubMenu} {
      pointer-events: all;
      display: block;
    }
  }
`;

export const BigMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 16px;
  padding-bottom: 16px;
`;

export const BigMenuIconContainerInnerLabel = styled.div`
  font-weight: bold;
`;

export const BigMenuIconContainer = styled.div<MenuSelectedProps>`
  display: flex;
  margin: 0;
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 8px;
  transition: 0.1s;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: ${({ selected }) => (selected ? "#005A24" : "white")};
  &:hover {
    background-color: #005a24;
    ${BigMenuIconContainerInnerLabel} {
      color: white;
    }
  }
`;

export const BigMenuIconContainerInner = styled.div<MenuSelectedProps>`
  color: ${({ selected }) => (selected ? "#fff" : "rgb(108,117,125)")};
  display: flex;
  text-align: left;
  align-items: center;
  width: 100%;
`;

export const BigMenuTaskContainer = styled.div`
  display: flex;
  position: relative;
  height: fit-content;
  align-items: center;
`;

export const BigBottomMenuTaskContainer = styled.div`
  display: flex;
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 8px;
  transition: 0.2s;
  &:hover {
    border-left: 2px solid #005a24;
  }
`;

export const BigMenuIconContainerInnerIcon = styled.div`
  margin-right: 12px;
  width: 32px;
  height: 32px;
  display: flex;
  background: #e5f2e9;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  color: #005a24;
`;

export const BigMenuChevronContainer = styled.button<MenuSelectedProps>`
  width: 25px;
  height: 25px;
  display: flex;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  cursor: pointer;
  color: ${({ selected }) => (selected ? "#fff" : "rgb(108,117,125)")};
  & svg {
    transform: ${({ open }) => (open ? "rotateZ(90deg)" : "none")};
    width: 8px;
  }
`;
export const BigMenuSubtaskContainer = styled.div<MenuSelectedProps>`
  position: relative;
  align-self: flex-start;
  border: none;
  padding-left: 14px;
  font-size: 14px;
  transition: display 0.6s;
  transition: max-height 0.9s ease, opacity 0.6s ease;
  max-height: ${({ selected }) => (selected ? '1000px' : '0')};
  opacity: ${({ selected }) => (selected ? 1 : 0)};
  overflow: hidden;
`;

export const BigMenuIconSectionTitle = styled.div`
  padding: 20px 15px 0px 15px;
  text-transform: uppercase;
`;

export const BigMenuLabel = styled.div<MenuSelectedProps>`
  color: ${({ selected }) => (selected ? "#fff" : "rgb(108,117,125)")};
  width: 100%;
  width: calc(100% - 20px);
  padding: 15px 20px 0px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
`;

export const MenuLogoutContainer = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
`;

export const BigBottomMenu = styled.div`
  flex-direction: column;
  align-items: center;iv
  justify-content: center;
  margin-top: auto;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: auto;
  padding: 24px 0px;
`;

interface MenuOpenProps {
  menuOpen: boolean;
}

export const LogoutLabel = styled.div<MenuOpenProps>`
  display: ${({ menuOpen }) => (menuOpen ? "flex" : "none")};
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 20px;
`;

export const FeedbackLabel = styled.div<MenuOpenProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 20px;
  padding-left: 10px;
`;

export const MenuLogoutButton = styled.button<MenuOpenProps>`
  width: calc(100% - 20px);
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  color: rgb(108, 117, 125);
  border: none;
  cursor: pointer;
  background-color: transparent;

  label {
    display: none;
    cursor: pointer;
  }

  svg {
    font-size: 20px;
    padding: 10px;
  }
  &:hover {
    border-radius: ${({ menuOpen }) => (menuOpen ? "5px" : "50%")};
  }
`;

export const FeedbackIcon = styled.button<MenuOpenProps>`
  width: calc(100% - 20px);
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  color: rgb(108, 117, 125);
  border: none;
  cursor: pointer;
  background-color: transparent;

  label {
    display: none;
    cursor: pointer;
  }

  svg {
    font-size: 20px;
    padding: 10px;
  }
  &:hover {
    background-color: rgb(6, 172, 56);
    color: white;
    border-radius: ${({ menuOpen }) => (menuOpen ? "5px" : "50%")};
  }
`;

import { useCallback, useEffect, useState } from "react";
import {
  Alerts,
  Button,
  ColorPicker,
  DragDropList,
  Dropdown,
  Icon,
  Modal,
  PDInput,
} from "@/components";
import {
  ReturnIconDiv,
  ReturnIcon,
  FormLabel,
  FormRow,
  FormColumn,
  ModalContainer,
  ModalButtonsContainerRight,
  ModalButtonsContainer,
  FormAlert,
  ChevronRowTitleContainer,
  ChevronRowIconsContainer,
  ChevronRowTitle,
  SpanIcon,
  StatusTag,
  SpanButton,
} from "@/common.styled";
import { ReleaseTitle } from "../ReleaseCard/ReleaseCard.styled";
import { useDispatch } from "react-redux";
import {
  ButtonContainer,
  ColorPickerContainer,
  ColorPickerModalTaskContainer,
  DeleteElementIcon,
  DragDropListContainer,
  FormIconContainer,
  StatusDragDropDiv,
  StatusElementColumn,
  StatusElementColumnIcon,
  StatusElementListDiv,
  StatusElementListDivContaner,
  StatusListBody,
  StatusListContainer,
  StatusListRow,
} from "./StatusSettings.styled";
import {
  getIconsOptions,
  toggleChevronIcon,
} from "@/utils/shared-functions";
import { IconsType } from "@/components/Icon/IconType";
import { statusElementsListType, statusElementsType, statusSettingsType } from "@/models";
import { Alert } from "@/components/Alerts/Alerts.model";
import { setStatusElements, setStatusList, useStatusElementsSelector, useStatusListSelector } from "@/store/states";

const StatusSettings = ({ onReturn }: statusSettingsType) => {
  const dispatch = useDispatch();
  const statusElements = useStatusElementsSelector();
  const statusList = useStatusListSelector();
  const [createNewStatusModal, setOpenCreateNewStatusModal] = useState(false);
  const [newStatusName, setNewStatusName] = useState("");
  const [newStatusColor, setNewStatusColor] = useState("#000");
  const [newStatusIcon, setNewStatusIcon] = useState({
    name: "Academic Cap",
    value: "AcademicCapIcon",
  });
  const [newStatusReasonFor, setNewStatusReasonFor] = useState(false);
  const [newStatusListName, setNewStatusListName] = useState("");

  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [nameAlreadyExistElement, setNameAlreadyExistElement] = useState(false);
  const [deleteNewStatusModal, setOpenDeleteNewStatusModal] = useState(false);
  const [elementOriginalName, setElementOriginalName] = useState("");

  const [openCreateNewStatusListModal, setOpenCreateNewStatusListModal] =
    useState(false);
  const [statusListArray, setStatusListArray] = useState<string[]>([]);

  const [currentStatusList, setCurrentStatusList] = useState<any>([]);
  const [selectedStatusList, setSelectedStatusList] = useState<any>(null);
  const [deleteSelectedStatusListModal, setOpenDeleteSelectedStatusListModal] =
    useState(false);

  const [alertsElements, setAlertsElements] = useState<Alert[]>([
    {
      type: "info",
      text: "The changes made here won't affect the Status Lists  previously created.",
    },
  ]);

  const [alerts, setAlerts] = useState<Alert[]>([
    {
      type: "info",
      text: "The changes made here won't affect the status of the releases previously created.",
    },
  ]);

  const getStatusElementsListOptions = useCallback(() => {
    const options = statusElements
      .filter(
        (status: statusElementsType) =>
          !currentStatusList
            .map((status: statusElementsType) => status.name)
            .includes(status.name),
      )
      .map((status: statusElementsType) => {
        return { value: status.name, ...status };
      });
    return options;
  }, [statusElements, currentStatusList]);

  const [statusElementsListOptions, setStatusElementsListOptions] = useState(
    getStatusElementsListOptions(),
  );

  useEffect(() => {
    setStatusElementsListOptions(getStatusElementsListOptions());
  }, [statusElements, currentStatusList, getStatusElementsListOptions]);

  const handleStatusListOpen = (name: string) => {
    if (statusListArray.includes(name)) {
      setStatusListArray(statusListArray.filter((status) => status !== name));
    } else {
      setStatusListArray([...statusListArray, name as string]);
    }
  };

  useEffect(() => {
    const namesElementsArray = statusElements.map(
      (element: any) => element.name,
    );
    setNameAlreadyExistElement(namesElementsArray.includes(newStatusName));
  }, [newStatusName, statusElements]);

  const addNewStatus = () => {
    if (nameAlreadyExistElement) {
      return;
    }
    dispatch(
      setStatusElements([
        ...statusElements,
        {
          name: newStatusName,
          color: newStatusColor,
          icon: newStatusIcon.value as IconsType,
          reasonFor: newStatusReasonFor,
        },
      ]),
    );
    setSelectedStatus(null);
    setOpenCreateNewStatusModal(false);
  };

  useEffect(() => {
    setNewStatusName(selectedStatus ? selectedStatus.name : "");
    setNewStatusColor(selectedStatus ? selectedStatus.color : "#000");
    setNewStatusIcon(
      selectedStatus
        ? {
            name: selectedStatus.icon,
            value: selectedStatus.icon,
          }
        : {
            name: "Academic Cap",
            value: "AcademicCapIcon",
          },
    );
    setNewStatusReasonFor(selectedStatus ? selectedStatus.reasonFor : false);
  }, [selectedStatus]);

  const editStatus = () => {
    const newStatusElements = statusElements.map((status: any) => {
      if (status.name === selectedStatus.name) {
        return {
          name: newStatusName,
          color: newStatusColor,
          icon: newStatusIcon.value as IconsType,
          reasonFor: newStatusReasonFor,
        };
      }
      return status;
    });
    dispatch(
      setStatusElements(newStatusElements),
    );
    setSelectedStatus(null);
    setOpenCreateNewStatusModal(false);
  };

  const deleteStatus = () => {
    const newStatusElements = statusElements.filter(
      (status: any) => status.name !== selectedStatus.name,
    );
    dispatch(
      setStatusElements(newStatusElements),
    );
    setSelectedStatus(null);
    setOpenDeleteNewStatusModal(false);
    setOpenCreateNewStatusModal(false);
  };

  const createNewStatusList = () => {
    dispatch(
      setStatusList([
        ...statusList,
        {
          name: newStatusListName,
          statusList: currentStatusList,
        },
      ]),
    );
    setOpenCreateNewStatusListModal(false);
  };

  const editStatusList = () => {
    dispatch(
      setStatusList(
        statusList.map((status: any) => {
          if (status.name === selectedStatusList.name) {
            return {
              name: newStatusListName,
              statusList: currentStatusList,
            };
          }
          return status;
        }),
      ),
    );
    setOpenCreateNewStatusListModal(false);
  };

  useEffect(() => {
    if (!openCreateNewStatusListModal) {
      setSelectedStatusList(null);
      setCurrentStatusList([]);
      setNewStatusListName("");
    }
  }, [openCreateNewStatusListModal]);

  useEffect(() => {
    if (selectedStatusList) {
      setCurrentStatusList(selectedStatusList.statusList);
      setNewStatusListName(selectedStatusList.name);
      setOpenCreateNewStatusListModal(true);
    }
  }, [selectedStatusList]);

  const deleteStatusList = () => {
    dispatch(
      setStatusList(
        statusList.filter(
          (status: any) => status.name !== selectedStatusList.name,
        ),
      ),
    );
    setSelectedStatusList(null);
    setOpenDeleteSelectedStatusListModal(false);
    setOpenCreateNewStatusListModal(false);
  };

  return (
    <>
      <ReleaseTitle>
        <ReturnIconDiv $color={"000"}>
          <ReturnIcon
            onClick={() => {
              onReturn();
            }}
          >
            <Icon icon="ArrowLeftIcon" type={"outline"} />
          </ReturnIcon>
          Status Settings
        </ReturnIconDiv>
      </ReleaseTitle>
      <ButtonContainer>
        <Button
          text="Create New Status"
          color="#048a24"
          type="primary"
          onClick={() => setOpenCreateNewStatusModal(true)}
        />
      </ButtonContainer>
      <Alerts
        alerts={alertsElements}
        onRemove={(index: number) =>
          setAlertsElements(alertsElements.filter((_, i) => i !== index))
        }
      />
      <StatusElementListDivContaner>
        <StatusElementListDiv>
          <StatusElementColumn>Status Name</StatusElementColumn>
          <StatusElementColumn>Ask for Reason</StatusElementColumn>
          <StatusElementColumn>Edit</StatusElementColumn>
        </StatusElementListDiv>
        {statusElements.map((status: any) => (
          <StatusElementListDiv key={status.name}>
            <StatusElementColumn>
              <StatusTag $statusColor={status.color}>
                <Icon icon={status.icon} type={"outline"} />
                {status.name}
              </StatusTag>
            </StatusElementColumn>
            <StatusElementColumn>
              <StatusElementColumnIcon $hasReasonFor={status.reasonFor}>
                <Icon
                  icon={status.reasonFor ? "CheckCircleIcon" : "NoSymbolIcon"}
                  type={"outline"}
                />
              </StatusElementColumnIcon>
            </StatusElementColumn>
            <StatusElementColumn>
              <Button
                text="Edit"
                color="#048a24"
                type="secondary"
                onClick={() => {
                  setElementOriginalName(status.name);
                  setSelectedStatus(status);
                  setOpenCreateNewStatusModal(true);
                }}
              />
            </StatusElementColumn>
          </StatusElementListDiv>
        ))}
      </StatusElementListDivContaner>
      <ButtonContainer>
        <Button
          text="Create New Status List"
          color="#048a24"
          type="primary"
          onClick={() => setOpenCreateNewStatusListModal(true)}
        />
      </ButtonContainer>
      <Alerts
        alerts={alerts}
        onRemove={(index: number) =>
          setAlerts(alerts.filter((_, i) => i !== index))
        }
      />
      <StatusListContainer>
        {statusList.map((status: any) => (
          <StatusListRow key={status.name}>
            <ChevronRowTitleContainer>
              <ChevronRowTitle>{status.name}</ChevronRowTitle>
              <ChevronRowIconsContainer>
                <SpanIcon
                  onClick={() => {
                    setSelectedStatusList(status);
                  }}
                >
                  <Icon icon={"PencilSquareIcon"} type={"outline"} />
                </SpanIcon>
                <SpanButton
                  onClick={() => {
                    handleStatusListOpen(status.name);
                  }}
                >
                  <Icon
                    icon={toggleChevronIcon(
                      statusListArray.includes(status.name),
                    )}
                    type={"outline"}
                  />
                </SpanButton>
              </ChevronRowIconsContainer>
            </ChevronRowTitleContainer>
            {statusListArray.includes(status.name) && (
              <StatusListBody>
                <StatusElementListDivContaner>
                  <StatusElementListDiv>
                    <StatusElementColumn>Order</StatusElementColumn>
                    <StatusElementColumn>Status</StatusElementColumn>
                    <StatusElementColumn>Ask for Reason</StatusElementColumn>
                  </StatusElementListDiv>
                  {status.statusList.map(
                    (statusElement: any, index: number) => (
                      <StatusElementListDiv key={statusElement.name}>
                        <StatusElementColumn>{index + 1}</StatusElementColumn>
                        <StatusElementColumn>
                          <StatusTag $statusColor={statusElement.color}>
                            <Icon icon={statusElement.icon} type={"outline"} />
                            {statusElement.name}
                          </StatusTag>
                        </StatusElementColumn>
                        <StatusElementColumn>
                          <StatusElementColumnIcon
                            $hasReasonFor={statusElement.reasonFor}
                          >
                            <Icon
                              icon={
                                statusElement.reasonFor
                                  ? "CheckCircleIcon"
                                  : "NoSymbolIcon"
                              }
                              type={"outline"}
                            />
                          </StatusElementColumnIcon>
                        </StatusElementColumn>
                      </StatusElementListDiv>
                    ),
                  )}
                </StatusElementListDivContaner>
              </StatusListBody>
            )}
          </StatusListRow>
        ))}
      </StatusListContainer>

      {/* Modals */}
      <Modal
        handleClose={() => setOpenCreateNewStatusModal(false)}
        show={createNewStatusModal}
        title={"Create New Status"}
      >
        <ModalContainer>
          <FormLabel $mandatory="true">Name</FormLabel>
          <PDInput
            value={newStatusName}
            onChange={(e) => setNewStatusName(e)}
          />
          {nameAlreadyExistElement && newStatusName !== elementOriginalName && (
            <FormAlert>This Name Already Exist</FormAlert>
          )}
          <FormRow style={{ alignItems: "center" }}>
            <FormColumn>
              <FormLabel $mandatory="true">Color</FormLabel>
              <PDInput value={newStatusColor} disabled />
            </FormColumn>
            <FormIconContainer>
              <ColorPickerContainer>
                <ColorPicker
                  currentColor={newStatusColor}
                  onChange={setNewStatusColor}
                />
              </ColorPickerContainer>
            </FormIconContainer>
          </FormRow>
          <FormRow style={{ alignItems: "center" }}>
            <FormColumn>
              <FormLabel>Icon</FormLabel>
              <Dropdown
                options={getIconsOptions()}
                value={newStatusIcon.name}
                onChange={(value, option) => setNewStatusIcon(option)}
                displayValue={"name"}
              />
            </FormColumn>
            <FormIconContainer>
              <Icon icon={newStatusIcon.value as IconsType} type={"outline"} />
            </FormIconContainer>
          </FormRow>
          <FormLabel>Ask for reason</FormLabel>
          <Dropdown
            options={[
              { name: "Yes", value: true },
              { name: "No", value: false },
            ]}
            value={newStatusReasonFor ? "Yes" : "No"}
            onChange={(value) => setNewStatusReasonFor(value)}
            displayValue={"name"}
          />
          <ColorPickerModalTaskContainer>
            <StatusTag $statusColor={newStatusColor}>
              <Icon icon={newStatusIcon.value as IconsType} type={"outline"} />
              {newStatusName}
            </StatusTag>
          </ColorPickerModalTaskContainer>
          <ModalButtonsContainer>
            <div>
              {selectedStatus && (
                <Button
                  text="Delete"
                  type="secondary"
                  color="#f44336"
                  onClick={() => {
                    setOpenDeleteNewStatusModal(true);
                  }}
                />
              )}
            </div>
            <ModalButtonsContainerRight>
              <Button
                text={selectedStatus ? "Edit" : "Create"}
                type="secondary"
                color="#048a24"
                onClick={() => {
                  selectedStatus ? editStatus() : addNewStatus();
                }}
                disabled={
                  !newStatusName ||
                  !newStatusColor ||
                  !newStatusIcon ||
                  (nameAlreadyExistElement && !selectedStatus) ||
                  (nameAlreadyExistElement &&
                    newStatusName !== elementOriginalName)
                }
              />
              <Button
                text="Cancel"
                type="secondary"
                color="#f44336"
                onClick={() => {
                  setOpenCreateNewStatusModal(false);
                  setSelectedStatus(null);
                }}
              />
            </ModalButtonsContainerRight>
          </ModalButtonsContainer>
        </ModalContainer>
      </Modal>

      <Modal
        handleClose={() => setOpenDeleteNewStatusModal(false)}
        show={deleteNewStatusModal}
        title={"Delete Status"}
        modalFitContent={true}
      >
        <ModalContainer>
          <FormLabel>Are you sure you want to delete this status?</FormLabel>
          <ModalButtonsContainer>
            <Button
              text="Delete"
              type="secondary"
              color="#f44336"
              onClick={() => {
                deleteStatus();
              }}
            />
            <Button
              text="Cancel"
              type="secondary"
              color="#048a24"
              onClick={() => {
                setOpenDeleteNewStatusModal(false);
              }}
            />
          </ModalButtonsContainer>
        </ModalContainer>
      </Modal>

      <Modal
        handleClose={() => setOpenCreateNewStatusListModal(false)}
        show={openCreateNewStatusListModal}
        title={"Create New Status List"}
      >
        <ModalContainer>
          <FormLabel $mandatory="true">Name</FormLabel>
          <PDInput
            value={newStatusListName}
            onChange={(e) => setNewStatusListName(e)}
          />
          <FormLabel>Add Status</FormLabel>
          <Dropdown
            options={statusElementsListOptions}
            removeAfterAdd
            title="Select Status"
            onChange={(value, option) => {
              setCurrentStatusList([...currentStatusList, option]);
            }}
            displayValue={"name"}
          />
          <DragDropListContainer>
            <DragDropList
              items={currentStatusList}
              onChange={(newItems: statusElementsListType) =>
                setCurrentStatusList(newItems)
              }
              renderItem={({ name, color, icon }: statusElementsType) => (
                <StatusDragDropDiv>
                  <StatusTag $statusColor={color}>
                    <Icon icon={icon as IconsType} type={"outline"} />
                    {name}
                  </StatusTag>
                  <DeleteElementIcon
                    onClick={() =>
                      setCurrentStatusList(
                        currentStatusList.filter(
                          (status: any) => status.name !== name,
                        ),
                      )
                    }
                  >
                    <Icon icon="XMarkIcon" type={"outline"} />
                  </DeleteElementIcon>
                </StatusDragDropDiv>
              )}
            />
          </DragDropListContainer>
          <ModalButtonsContainer>
            <div>
              {selectedStatusList && statusList.length > 1 && (
                <Button
                  text="Delete"
                  type="secondary"
                  color="#f44336"
                  onClick={() => {
                    setOpenDeleteSelectedStatusListModal(true);
                  }}
                />
              )}
            </div>
            <ModalButtonsContainerRight>
              <Button
                text={selectedStatusList ? "Edit" : "Create"}
                type="secondary"
                color="#048a24"
                onClick={() => {
                  selectedStatusList ? editStatusList() : createNewStatusList();
                  setOpenCreateNewStatusListModal(false);
                }}
                disabled={!newStatusListName || currentStatusList.length === 0}
              />
              <Button
                text="Cancel"
                type="secondary"
                color="#f44336"
                onClick={() => {
                  setOpenCreateNewStatusListModal(false);
                }}
              />
            </ModalButtonsContainerRight>
          </ModalButtonsContainer>
        </ModalContainer>
      </Modal>
      <Modal
        handleClose={() => setOpenDeleteSelectedStatusListModal(false)}
        show={deleteSelectedStatusListModal}
        title={"Delete Status List"}
        modalFitContent={true}
      >
        <ModalContainer>
          <FormLabel>
            Are you sure you want to delete this status list?
          </FormLabel>
          <ModalButtonsContainer>
            <Button
              text="Delete"
              type="secondary"
              color="#f44336"
              onClick={() => {
                deleteStatusList();
              }}
            />
            <Button
              text="Cancel"
              type="secondary"
              color="#048a24"
              onClick={() => {
                setOpenDeleteSelectedStatusListModal(false);
              }}
            />
          </ModalButtonsContainer>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default StatusSettings;

import React from 'react';
import { useEffect, useState } from 'react';
import { ColorPickerType } from './ColorPicker.model';
import { ColorSwatch, HiddenInput } from './ColorPicker.styled';

const ColorPicker = ({ onChange, currentColor }: ColorPickerType) => {
  const [color, setColor] = useState(currentColor || '#ffffff');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = event.target.value;
    setColor(newColor);
    onChange(newColor);
  };

  useEffect(() => {
    setColor(currentColor || '#ffffff');
  }, [currentColor]);

  return (
    <ColorSwatch color={color}>
      <HiddenInput type="color" value={color} onChange={handleChange} />
    </ColorSwatch>
  );
};

ColorPicker.displayName = 'ColorPicker';

export default ColorPicker;

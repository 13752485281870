import { IconStatusLiteral } from "@/utils/shared-functions";
import AssociationCard from "../AssociationCard";
import {
  MembersCard,
  MembersCardIcon,
  MembersCardInner,
  MemberCardTopDiv,
  MemberCardTitle,
  MemberCardActionIcon,
  MemberCardDescription,
  MemberCardStatus,
  AssociationCardsDiv,
  AssociationCardsLabel,
  MemberTitleDiv,
} from "./MemberCard.styled";
import { goalsType } from "@/models";
import { Icon } from "@/components";
import { IconsType } from "@/components/Icon/IconType";

const MemberCardComponent = ({
  name,
  status,
  description,
  associations,
  isSuperior,
  onClickEdit,
}: goalsType) => {
  return (
    <MembersCard $isSuperior={isSuperior}>
      <MembersCardInner>
        <MemberCardTopDiv>
          <MemberTitleDiv>
            <MembersCardIcon title={status} $statusColor={status}>
              <Icon
                icon={IconStatusLiteral[status] as IconsType}
                type={"outline"}
              />
            </MembersCardIcon>
            <MemberCardTitle $statusColor={status}>
              {name.toUpperCase()}
            </MemberCardTitle>
          </MemberTitleDiv>

          {!isSuperior && onClickEdit && (
            <MemberCardActionIcon onClick={() => onClickEdit()}>
              <Icon icon={"PencilSquareIcon"} type={"outline"} />
            </MemberCardActionIcon>
          )}
        </MemberCardTopDiv>
        <MemberCardDescription>{description}</MemberCardDescription>
        {associations && (
          <AssociationCardsLabel>Associations:</AssociationCardsLabel>
        )}
        <AssociationCardsDiv>
          {associations &&
            associations.map((id) => <AssociationCard key={id} id={id} />)}
        </AssociationCardsDiv>
        <MemberCardStatus>
          <Icon
            icon={IconStatusLiteral[status] as IconsType}
            type={"outline"}
          />
          {status}
        </MemberCardStatus>
      </MembersCardInner>
    </MembersCard>
  );
};

export default MemberCardComponent;

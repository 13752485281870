import styled from "styled-components";

export const ContainerReleaseCard = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 24px;
`;

export const ReleaseTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #000;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const EditTopIcon = styled.div`
  height: 20px;
  width: 20px;
  margin-right: 10px;
  & svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  &:hover {
    color: #a0e0a0;
  }
`;

export const DescriptionDiv = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  margin-top: 20px;
`;

export const DescriptionText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #000;
`;

export const LabelTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #343434;
  width: fit-content;
`;

type InitialsContainerInterface = {
  color: string;
};

export const InitialsContainer = styled.div<InitialsContainerInterface>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  border: 2px solid #e5e7eb;
  box-shadow: 0 0 0 2px #8f8f8f;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

export const AssignedDiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { baseApi } from "./fetchClientInstance.ts";
import {
  sessionInfoType,
  userInfoType,
  superiorInfoType,
  userManagementInfoType,
  teamsInfoType,
  coursesAndEventsType,
  managementReleasesType,
  teamsTreeType,
  statusElementsType,
  statusListsType,
navigationParamsType, 
} from "@/models";
import {
  userInfoSlice,
  sessionInfoSlice,
  superiorInfoSlice,
  userManagementInfoSlice,
  teamsInfoSlice,
  coursesAndEventsSlice,
  managementReleasesSlice,
  teamsTreeSlice,
  statusElementsSlice,
  statusListSlice,
navigationParamsSlice, 
} from "@/store/states";

export interface AppStore {
    navigationParams: navigationParamsType;
  statusList: statusListsType;
  statusElements: statusElementsType;
  teamsTree: teamsTreeType;
  managementReleases: managementReleasesType;
  coursesAndEvents: coursesAndEventsType;
  teamsInfo: teamsInfoType;
  userManagementInfo: userManagementInfoType;
  userInfo: userInfoType;
  sessionInfo: sessionInfoType;
  superiorInfo: superiorInfoType;
}

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
    navigationParams: navigationParamsSlice.reducer,
  statusList: statusListSlice.reducer,
  statusElements: statusElementsSlice.reducer,
  teamsTree: teamsTreeSlice.reducer,
  managementReleases: managementReleasesSlice.reducer,
  coursesAndEvents: coursesAndEventsSlice.reducer,
  teamsInfo: teamsInfoSlice.reducer,
  userManagementInfo: userManagementInfoSlice.reducer,
  userInfo: userInfoSlice.reducer,
  sessionInfo: sessionInfoSlice.reducer,
  superiorInfo: superiorInfoSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(baseApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import styled from 'styled-components';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClientDeliverySkeleton from '@/components/Skeletons/ClientDeliverySkeleton/ClientDeliverySkeleton';

const PDUContainer = styled.div`
  margin-top: 12px;
  max-width: 100%;
  background-color: white;
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: inline-flex;
  align-items: baseline;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: #117170;
  margin-right: 12px;
`;

const IconWrapper = styled.div`
  width: 2rem;
  cursor: pointer;
`;

const TableContainer = styled.div`
  margin-top: 12px;
  overflow-x: auto;
`;

const TableWrapper = styled.div`
  min-width: 100%;
  padding: 8px 24px;
`;

const Table = styled.table`
  min-width: 100%;
  border-collapse: separate;
  border-color: #d1d5db;
`;

const Th = styled.th`
  padding: 5px;
  text-align: ${props => props.align || 'left'};
  font-size: 16px;
  font-weight: 600;
  color: #1f2937;
`;

const Td = styled.td`
  white-space: nowrap;
  font-size: 14px;
  color: #6b7280;
  border-bottom: solid #F7F7F7;
  padding: ${props => props.padding || '.5rem'};
  text-align: ${props => props.align || 'left'};
`;

const CustomerLink = styled.div`
  cursor: pointer;
  margin-top: 4px;
  font-weight: 500;
  color: #1f2937;
  padding: .5rem;
`;

const CsvIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: #117170;
`;

export const PDUSection = ({ 
  loading,
  showPDU,
  setShowPdu,
  pduData,
  goToPath,
}) => {
  if (loading) return <ClientDeliverySkeleton />;

  return (
    <PDUContainer>
      <Header>
        <TitleContainer>
          <Title>PagerDuty University</Title>
        </TitleContainer>
        <IconWrapper onClick={() => setShowPdu(!showPDU)}>
          {showPDU ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="#117170"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 0 20 20" 
              fill="#117170"
              aria-hidden="true"
            >
              <path 
                fillRule="evenodd" 
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" 
                clipRule="evenodd"
              />
            </svg>
          )}
        </IconWrapper>
      </Header>

      {pduData.length > 0 && showPDU && (
        <TableContainer>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <Th>Name</Th>
                  <Th align="center">Contract End Date</Th>
                  <Th align="center">ILT Remaining</Th>
                  <Th align="center">Registered Courses</Th>
                  <Th align="center">Completed Courses</Th>
                  <Th align="center">Certifications</Th>
                </tr>
              </thead>
              <tbody>
                {pduData.map((item) => (
                  <tr key={item.asana_sod_id}>
                    <Td>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginLeft: '16px' }}>
                          <CustomerLink onClick={() => goToPath(Paths.PDUApp)}>
                            {item.customer_name}
                          </CustomerLink>
                        </div>
                      </div>
                    </Td>
                    <Td align="center">{item.contract_expiry}</Td>
                    <Td align="center">{item.ilt_remaining}</Td>
                    <Td align="center">{item.registered_courses}</Td>
                    <Td align="center">{item.completed_courses}</Td>
                    <Td align="center">{item.certificates}</Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
        </TableContainer>
      )}
    </PDUContainer>
  );
};
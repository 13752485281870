import { useState, useEffect } from 'react';
import { useApiMutation } from '@/store/fetchClientInstance';
import { apiRoutes } from '@/services';
import { Role, User } from '@/pages/Home/ClientDelivery/types';

export const useUsers = (selectedRole?: Role) => {
  const [users, setUsers] = useState<User[]>([]);

  const [fetchUsers, { data, isLoading, error }] = useApiMutation();

  useEffect(() => {
    if (selectedRole) {
      fetchUsers({
        url: apiRoutes.clientDeliveryMembers,
        config: {
          method: 'POST',
          body: [selectedRole]
        }
      });
    }
  }, [selectedRole, fetchUsers]);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const formattedUsers = data.map(user => ({
        ...user,
        value: user.full_name
      }));
      setUsers(formattedUsers);
    }
  }, [data]);

  return {
    users,
    isLoading,
    error,
    refetch: () => fetchUsers({
      url: apiRoutes.clientDeliveryMembers,
      config: {
        method: 'POST',
        body: selectedRole ? [selectedRole] : []
      }
    })
  };
};